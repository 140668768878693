import {Address} from './address'
// import {BusinessCustomer} from '../../personal-details/model/businessCustomer'
import { Account } from 'src/app/account-details/model/account'
import { Stakeholder } from '../model/stakeholder'

export class Business { 
  constructor(  
    public id: number,
    public fullName: string,
    public registrationNo: string,
    public website:string,
    public status: string,
    public address: Address,
    public registrationType:string,
    public industry:string,
    public documents: Document[],
    public account:Account,
    public stakeHolder:Stakeholder[],
    public emailAddress: any
    // public customer: BusinessCustomer[],
    ){}  }
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Data } from 'src/app/providers/data';
import { Business } from '../sign-up/model/business';



@Component({
  selector: 'app-kyc-type',
  templateUrl: './kyc-type.component.html',
  styleUrls: ['./kyc-type.component.scss']
})
export class KycTypeComponent implements OnInit {
  public business : Business;
  constructor(private router: Router,private data: Data){}

  ngOnInit(): void {
    this.business=this.data.storage.businessDetails
  }

  byMannual(){
    this.data.storage = {
      businessDetails: this.business
    };
  this.router.navigate(['/personalDoctype']);
  }

  byPassport(){
    
  }

  byPassBase(){
    this.data.storage = {
      businessDetails: this.business
    };
  this.router.navigate(['/passbaseVerification']);
  }


}

import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as custom from '../reducer/selfieDocument.reducer';

export const selectSelfieDocState = createFeatureSelector<custom.SelfieDocumentState>(
  custom.selfieDocumentFeatureKey,
);


export const selectSelfieDocument = createSelector(
  selectSelfieDocState,
  (state: custom.SelfieDocumentState) => state.selfieDocument
);

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SystemConstants } from '../../sign-up/system/system.constants';
import { Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { map, catchError } from 'rxjs/operators';
import { HttpService } from '../../http.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private baseUrl = environment.api_base_url;
  private headers = SystemConstants.header;

  constructor(private httpService: HttpService,
    private ngxLoader: NgxUiLoaderService) { }


  saveUser(Account: any): Observable<any> {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    let request = JSON.stringify({ "user": Account });
    return this.httpService.post(this.baseUrl + "onboarding/account/", request, { headers })
      .pipe(map((res: any) => {
        this.ngxLoader.stop();
        return JSON.parse(res._body);
      })).pipe(catchError((error: any) => {
        console.log(error);
        this.ngxLoader.stop();
        return error;
      }));
  }

  sendOtp(Account: any): Observable<any> {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    let request = JSON.stringify(Account);
    return this.httpService.post(this.baseUrl + "user/register", request, { headers })
      .pipe(map((res: any) => {
        this.ngxLoader.stop();
        return JSON.parse(res._body);
      })).pipe(catchError((error: any) => {
        console.log(error);
        this.ngxLoader.stop();
        return error;
      }));
  }


}

import { Address } from "../../business-details/model/address";
import { Document } from "../../business-details/model/document";
import { Account } from "../../business-details/model/account";

export class BusinessCustomer { 
  constructor(  
    public id: number,
    public businessId: number,
    public account:Account, 
    public type: string,
    public firstName: string,
    public lastName: string,
    public gender:string,
    public nationality: string,
    public dateOfBirth: string,
    public status: number,
    public address: Address,
    public documents: Document[],
    ){}  }
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PassBaseVerificationComponent } from '../pass-base-verification/component/pass-base-verification.component'
import { AuthenticationService } from './service/authentication.service'

@NgModule({
  declarations: [PassBaseVerificationComponent],
  providers:[AuthenticationService],
  imports: [
    CommonModule
  ]
})
export class PassbaseModule { }

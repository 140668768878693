import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignUpService } from '../sign-up/service/sign-up.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { RouterModule } from '@angular/router';
import { CreateAccountComponent } from './component/create-account.component';
import { UIModule } from '../util/ui/ui-module';


@NgModule({
  declarations: [
    CreateAccountComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    UIModule,
  ],
  providers:[SignUpService],
  exports: [
    CreateAccountComponent
  ]
})
export class CreateAccountModule {}

<app-card-large>
  <div class="dyEAXN">
    <div class="bCDrPV djdIYm">
      <div class="mjYRwVK">
        <div class="main-panel__content">
          <div class="main-panel__content">
            <h1 class="main-heading nonreferral">Business Roles Structure</h1>
            <h2 class="main-sub-heading">Please provide your business roles</h2>
          </div>
        </div>

        <div
          class="col-lg-12 m-auto align-items-center justify-content-between"
        >
          <div
            class="col-lg-12 m-auto d-flex align-items-center justify-content-between"
          >
            <ul class="nav nav-pills" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  #homeTab
                  class="nav-link active"
                  id="home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Authorised signatory
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  #profileTab
                  class="nav-link"
                  id="profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  (click)="getCustmerList()"
                >
                  Director
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  #contacttab
                  class="nav-link"
                  id="contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#contact"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  Shareholder
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  #ubotab
                  class="nav-link"
                  id="ubo-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#contact-1"
                  type="button"
                  role="tab"
                  aria-controls="contact-1"
                  aria-selected="false"
                >
                  UBO
                </button>
              </li>
            </ul>
          </div>
          <div
            class="tab-content"
            id="myTabContent"
            style="
              margin-bottom: 30px !important;
              padding-bottom: 30px !important;
            "
          >
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div
                class="d-flex mt-2 p-3"
              >
                <form
                  name="busineesroles"
                  [formGroup]="busineesroles"
                  class="form-content"
                >
                  <div>
                    <h1
                      data-component="heading"
                      class="small-heading"
                      style="margin-top: -50px"
                    >
                      Add authorised signatory
                    </h1>
                  </div>
                  <div class="row">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >First name<span style="color: red">*</span></label
                      >
                      <input
                        id="text"
                        placeholder="first name"
                        type="text"
                        formControlName="firstname"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted && t.firstname.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.firstname.errors.required">
                          first name is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label">
                        Last name<span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        placeholder="last name"
                        type="text"
                        class="fts-input-field input-width"
                        formControlName="lastname"
                      />
                      <div
                        *ngIf="submitted && t.lastname.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.lastname.errors.required">
                          last name is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Nationality<span style="color: red">*</span></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        formControlName="nationality"
                        class="fts-select-small"
                      >
                        <option disabled selected>
                          Select your nationality
                        </option>
                        <option
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}
                        </option>
                      </select>

                      <div
                        *ngIf="submitted && t.nationality.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.nationality.errors.required">
                          nationality is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Date of birth<span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        placeholder="Date of birth"
                        type="date"
                        formControlName="birth"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted && t.birth.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.birth.errors.required">
                          date of birth is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Place of birth<span style="color: red">*</span></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        class="fts-select"
                        formControlName="place"
                        placeholder="Date of birth"
                      >
                        <option disabled selected>
                          Select your birth place
                        </option>
                        <option
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}
                        </option>
                      </select>

                      <div
                        *ngIf="submitted && t.place.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.place.errors.required">
                          place of birth is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Id document type<span style="color: red"
                          >*</span
                        ></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        class="fts-select-small"
                        formControlName="Iddoc"
                        placeholder="Id document type"
                      >
                        <option disabled selected>Id document type</option>
                        <option
                          *ngFor="let type of documentsType"
                          [value]="type.id"
                        >
                          {{ type.label }}s
                        </option>
                      </select>
                      <div
                        *ngIf="submitted && t.Iddoc.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.Iddoc.errors.required">
                          Id document type is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label">
                        Id document number<span style="color: red"
                          >*</span
                        ></label
                      >
                      <input
                        id="name"
                        placeholder="document number"
                        type="text"
                        formControlName="docnum"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted && t.Iddoc.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.docnum.errors.required">
                          document number is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Id document issuing country<span style="color: red"
                          >*</span
                        ></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        class="fts-select-small"
                        formControlName="docissuing"
                        placeholder="XXXXXXXXXXXXXXX"
                      >
                        <option disabled selected>
                          Id document issuing country
                        </option>
                        <option
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="submitted && t.docissuing.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.docissuing.errors.required">
                          id document issuing is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label">
                        Id document expiry date<span style="color: red"
                          >*</span
                        ></label
                      >
                      <input
                        id="name"
                        placeholder="XXXXXXXXXXXXXXX"
                        formControlName="expdate"
                        type="date"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted && t.expdate.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.expdate.errors.required">
                          Document expiry date is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label">
                        Mobile number
                        <span style="color: red">*</span>
                      </label>
                      <input
                        id="name"
                        placeholder="XXXXXXXXXXXXXXX"
                        type="text"
                        formControlName="mobnum"
                        class="fts-input-field register-w"
                      />
                      <div
                        *ngIf="submitted && t.mobnum.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.mobnum.errors.required">
                          Mobile number is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Email
                        <span style="color: red">*</span>
                      </label>
                      <input
                        id="userEmail"
                        name="email"
                        type="text"
                        formControlName="email"
                        class="fts-input-field register-w"
                      />
                    </div>
                    <div
                      *ngIf="submitted && t.email.errors"
                      class="text-danger"
                    >
                      <div *ngIf="t.email.errors.required">
                        Email is required
                      </div>
                    </div>
                  </div>
                  <h5 class="small-heading" style="margin-top: 10px">
                    Permanent address
                    <span style="color: red">*</span>
                  </h5>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Country <span style="color: red">*</span>
                      </label>
                      <select
                        name="team_size"
                        id="team_size"
                        class="fts-select-small"
                        formControlName="country"
                        placeholder="country"
                      >
                        <option disabled selected>country</option>
                        <option
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="submitted && t.country.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.country.errors.required">
                          Country is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label">
                        City<span style="color: red">*</span>
                      </label>
                      <select
                        id="city"
                        formControlName="city"
                        class="fts-select-small"
                      >
                        <option *ngFor="let city of cities" [value]="city.id">
                          {{ city.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="submitted && t.city.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.city.errors.required">
                          City is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Street <span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        placeholder="Street"
                        formControlName="street"
                        type="text"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted && t.street.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.street.errors.required">
                          Street is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label">
                        Zip code<span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        formControlName="zip"
                        placeholder="XXX"
                        type="number"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted && t.zip.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.zip.errors.required">
                          Zip Code is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="small-heading" style="margin-top: 10px">
                    Tax information<span style="color: red">*</span>
                  </h5>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Country <span style="color: red">*</span></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        class="fts-select-small"
                        formControlName="countryone"
                        placeholder="XXXXXXXXXXXXXXX"
                      >
                        <option disabled selected>country</option>
                        <option
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="submitted && t.countryone.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.countryone.errors.required">
                          Country is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Tax identification number (tin)
                        <span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        placeholder="tax identification"
                        type="text"
                        formControlName="taxnum"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted && t.taxnum.errors"
                        class="text-danger"
                      >
                        <div *ngIf="t.taxnum.errors.required">
                          Tax identification numbe is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="form__label" style="margin-top: 10px">
                    Do you have a multiple citizenship<span style="color: red"
                      >*</span
                    >
                  </h5>
                  <div style="display: flex; gap: 10px">
                    <input
                      type="radio"
                      id="html"
                      formControlName="citizenship"
                      name="citizenship"
                      value="HTML"
                    />
                    <label for="html" style="margin-top: 10px">Yes</label><br />
                    <input
                      type="radio"
                      id="css"
                      formControlName="citizenship"
                      name="citizenship"
                      value="CSS"
                    />
                    <label for="css" style="margin-top: 10px">No</label><br />
                  </div>
                  <div class="row">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Position <span style="color: red">*</span></label
                      >
                      <input
                        class="fts-input-field register-w"
                        formControlName="position"
                        type="text"
                        placeholder="Co"
                      />
                      <p class="text-muted">
                        Position of a person should be (co)
                      </p>
                    </div>
                  </div>
                  <h6 style="margin-top: 10px">
                    Is the person.his/her immedate family members or close
                    associates politically exposed persons (or have been so
                    during the past 12 months)<span style="color: red">?</span>
                  </h6>
                  <div style="display: flex; gap: 10px">
                    <input
                      type="radio"
                      id="html"
                      formControlName="persons"
                      name="persons"
                      value="HTML"
                    />
                    <label for="html" style="margin-top: 10px">Yes</label>
                    <br />
                    <input
                      type="radio"
                      id="css"
                      formControlName="persons"
                      name="persons"
                      value="CSS"
                    />
                    <label for="css" style="margin-top: 10px">No</label>
                    <br />
                  </div>
                  <h6 style="margin-top: 10px">
                    Does the beneficial owner act as a nominee<span
                      style="color: red"
                      >?</span
                    >
                  </h6>
                  <div style="display: flex; gap: 10px">
                    <input
                      type="radio"
                      id="html"
                      formControlName="nominee"
                      name="nominee"
                      value="HTML"
                    />
                    <label for="html" style="margin-top: 10px">Yes</label><br />
                    <input
                      type="radio"
                      id="css"
                      formControlName="nominee"
                      name="nominee"
                      value="CSS"
                    />
                    <label for="css" style="margin-top: 10px">No</label><br />
                  </div>
                  <h6 style="margin-top: 10px">
                    Does the beneficial owner act as a trustee<span
                      style="color: red"
                      >?</span
                    >
                  </h6>
                  <div style="display: flex; gap: 10px">
                    <input
                      type="radio"
                      id="html"
                      name="trustee"
                      formControlName="trustee"
                      value="HTML"
                    />
                    <label for="html" style="margin-top: 10px">Yes</label><br />
                    <input
                      type="radio"
                      id="css"
                      formControlName="trustee"
                      name="trustee"
                      value="CSS"
                    />
                    <label for="css" style="margin-top: 10px">No</label><br />
                  </div>
                  <h6 style="margin-top: 10px">
                    Is the beneficial owner a USA person<span style="color: red"
                      >?</span
                    >
                  </h6>
                  <div style="display: flex; gap: 10px">
                    <input
                      type="radio"
                      (change)="onUsaChange()"
                      formControlName="usaPerson"
                      id="html"
                      name="usaPerson"
                      value="yes"
                    />
                    <label for="html" style="margin-top: 10px">Yes</label><br />
                    <input
                      type="radio"
                      (change)="onUsaChange()"
                      formControlName="usaPerson"
                      id="css"
                      name="usaPerson"
                      value="no"
                    />
                    <label for="css" style="margin-top: 10px">No</label><br />
                  </div>
                  <div *ngIf="isYesSelected">
                    <!-- Your input fields for "YES" option go here -->
                    <input
                      class="fts-input-field register-w"
                      type="text"
                      placeholder="Enter USA Tin Number"
                    />
                  </div>
                  <!-- <div class="eBKYjJ cgMkag">
                    <div class="row">
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          (click)="Previous()"
                          class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 buttons-Add"
                        >
                          Previous
                        </button>
                      </div>
                      <div
                        *ngIf="authorizedContinueButton"
                        class="input-field col s4"
                      >
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          (click)="openProfileTab()"
                          class="buttons"
                          style="
                            height: 40px;
                            padding: 10px 30px;
                            min-width: 170px;
                            color: #2185f4 !important;
                          "
                        >
                          Continue
                        </button>
                      </div>
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          (click)="addFormData()"
                          class="epjTBn"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div> -->

                  <div class="primary-back-continue">
                    <div class="row">
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          class="fts-button-tertiary"
                          (click)="Previous()"
                        >
                          Previous
                        </button>
                      </div>
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          class="fts-button-secondary-small"
                          (click)="openProfileTab()"
                        >
                          Continue
                        </button>
                      </div>
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          (click)="addFormData()"
                          class="fts-button-primary"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="display-table">
                    <table>
                      <thead>
                        <tr>
                          <th style="position: relative; left: 21%">Name</th>
                          <th style="position: relative; left: 32rem">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          class="t-hover"
                          *ngFor="let data of formDataArray; let i = index"
                        >
                          <td style="position: relative; left: 21%">
                            {{ data.firstname }}
                          </td>
                          <td style="position: relative; left: 32rem">
                            <div>
                              <img
                                (click)="setFormValues(data, i)"
                                src="../../../../assets/document-editor.png"
                                style="width: 22px; cursor: pointer"
                              />
                              <img
                                (click)="deleteRecord(i)"
                                src="../../../../assets/delete.png"
                                style="width: 18px; cursor: pointer"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <form
                name="busineesroles1"
                [formGroup]="busineesroles1"
                class="form-content"
                style="
                  padding: 15px 35px;
                  margin-top: 15px;
                "
              >
                <div>
                  <h1
                    data-component="heading"
                    class="small-heading"
                  >
                    Add director
                  </h1>
                </div>
                <div class="row">
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label
                      data-component="form-input-name-label"
                      class="form__label"
                      for="name"
                      >Type<span style="color: red">*</span></label
                    >
                    <select
                      #type
                      name="team_size"
                      (change)="onSelectType(type.value)"
                      id="team_size"
                      class="fts-select"
                      placeholder="Date"
                    >
                      <option disabled selected>Select type</option>
                      <option value="individual">Individual</option>
                      <option value="business">Business</option>
                    </select>
                  </div>
                </div>
                <div *ngIf="isIndividual">
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Would you like to use an existing person for this
                        role<span tyle="color: red;">?</span></label
                      >
                      <select
                        #customerSelect
                        name="team_size"
                        (change)="fetchCustomerData(customerSelect.value)"
                        id="team_size"
                        class="fts-select-small"
                        placeholder="person"
                      >
                        <option value="" disabled selected>Select User</option>
                        <option
                          *ngFor="let customer of customers"
                          [value]="customer.id"
                        >
                          {{ customer.firstName }} {{ customer.lastName }}
                        </option>
                      </select>
                      <div
                        *ngIf="submitted3 && b.customer.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.customer.errors.required">
                          would you like to use an existing person for this role
                          is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 33px">
                      <button class="fts-button-tertiary-small" (click)="refreshForm()">
                        Clear
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >First name<span style="color: red">*</span></label
                      >
                      <input
                        id="text"
                        placeholder="first name"
                        type="text"
                        formControlName="firstname"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted2 && c.firstname.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.firstname.errors.required">
                          first name is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Last name<span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        placeholder="last name"
                        type="text"
                        class="fts-input-field input-width"
                        formControlName="lastname"
                      />
                      <div
                        *ngIf="submitted2 && c.lastname.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.lastname.errors.required">
                          last name is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Nationality<span style="color: red">*</span></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        formControlName="nationality"
                        class="fts-select-small"
                      >
                        <option disabled selected>Nationality</option>
                        <option
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}
                        </option>
                      </select>

                      <div
                        *ngIf="submitted2 && c.nationality.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.nationality.errors.required">
                          nationality is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Date of birth<span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        placeholder="Date of birth"
                        type="date"
                        formControlName="birth"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted2 && c.birth.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.birth.errors.required">
                          date of birth is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Place of birth<span style="color: red">*</span></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        class="fts-select"
                        formControlName="place"
                        placeholder="Date of birth"
                      >
                        <option disabled selected>
                          Select your birth place
                        </option>
                        <option
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}
                        </option>
                      </select>

                      <div
                        *ngIf="submitted2 && c.place.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.place.errors.required">
                          place of birth is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Id document type<span style="color: red"
                          >*</span
                        ></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        class="fts-select-small"
                        formControlName="Iddoc"
                        placeholder="XXXXXXXXXXXXXXX"
                      >
                        <option disabled selected>ID type</option>
                        <option
                          *ngFor="let type of documentsType"
                          [value]="type.id"
                        >
                          {{ type.label }}s
                        </option>
                      </select>
                      <div
                        *ngIf="submitted2 && c.Iddoc.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.Iddoc.errors.required">
                          Id document type is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Id document number<span style="color: red"
                          >*</span
                        ></label
                      >
                      <input
                        id="name"
                        placeholder="Id document number"
                        type="text"
                        formControlName="docnum"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted2 && c.Iddoc.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.docnum.errors.required">
                          document number is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Id document issuing country<span style="color: red"
                          >*</span
                        ></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        class="fts-select-small"
                        formControlName="docissuing"
                        placeholder="XXXXXXXXXXXXXXX"
                      >
                        <option disabled selected>
                          Id document issuing country
                        </option>
                        <option
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="submitted2 && c.docissuing.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.docissuing.errors.required">
                          id document issuing is required
                        </div>
                      </div>
                      <!-- <div  class="text-danger">
                            <div >Country is required</div>
                            </div> -->
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Id document expiry date<span style="color: red"
                          >*</span
                        ></label
                      >
                      <input
                        id="name"
                        placeholder=" document expiry date"
                        formControlName="expdate"
                        type="date"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted2 && c.expdate.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.expdate.errors.required">
                          document expiry date is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Mobile number <span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        placeholder="XXXXXXXXXXXXXXX"
                        type="text"
                        formControlName="mobnum"
                        class="fts-input-field register-w"
                      />
                      <div
                        *ngIf="submitted2 && c.mobnum.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.mobnum.errors.required">
                          Mobile number is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Email <span style="color: red">*</span></label
                      >
                      <input
                        id="userEmail"
                        name="email"
                        type="text"
                        formControlName="email"
                        class="fts-input-field register-w"
                      />
                    </div>
                    <div
                      *ngIf="submitted2 && c.email.errors"
                      class="text-danger"
                    >
                      <div *ngIf="c.email.errors.required">
                        Email is required
                      </div>
                    </div>
                  </div>
                  <h5 class="small-heading" style="margin-top: 10px">
                    Permanent address
                    <span style="color: red">*</span>
                  </h5>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Country <span style="color: red">*</span></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        class="fts-select-small"
                        formControlName="country"
                        placeholder="XXXXXXXXXXXXXXX"
                      >
                        <option disabled selected>Country</option>
                        <option
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="submitted2 && c.country.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.country.errors.required">
                          Country is required
                        </div>
                      </div>
                      <!-- <div  class="text-danger">
                            <div >Country is required</div>
                            </div> -->
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label">
                        City<span style="color: red">*</span></label
                      >
                      <select
                        id="city"
                        formControlName="city"
                        class="fts-select-small"
                      >
                        <option *ngFor="let city of cities" [value]="city.id">
                          {{ city.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="submitted2 && c.city.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.city.errors.required">
                          City is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Street <span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        placeholder="Street"
                        formControlName="street"
                        type="text"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted2 && c.street.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.street.errors.required">
                          street is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label">
                        Zip code<span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        formControlName="zip"
                        placeholder="XXX"
                        type="number"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted2 && c.zip.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.zip.errors.required">
                          Zip code is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="small-heading" style="margin-top: 10px">
                    Tax information<span style="color: red">*</span>
                  </h5>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Country <span style="color: red">*</span></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        class="fts-select-small"
                        formControlName="countryone"
                        placeholder="XXXXXXXXXXXXXXX"
                      >
                        <option disabled selected>Country</option>
                        <option
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}
                        </option>
                      </select>

                      <div
                        *ngIf="submitted2 && c.countryone.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.countryone.errors.required">
                          Country is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >tax identification number (tin)
                        <span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        placeholder="tax identification"
                        type="text"
                        formControlName="taxnum"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted2 && c.taxnum.errors"
                        class="text-danger"
                      >
                        <div *ngIf="c.taxnum.errors.required">
                          Tax identification numbe is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="form__label" style="margin-top: 10px">
                    Do you have a multiple citizenship<span style="color: red"
                      >*</span
                    >
                  </h5>
                  <div style="display: flex; gap: 10px">
                    <input
                      type="radio"
                      id="html"
                      formControlName="citizenship"
                      name="citizenship"
                      value="HTML"
                    />
                    <label for="html" style="margin-top: 10px">Yes</label><br />
                    <input
                      type="radio"
                      id="css"
                      name="citizenship"
                      formControlName="citizenship"
                      value="CSS"
                    />
                    <label for="css" style="margin-top: 10px">No</label><br />
                  </div>
                  <div class="row">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Position <span style="color: red">*</span></label
                      >
                      <input
                        class="fts-input-field register-w"
                        type="text"
                        formControlName="position"
                        placeholder="Co"
                      />
                      <p class="text-muted">
                        Position of a person should be (Co)
                      </p>
                    </div>
                  </div>
                  <h6 class="form__label" style="margin-top: 10px">
                    Is the person.his/her immedate family members or close
                    associates politically exposed persons (or have been so
                    during the past 12 months)<span style="color: red">?</span>
                  </h6>
                  <div style="display: flex; gap: 10px">
                    <input
                      type="radio"
                      id="html"
                      name="persons"
                      formControlName="persons"
                      value="HTML"
                    />
                    <label for="html" style="margin-top: 10px">Yes</label><br />
                    <input
                      type="radio"
                      id="css"
                      name="persons"
                      formControlName="persons"
                      value="CSS"
                    />
                    <label for="css" style="margin-top: 10px">No</label><br />
                  </div>
                  <h6 class="form__label" style="margin-top: 10px">
                    Does the beneficial owner act as a nominee<span
                      style="color: red"
                      >?</span
                    >
                  </h6>
                  <div style="display: flex; gap: 10px">
                    <input
                      type="radio"
                      id="html"
                      formControlName="nominee"
                      name="nominee"
                      value="HTML"
                    />
                    <label for="html" style="margin-top: 10px">Yes</label><br />
                    <input
                      type="radio"
                      id="css"
                      formControlName="nominee"
                      name="nominee"
                      value="CSS"
                    />
                    <label for="css" style="margin-top: 10px">No</label><br />
                  </div>
                  <h6 class="form__label" style="margin-top: 10px">
                    Does the beneficial owner act as a trustee<span
                      style="color: red"
                      >?</span
                    >
                  </h6>
                  <div style="display: flex; gap: 10px">
                    <input
                      type="radio"
                      id="html"
                      formControlName="trustee"
                      name="trustee"
                      value="HTML"
                    />
                    <label for="html" style="margin-top: 10px">Yes</label><br />
                    <input
                      type="radio"
                      id="css"
                      formControlName="trustee"
                      name="trustee"
                      value="CSS"
                    />
                    <label for="css" style="margin-top: 10px">No</label><br />
                  </div>
                  <h6 class="form__label" style="margin-top: 10px">
                    Is the beneficial owner a USA person<span style="color: red"
                      >?</span
                    >
                  </h6>
                  <div style="display: flex; gap: 10px">
                    <input
                      type="radio"
                      id="html"
                      formControlName="usaPerson"
                      name="usaPerson"
                      value="HTML"
                    />
                    <label for="html" style="margin-top: 10px">Yes</label><br />
                    <input
                      type="radio"
                      id="css"
                      name="usaPerson"
                      formControlName="usaPerson"
                      value="CSS"
                    />
                    <label for="css" style="margin-top: 10px">No</label><br />
                  </div>
                  <!-- <div class="eBKYjJ cgMkag">
                    <div class="row">
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          (click)="homePrevious()"
                          class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 buttons-Add"
                        >
                          Previous
                        </button>
                      </div>
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          (click)="opencontacttab()"
                          class="buttons"
                          style="
                            height: 40px;
                            padding: 10px 30px;
                            min-width: 170px;
                            color: #2185f4 !important;
                          "
                        >
                          Continue
                        </button>
                      </div>
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          (click)="addDirectorData()"
                          class="epjTBn"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div> -->

                  <div class="primary-back-continue">
                    <div class="row">
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          class="fts-button-tertiary"
                          (click)="homePrevious()"
                        >
                          Previous
                        </button>
                      </div>
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          class="fts-button-secondary-small"
                          (click)="opencontacttab()"
                        >
                          Continue
                        </button>
                      </div>
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          (click)="addDirectorData()"
                          class="fts-button-primary"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="display-table">
                    <table>
                      <thead>
                        <tr>
                          <th style="position: relative; left: 21%">Name</th>
                          <th style="position: relative; left: 32rem">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          class="t-hover"
                          *ngFor="let data of directorDataArray; let i = index"
                        >
                          <td style="position: relative; left: 21%">
                            {{ data.firstname }}
                          </td>
                          <td style="position: relative; left: 32rem">
                            <div>
                              <img
                                (click)="setDirectorValues(data, i)"
                                src="../../../../assets/document-editor.png"
                                style="width: 22px; cursor: pointer"
                              />
                              <img
                                (click)="deleteRecord1(i)"
                                src="../../../../assets/delete.png"
                                style="width: 18px; cursor: pointer"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <form name="BusinessDirector" [formGroup]="BusinessDirector">
                  <div *ngIf="isBusiness">
                    <div class="row" style="margin-top: 10px">
                      <div class="input-field col s4" style="margin-top: 5px">
                        <label for="team_size" class="form__label">
                          Corporate full name<span style="color: red"
                            >*</span
                          ></label
                        >
                        <input
                          id="corporate Full Name"
                          formControlName="corporateFullName"
                          name="corporate Full Name"
                          type="text"
                          class="fts-input-field register-w"
                        />
                      </div>
                      <div
                        *ngIf="submittedbusiness && k.corporateFullName.errors"
                        class="text-danger"
                      >
                        <div *ngIf="k.corporateFullName.errors.required">
                          corporate full name
                        </div>
                      </div>
                    </div>
                    <div class="row" style="margin-top: 10px">
                      <div class="input-field col s4" style="margin-top: 5px">
                        <label
                          data-component="form-input-name-label"
                          class="form__label"
                          for=""
                          >Country of incorporation<span style="color: red"
                            >*</span
                          ></label
                        >
                        <select
                          name="country incorporation"
                          id="countryIncorporation"
                          formControlName="countryIncorporation"
                          class="fts-select-small"
                          placeholder="country incorporation"
                        >
                          <option disabled selected>
                            Country of incorporation
                          </option>
                          <option>United kingdom</option>
                        </select>
                        <div
                          *ngIf="
                            submittedbusiness && k.countryIncorporation.errors
                          "
                          class="text-danger"
                        >
                          <div *ngIf="k.countryIncorporation.errors.required">
                            Country of incorporation
                          </div>
                        </div>
                      </div>
                      <div class="input-field col s4" style="margin-top: 5px">
                        <label for="team_size" class="form__label">
                          Type of corporate<span style="color: red"
                            >*</span
                          ></label
                        >
                        <select
                          name="team_size"
                          id="type of Corporate"
                          formControlName="typeCorporate"
                          class="fts-select-small"
                        >
                          <option disabled selected>
                            Select corporate type
                          </option>
                          <option>Business</option>
                        </select>
                        <div
                          *ngIf="submittedbusiness && k.typeCorporate.errors"
                          class="text-danger"
                        >
                          <div *ngIf="k.typeCorporate.errors.required">
                            Select corporate type
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" style="margin-top: 10px">
                      <div class="input-field col s4" style="margin-top: 5px">
                        <label for="team_size" class="form__label">
                          Phone number<span style="color: red">*</span></label
                        >
                        <input
                          id="phone Number"
                          name="phoneNumber"
                          formControlName="phoneNumber"
                          type="text"
                          class="fts-input-field register-w"
                        />
                      </div>
                      <div
                        *ngIf="submittedbusiness && k.phoneNumber.errors"
                        class="text-danger"
                      >
                        <div *ngIf="k.phoneNumber.errors.required">
                          Phone number
                        </div>
                      </div>
                    </div>
                    <div class="row" style="margin-top: 10px">
                      <div class="input-field col s4" style="margin-top: 5px">
                        <label for="team_size" class="form__label">
                          Registeration number<span style="color: red"
                            >*</span
                          ></label
                        >
                        <input
                          id="registerationNumber"
                          name="registeration Number"
                          formControlName="registerationNumber"
                          type="text"
                          class="fts-input-field input-width"
                        />
                        <div
                          *ngIf="
                            submittedbusiness && k.registerationNumber.errors
                          "
                          class="text-danger"
                        >
                          <div *ngIf="k.registerationNumber.errors.required">
                            Registeration number
                          </div>
                        </div>
                      </div>
                      <div class="input-field col s4" style="margin-top: 5px">
                        <label for="team_size" class="form__label">
                          Date of incorporation<span style="color: red"
                            >*</span
                          ></label
                        >
                        <input
                          id="date incorporation"
                          name="dateincorporation"
                          formControlName="dateincorporation"
                          type="date"
                          class="fts-input-field input-width"
                        />
                        <div
                          *ngIf="
                            submittedbusiness && k.dateincorporation.errors
                          "
                          class="text-danger"
                        >
                          <div *ngIf="k.dateincorporation.errors.required">
                            date incorporation
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="input-field col s4"
                      style="margin-top: 30px; margin-left: 225px"
                    >
                      <button
                        data-component="primary-button"
                        type="submit"
                        role="button"
                        tabindex="0"
                        (click)="addBusinessDirector()"
                        class="fts-button-primary-medium"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </form>
              </form>
            </div>
            <div
              class="tab-pane fade"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <form
                name="busineesroles"
                [formGroup]="busineesroles2"
                class="form-content"
                style="
                  padding: 15px 35px;
                  margin-top: 15px;
                "
              >
                <div>
                  <h1
                    data-component="heading"
                    class="small-heading"
                  >
                    Add shareholder
                  </h1>
                </div>
                <div class="row">
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label
                      data-component="form-input-name-label"
                      class="form__label"
                      for="name"
                      >Type<span style="color: red">*</span></label
                    >
                    <select
                      #shareHolderType
                      name="team_size"
                      id="team_size"
                      (change)="onShareHolderType(shareHolderType.value)"
                      class="fts-select"
                      placeholder="Date"
                    >
                      <option disabled selected>Type</option>
                      <option value="individual">Individual</option>
                      <option value="business">Business</option>
                    </select>
                    <div
                      *ngIf="submitted3 && b.type.errors"
                      class="text-danger"
                    >
                      <div *ngIf="b.type.errors.required">type is required</div>
                    </div>
                  </div>
                </div>
                <div *ngIf="shareHolderTypeIndividual">
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >would you like to use an existing person for this
                        role<span style="color: red">?</span></label
                      >
                      <select
                        #customerSelect
                        (change)="fetchshareholderData(customerSelect.value)"
                        id="team_size"
                        class="fts-select-small"
                        formControlName="person"
                        placeholder="person"
                      >
                        <option value="" disabled selected>Select user</option>
                        <option
                          *ngFor="let customer of customers"
                          [value]="customer.id"
                        >
                          {{ customer.firstName }}
                          {{ customer.lastName }}
                        </option>
                      </select>
                      <div
                        *ngIf="submitted3 && b.person.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.person.errors.required">
                          would you like to use an existing person for this role
                          is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 33px">
                      <button class="fts-button-tertiary-small" (click)="refreshshareholder()">
                        Clear
                      </button>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >First name<span style="color: red">*</span></label
                      >
                      <input
                        id="text"
                        placeholder="first "
                        type="text"
                        formControlName="firstname"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted3 && b.firstname.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.firstname.errors.required">
                          first name is required
                        </div>
                      </div>
                      <!-- <div  class="text-danger">
                            <div >Country is required</div>
                            </div> -->
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Last name<span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        placeholder="last name"
                        type="text"
                        class="fts-input-field input-width"
                        formControlName="lastname"
                      />
                      <div
                        *ngIf="submitted3 && b.lastname.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.lastname.errors.required">
                          last name is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Nationality<span style="color: red">*</span></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        formControlName="nationality"
                        class="fts-select-small"
                      >
                        <option disabled selected>Nationality</option>
                        <option
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}
                        </option>
                      </select>

                      <div
                        *ngIf="submitted3 && b.nationality.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.nationality.errors.required">
                          nationality is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Date of birth<span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        placeholder="Date of birth"
                        type="date"
                        formControlName="birth"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted && b.birth.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.birth.errors.required">
                          date of birth is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Place of birth<span style="color: red">*</span></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        class="fts-select"
                        formControlName="place"
                        placeholder="Date of birth"
                      >
                        <option disabled selected>Place of birth</option>
                        <option
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="submitted3 && b.place.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.place.errors.required">
                          place of birth is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Id document type<span style="color: red"
                          >*</span
                        ></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        class="fts-select-small"
                        formControlName="Iddoc"
                        placeholder="XXXXXXXXXXXXXXX"
                      >
                        <option disabled selected>Id document type</option>
                        <option
                          *ngFor="let type of documentsType"
                          [value]="type.id"
                        >
                          {{ type.label }}s
                        </option>
                      </select>
                      <div
                        *ngIf="submitted3 && b.Iddoc.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.Iddoc.errors.required">
                          Id document type is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Id document number<span style="color: red"
                          >*</span
                        ></label
                      >
                      <input
                        id="name"
                        placeholder="document number"
                        type="text"
                        formControlName="docnum"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted3 && b.Iddoc.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.docnum.errors.required">
                          document number is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Id document issuing country<span style="color: red"
                          >*</span
                        ></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        class="fts-select-small"
                        formControlName="docissuing"
                        placeholder="XXXXXXXXXXXXXXX"
                      >
                        <option disabled selected>document issuing</option>
                        <option
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}
                        </option>
                      </select>

                      <div
                        *ngIf="submitted3 && b.docissuing.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.docissuing.errors.required">
                          id document issuing is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Id document expiry date<span style="color: red"
                          >*</span
                        ></label
                      >
                      <input
                        id="name"
                        placeholder="document expiry"
                        formControlName="expdate"
                        type="date"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted3 && b.expdate.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.expdate.errors.required">
                          document expiry date is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Mobile number <span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        placeholder="Mobile number"
                        type="text"
                        formControlName="mobnum"
                        class="fts-input-field register-w"
                      />
                      <div
                        *ngIf="submitted3 && b.mobnum.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.mobnum.errors.required">
                          Mobile number is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Email <span style="color: red">*</span></label
                      >
                      <input
                        id="userEmail"
                        name="email"
                        type="text"
                        formControlName="email"
                        class="fts-input-field register-w"
                      />
                    </div>
                    <div
                      *ngIf="submitted3 && b.email.errors"
                      class="text-danger"
                    >
                      <div *ngIf="b.email.errors.required">
                        Email is required
                      </div>
                    </div>
                  </div>
                  <h5 class="small-heading" style="margin-top: 10px">
                    Permanent address
                    <span style="color: red">*</span>
                  </h5>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Country <span style="color: red">*</span></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        class="fts-select-small"
                        formControlName="country"
                        placeholder="XXXXXXXXXXXXXXX"
                      >
                        <option disabled selected>Country</option>
                        <option
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}
                        </option>
                      </select>

                      <div
                        *ngIf="submitted3 && b.country.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.country.errors.required">
                          Country is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label">
                        City<span style="color: red">*</span></label
                      >
                      <select
                        id="city"
                        formControlName="city"
                        class="fts-select-small"
                      >
                        <option *ngFor="let city of cities" [value]="city.id">
                          {{ city.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="submitted3 && b.city.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.city.errors.required">
                          City is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Street <span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        placeholder="Street"
                        formControlName="street"
                        type="text"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted3 && b.street.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.street.errors.required">
                          street is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label">
                        Zip code<span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        formControlName="zip"
                        placeholder="XXX"
                        type="number"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted3 && b.zip.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.zip.errors.required">
                          Zip code is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="small-heading" style="margin-top: 10px">
                    Tax information<span style="color: red">*</span>
                  </h5>
                  <div class="row" style="margin-top: 10px">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label
                        data-component="form-input-name-label"
                        class="form__label"
                        for="name"
                        >Country <span style="color: red">*</span></label
                      >
                      <select
                        name="team_size"
                        id="team_size"
                        class="fts-select-small"
                        formControlName="countryone"
                        placeholder="XXXXXXXXXXXXXXX"
                      >
                        <option disabled selected>Country</option>
                        <option
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="submitted3 && b.countryone.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.countryone.errors.required">
                          Country is required
                        </div>
                      </div>
                    </div>
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Tax identification number (tin)
                        <span style="color: red">*</span></label
                      >
                      <input
                        id="name"
                        placeholder="tax identificatin"
                        type="text"
                        formControlName="taxnum"
                        class="fts-input-field input-width"
                      />
                      <div
                        *ngIf="submitted3 && b.taxnum.errors"
                        class="text-danger"
                      >
                        <div *ngIf="b.taxnum.errors.required">
                          Tax identification numbe is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="form__label" style="margin-top: 10px">
                    Do you have a multiple citizenship<span style="color: red"
                      >*</span
                    >
                  </h5>
                  <div style="display: flex; gap: 10px">
                    <input
                      type="radio"
                      id="html"
                      formControlName="citizenship"
                      name="citizenship"
                      value="HTML"
                    />
                    <label for="html" style="margin-top: 10px">Yes</label><br />
                    <input
                      type="radio"
                      id="css"
                      formControlName="citizenship"
                      name="citizenship"
                      value="CSS"
                    />
                    <label for="css" style="margin-top: 10px">No</label><br />
                  </div>
                  <div class="row">
                    <div class="input-field col s4" style="margin-top: 5px">
                      <label for="team_size" class="form__label"
                        >Ownership <span style="color: red">*</span></label
                      >
                      <input
                        class="fts-input-field register-w"
                        type="text"
                        formControlName="position"
                        placeholder="Co"
                      />
                      <p class="text-muted">
                        Ownership of a person should be (co)
                      </p>
                    </div>
                  </div>
                  <h6 class="form__label" style="margin-top: 10px">
                    Is the person.his/her immedate family members or close
                    associates politically exposed persons (or have been so
                    during the past 12 months)<span style="color: red">?</span>
                  </h6>
                  <div style="display: flex; gap: 10px">
                    <input
                      type="radio"
                      id="html"
                      formControlName="persons"
                      name="persons"
                      value="HTML"
                    />
                    <label for="html" style="margin-top: 10px">Yes</label><br />
                    <input
                      type="radio"
                      formControlName="persons"
                      id="css"
                      name="persons"
                      value="CSS"
                    />
                    <label for="css" style="margin-top: 10px">No</label><br />
                  </div>
                  <h6 class="form__label" style="margin-top: 10px">
                    Does the beneficial owner act as a nominee<span
                      style="color: red"
                      >?</span
                    >
                  </h6>
                  <div style="display: flex; gap: 10px">
                    <input
                      type="radio"
                      id="html"
                      formControlName="nominee"
                      name="nominee"
                      value="HTML"
                    />
                    <label for="html" style="margin-top: 10px">Yes</label><br />
                    <input
                      type="radio"
                      formControlName="nominee"
                      id="css"
                      name="nominee"
                      value="CSS"
                    />
                    <label for="css" style="margin-top: 10px">No</label><br />
                  </div>
                  <h6 class="form__label" style="margin-top: 10px">
                    Does the beneficial owner act as a trustee<span
                      style="color: red"
                      >?</span
                    >
                  </h6>
                  <div style="display: flex; gap: 10px">
                    <input
                      type="radio"
                      formControlName="trustee"
                      id="html"
                      name="trustee"
                      value="HTML"
                    />
                    <label for="html" style="margin-top: 10px">Yes</label><br />
                    <input
                      type="radio"
                      id="css"
                      formControlName="trustee"
                      name="trustee"
                      value="CSS"
                    />
                    <label for="css" style="margin-top: 10px">No</label><br />
                  </div>
                  <h6 class="form__label" style="margin-top: 10px">
                    Is the beneficial owner a USA person<span style="color: red"
                      >?</span
                    >
                  </h6>
                  <div style="display: flex; gap: 10px">
                    <input
                      type="radio"
                      id="html"
                      formControlName="usaPerson"
                      name="usaPerson"
                      value="HTML"
                    />
                    <label for="html" style="margin-top: 10px">Yes</label><br />
                    <input
                      type="radio"
                      id="css"
                      formControlName="usaPerson"
                      name="usaPerson"
                      value="CSS"
                    />
                    <label for="css" style="margin-top: 10px">No</label><br />
                  </div>
                  <!-- <div class="eBKYjJ cgMkag">
                    <div class="row">
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          (click)="homePrevious()"
                          class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 buttons-Add"
                        >
                          Previous
                        </button>
                      </div>
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          (click)="routeToUboTab()"
                          class="buttons"
                          style="
                            height: 40px;
                            padding: 10px 30px;
                            min-width: 170px;
                            color: #2185f4 !important;
                          "
                        >
                          Continue
                        </button>
                      </div>
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          (click)="addShareholderData()"
                          class="epjTBn"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div> -->

                  <div class="primary-back-continue">
                    <div class="row">
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          class="fts-button-tertiary"
                          (click)="homePrevious()"
                        >
                          Previous
                        </button>
                      </div>
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          class="fts-button-secondary-small"
                          (click)="routeToUboTab()"
                        >
                          Continue
                        </button>
                      </div>
                      <div class="input-field col s4">
                        <button
                          data-component="primary-button"
                          type="submit"
                          role="button"
                          tabindex="0"
                          (click)="addShareholderData()"
                          class="fts-button-primary"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="display-table">
                    <table>
                      <thead>
                        <tr>
                          <th style="position: relative; left: 21%">Name</th>
                          <th style="position: relative; left: 32rem">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          class="t-hover"
                          *ngFor="
                            let data of shareholderDataArray;
                            let i = index
                          "
                        >
                          <td style="position: relative; left: 21%">
                            {{ data.firstname }}
                          </td>
                          <td style="position: relative; left: 32rem">
                            <div>
                              <img
                                (click)="setShareValues(data, i)"
                                src="../../../../assets/document-editor.png"
                                style="width: 22px; cursor: pointer"
                              />
                              <img
                                (click)="deleteRecord3(i)"
                                src="../../../../assets/delete.png"
                                style="width: 18px; cursor: pointer"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <form name="BusinessShare" [formGroup]="BusinessShare">
                  <div *ngIf="shareHolderTypeBusiness">
                    <div class="row" style="margin-top: 10px">
                      <div class="input-field col s4" style="margin-top: 5px">
                        <label for="team_size" class="form__label">
                          Corporate full name<span style="color: red"
                            >*</span
                          ></label
                        >
                        <input
                          id="corporateFullName"
                          name="corporateFullName"
                          type="text"
                          formControlName="corporateFullName"
                          class="fts-input-field register-w"
                        />
                        <div
                          *ngIf="submittedShare && s.corporateFullName.errors"
                          class="text-danger"
                        >
                          <div *ngIf="s.corporateFullName.errors.required">
                            Corporate full name
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" style="margin-top: 10px">
                      <div class="input-field col s4" style="margin-top: 5px">
                        <label
                          data-component="form-input-name-label"
                          class="form__label"
                          for="name"
                          >Country of incorporation<span style="color: red"
                            >*</span
                          ></label
                        >
                        <select
                          name="countryIncorporations"
                          id="countryIncorporations"
                          formControlName="countryIncorporations"
                          class="fts-select-small"
                          placeholder="countryIncorporation"
                        >
                          <option disabled selected>
                            Country of incorporation
                          </option>
                          <option>United Kingdom</option>
                        </select>
                        <div
                          *ngIf="
                            submittedShare && s.countryIncorporations.errors
                          "
                          class="text-danger"
                        >
                          <div *ngIf="s.countryIncorporations.errors.required">
                            Country of incorporation
                          </div>
                        </div>
                      </div>
                      <div class="input-field col s4" style="margin-top: 5px">
                        <label for="team_size" class="form__label">
                          Type of corporate<span style="color: red"
                            >*</span
                          ></label
                        >
                        <select
                          name="typeCorporate"
                          id="typeCorporate"
                          formControlName="typeCorporates"
                          class="fts-select-small"
                        >
                          <option disabled selected>
                            Select corporate type
                          </option>
                          <option>Business</option>
                        </select>
                        <div
                          *ngIf="submittedShare && s.typeCorporates.errors"
                          class="text-danger"
                        >
                          <div *ngIf="s.typeCorporates.errors.required">
                            Type of Corporate
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" style="margin-top: 10px">
                      <div class="input-field col s4" style="margin-top: 5px">
                        <label for="team_size" class="form__label">
                          Phone number<span style="color: red">*</span></label
                        >
                        <input
                          id="phoneNumbers"
                          name="phoneNumbers"
                          formControlName="phoneNumbers"
                          type="text"
                          class="fts-input-field register-w"
                        />
                        <div
                          *ngIf="submittedShare && s.phoneNumbers.errors"
                          class="text-danger"
                        >
                          <div *ngIf="s.phoneNumbers.errors.required">
                            Phone number
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" style="margin-top: 10px">
                      <div class="input-field col s4" style="margin-top: 5px">
                        <label for="team_size" class="form__label">
                          Registeration number<span style="color: red"
                            >*</span
                          ></label
                        >
                        <input
                          id="registerationNumber"
                          name="registerationNumber"
                          formControlName="registerationNumber"
                          type="text"
                          class="fts-input-field input-width"
                        />
                        <div
                          *ngIf="submittedShare && s.registerationNumber.errors"
                          class="text-danger"
                        >
                          <div *ngIf="s.registerationNumber.errors.required">
                            Registeration number
                          </div>
                        </div>
                      </div>
                      <div class="input-field col s4" style="margin-top: 5px">
                        <label for="team_size" class="form__label">
                          Date of incorporation<span style="color: red"
                            >*</span
                          ></label
                        >
                        <input
                          id="dateincorporation"
                          name="dateincorporation"
                          formControlName="dateincorporation"
                          type="date"
                          class="fts-input-field input-width"
                        />
                        <div
                          *ngIf="submittedShare && s.dateincorporation.errors"
                          class="text-danger"
                        >
                          <div *ngIf="s.dateincorporation.errors.required">
                            Date of incorporation
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="input-field col s4"
                      style="margin-top: 30px; margin-left: 225px"
                    >
                      <button
                        data-component="primary-button"
                        type="submit"
                        role="button"
                        tabindex="0"
                        (click)="addBusinessShare()"
                        class="fts-button-primary-medium"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </form>
              </form>
            </div>
            <div
              class="tab-pane fade"
              id="contact-1"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <form
                name="busineesroles"
                [formGroup]="busineesroles4"
                class="form-content"
                style="
                  padding: 15px 35px;
                  margin-top: 15px;
                "
              >
                <div>
                  <h1
                    data-component="heading"
                    class="small-heading"
                  >
                    Add ubo
                  </h1>
                </div>
                <div class="row" style="margin-top: 10px">
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label
                      data-component="form-input-name-label"
                      class="form__label"
                      for="name"
                      >Would you like to use an existing person for this
                      role<span style="color: red">?</span></label
                    >
                    <select
                      #customerSelect
                      name="team_size"
                      (change)="fetchUboData(customerSelect.value)"
                      name="team_size"
                      id="team_size"
                      class="fts-select-small"
                      formControlName="person"
                      placeholder="Date of birth"
                    >
                      <option value="" disabled selected>Select User</option>
                      <option
                        *ngFor="let customer of customers"
                        [value]="customer.id"
                      >
                        {{ customer.firstName }}
                        {{ customer.lastName }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted4 && d.person.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.person.errors.required">
                        person is required
                      </div>
                    </div>
                  </div>
                  <div class="input-field col s4" style="margin-top: 33px">
                    <button
                      class="fts-button-tertiary-small"
                      refreshshareholder
                      (click)="uboForm()"
                    >
                      Clear
                    </button>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px">
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label
                      data-component="form-input-name-label"
                      class="form__label"
                      for="name"
                      >First name<span style="color: red">*</span></label
                    >
                    <input
                      id="text"
                      placeholder="first name"
                      type="text"
                      formControlName="firstname"
                      class="fts-input-field input-width"
                    />
                    <div
                      *ngIf="submitted4 && d.firstname.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.firstname.errors.required">
                        first name is required
                      </div>
                    </div>
                  </div>
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label for="team_size" class="form__label"
                      >Last name<span style="color: red">*</span></label
                    >
                    <input
                      id="name"
                      placeholder="last name"
                      type="text"
                      class="fts-input-field input-width"
                      formControlName="lastname"
                    />
                    <div
                      *ngIf="submitted4 && d.lastname.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.lastname.errors.required">
                        last name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px">
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label for="team_size" class="form__label"
                      >Nationality<span style="color: red">*</span></label
                    >
                    <select
                      name="team_size"
                      id="team_size"
                      formControlName="nationality"
                      class="fts-select-small"
                    >
                      <option disabled selected>Nationality</option>
                      <option
                        *ngFor="let countrty of countries"
                        [value]="countrty.id"
                      >
                        {{ countrty.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted4 && d.nationality.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.nationality.errors.required">
                        nationality is required
                      </div>
                    </div>
                  </div>
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label for="team_size" class="form__label"
                      >Date of birth<span style="color: red">*</span></label
                    >
                    <input
                      id="name"
                      placeholder="Date of birth"
                      type="date"
                      formControlName="birth"
                      class="fts-input-field input-width"
                    />
                    <div
                      *ngIf="submitted4 && d.birth.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.birth.errors.required">
                        date of birth is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px">
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label
                      data-component="form-input-name-label"
                      class="form__label"
                      for="name"
                      >Place of birth<span style="color: red">*</span></label
                    >
                    <select
                      name="team_size"
                      id="team_size"
                      class="fts-select"
                      formControlName="place"
                      placeholder="Date of birth"
                    >
                      <option disabled selected>Birth place</option>
                      <option
                        *ngFor="let countrty of countries"
                        [value]="countrty.id"
                      >
                        {{ countrty.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted4 && d.place.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.place.errors.required">
                        place of birth is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px">
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label
                      data-component="form__label"
                      class="form__label"
                      for="name"
                      >Id document type<span style="color: red">*</span></label
                    >
                    <select
                      name="team_size"
                      id="team_size"
                      class="fts-select-small"
                      formControlName="Iddoc"
                      placeholder="XXXXXXXXXXXXXXX"
                    >
                      <option disabled selected>Id document type</option>
                      <option
                        *ngFor="let type of documentsType"
                        [value]="type.id"
                      >
                        {{ type.label }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted4 && d.Iddoc.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.Iddoc.errors.required">
                        Id document type is required
                      </div>
                    </div>
                  </div>
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label for="team_size" class="form__label"
                      >Id document number<span style="color: red"
                        >*</span
                      ></label
                    >
                    <input
                      id="name"
                      placeholder="document number"
                      type="text"
                      formControlName="docnum"
                      class="fts-input-field input-width"
                    />
                    <div
                      *ngIf="submitted4 && d.Iddoc.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.docnum.errors.required">
                        document number is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px">
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label
                      data-component="form-input-name-label"
                      class="form__label"
                      for="name"
                      >Id document issuing country<span style="color: red"
                        >*</span
                      ></label
                    >
                    <select
                      name="team_size"
                      id="team_size"
                      class="fts-select-small"
                      formControlName="docissuing"
                      placeholder="XXXXXXXXXXXXXXX"
                    >
                      <option disabled selected>document issuing</option>
                      <option
                        *ngFor="let countrty of countries"
                        [value]="countrty.id"
                      >
                        {{ countrty.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted4 && d.docissuing.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.docissuing.errors.required">
                        id document issuing is required
                      </div>
                    </div>
                  </div>
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label for="team_size" class="form__label"
                      >Id document expiry date<span style="color: red"
                        >*</span
                      ></label
                    >
                    <input
                      id="name"
                      placeholder="document expiry"
                      formControlName="expdate"
                      type="date"
                      class="fts-input-field input-width"
                    />
                    <div
                      *ngIf="submitted4 && d.expdate.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.expdate.errors.required">
                        document expiry date is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px">
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label for="team_size" class="form__label"
                      >Mobile number <span style="color: red">*</span></label
                    >
                    <input
                      id="name"
                      placeholder="mobile number"
                      type="text"
                      formControlName="mobnum"
                      class="fts-input-field register-w"
                    />
                    <div
                      *ngIf="submitted4 && d.mobnum.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.mobnum.errors.required">
                        Mobile number is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px">
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label for="team_size" class="form__label"
                      >Email <span style="color: red">*</span></label
                    >
                    <input
                      id="userEmail"
                      name="email"
                      type="text"
                      formControlName="email"
                      class="fts-input-field register-w"
                    />
                  </div>
                  <div *ngIf="submitted4 && d.email.errors" class="text-danger">
                    <div *ngIf="d.email.errors.required">Email is required</div>
                  </div>
                </div>
                <h5 class="form__label" style="margin-top: 10px">
                  Permanent address
                  <span style="color: red">*</span>
                </h5>
                <div class="row">
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label
                      data-component="form-input-name-label"
                      class="form__label"
                      for="name"
                      >Country <span style="color: red">*</span></label
                    >
                    <select
                      name="team_size"
                      id="team_size"
                      class="fts-select-small"
                      formControlName="country"
                      placeholder="XXXXXXXXXXXXXXX"
                    >
                      <option disabled selected>country</option>
                      <option
                        *ngFor="let countrty of countries"
                        [value]="countrty.id"
                      >
                        {{ countrty.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted4 && d.country.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.country.errors.required">
                        Country is required
                      </div>
                    </div>
                    <!-- <div  class="text-danger">
                          <div >Country is required</div>
                          </div> -->
                  </div>
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label for="team_size" class="form__label">
                      City<span style="color: red">*</span></label
                    >
                    <select
                      id="city"
                      formControlName="city"
                      class="fts-select-small"
                    >
                      <option *ngFor="let city of cities" [value]="city.id">
                        {{ city.name }}
                      </option>
                      <!--                                             
                            <option *ngFor="let city of cities"[value]="city.id">{{city.name}}</option>  -->
                    </select>
                    <div
                      *ngIf="submitted4 && d.city.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.city.errors.required">City is required</div>
                    </div>
                  </div>
                  <!-- <button class="btn-remove" style="display: flex; gap: 15px;">
                        <img style="height: 20px; width: 20px;" src="../../../assets/addicon.png" alt="" srcset="">
                        <img style="height: 15px; width: 15px;" src="../../../assets/crossicon.png" alt="" srcset="">
                        </button> -->
                </div>
                <div class="row">
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label
                      data-component="form-input-name-label"
                      class="form__label"
                      for="name"
                      >Street <span style="color: red">*</span></label
                    >
                    <input
                      id="name"
                      placeholder="Street"
                      formControlName="street"
                      type="text"
                      class="fts-input-field input-width"
                    />
                    <div
                      *ngIf="submitted4 && d.street.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.street.errors.required">
                        street is required
                      </div>
                    </div>
                    <!-- <div  class="text-danger">
                          <div >Country is required</div>
                          </div> -->
                  </div>
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label for="team_size" class="form__label">
                      Zip code<span style="color: red">*</span></label
                    >
                    <input
                      id="name"
                      formControlName="zip"
                      placeholder="XXX"
                      type="number"
                      class="fts-input-field input-width"
                    />
                    <div *ngIf="submitted4 && d.zip.errors" class="text-danger">
                      <div *ngIf="d.zip.errors.required">
                        Zip code is required
                      </div>
                    </div>
                  </div>
                  <!-- <button class="btn-remove" style="display: flex; gap: 15px;">
                        <img style="height: 20px; width: 20px;" src="../../../assets/addicon.png" alt="" srcset="">
                        <img style="height: 15px; width: 15px;" src="../../../assets/crossicon.png" alt="" srcset="">
                        </button> -->
                </div>
                <h5 class="form__label" style="margin-top: 10px">
                  Tax information<span style="color: red">*</span>
                </h5>
                <div class="row">
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label
                      data-component="form-input-name-label"
                      class="form__label"
                      for="name"
                      >Country <span style="color: red">*</span></label
                    >
                    <select
                      name="team_size"
                      id="team_size"
                      class="fts-select-small"
                      formControlName="countryone"
                      placeholder="XXXXXXXXXXXXXXX"
                    >
                      <option disabled selected>Country</option>
                      <!--<option *ngFor="let countrty of countries" [value]="countrty.id">{{countrty.name}}
                            </option> -->
                      <option
                        *ngFor="let countrty of countries"
                        [value]="countrty.id"
                      >
                        {{ countrty.name }}
                      </option>
                    </select>

                    <div
                      *ngIf="submitted4 && d.countryone.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.countryone.errors.required">
                        Country is required
                      </div>
                    </div>
                    <!-- <div  class="text-danger">
                          <div >Country is required</div>
                          </div> -->
                  </div>
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label for="team_size" class="form__label"
                      >Tax Identification Number (tin)
                      <span style="color: red">*</span></label
                    >
                    <input
                      id="name"
                      placeholder="tax identification"
                      type="text"
                      formControlName="taxnum"
                      class="fts-input-field input-width"
                    />
                    <div
                      *ngIf="submitted4 && d.taxnum.errors"
                      class="text-danger"
                    >
                      <div *ngIf="d.taxnum.errors.required">
                        Tax identification number is required
                      </div>
                    </div>
                  </div>
                </div>
                <h5 class="form__label" style="margin-top: 10px">
                  Do you have a multiple citizenship<span style="color: red"
                    >*</span
                  >
                </h5>
                <div style="display: flex; gap: 10px">
                  <input
                    type="radio"
                    formControlName="citizenship"
                    id="html"
                    name="citizenship"
                    value="HTML"
                  />
                  <label for="html" style="margin-top: 10px">Yes</label><br />
                  <input
                    type="radio"
                    formControlName="citizenship"
                    id="css"
                    name="citizenship"
                    value="CSS"
                  />
                  <label for="css" style="margin-top: 10px">No</label><br />
                </div>
                <div class="row">
                  <div class="input-field col s4" style="margin-top: 5px">
                    <label for="team_size" class="form__label"
                      >Ownership <span style="color: red">*</span></label
                    >
                    <input
                      class="fts-input-field register-w"
                      type="text"
                      formControlName="position"
                      placeholder="Ownership"
                    />
                    <p class="text-muted">
                      Ownership of a person should be (co)
                    </p>
                  </div>
                </div>
                <h6 class="form__label" style="margin-top: 10px">
                  Is the person.his/her immedate family members or close
                  associates politically exposed persons (or have been so during
                  the past 12 months)<span style="color: red">?</span>
                </h6>
                <div style="display: flex; gap: 10px">
                  <input
                    type="radio"
                    id="html"
                    name="persons"
                    placeholder="persons"
                    value="HTML"
                  />
                  <label for="html" style="margin-top: 10px">Yes</label><br />
                  <input
                    type="radio"
                    id="css"
                    placeholder="persons"
                    name="persons"
                    value="CSS"
                  />
                  <label for="css" style="margin-top: 10px">No</label><br />
                </div>
                <h6 class="form__label" style="margin-top: 10px">
                  Does the beneficial owner act as a nominee<span
                    style="color: red"
                    >?</span
                  >
                </h6>
                <div style="display: flex; gap: 10px">
                  <input
                    type="radio"
                    id="html"
                    placeholder="nominee"
                    name="nominee"
                    value="HTML"
                  />
                  <label for="html" style="margin-top: 10px">Yes</label><br />
                  <input
                    type="radio"
                    placeholder="nominee"
                    id="css"
                    name="nominee"
                    value="CSS"
                  />
                  <label for="css" style="margin-top: 10px">No</label><br />
                </div>
                <h6 class="form__label" style="margin-top: 10px">
                  Does the beneficial owner act as a trustee<span
                    style="color: red"
                    >?</span
                  >
                </h6>
                <div style="display: flex; gap: 10px">
                  <input
                    type="radio"
                    id="html"
                    placeholder="trustee"
                    name="trustee"
                    value="HTML"
                  />
                  <label for="html" style="margin-top: 10px">Yes</label><br />
                  <input
                    type="radio"
                    id="css"
                    placeholder="trustee"
                    name="trustee"
                    value="CSS"
                  />
                  <label for="css" style="margin-top: 10px">No</label><br />
                </div>
                <h6 class="form__label" style="margin-top: 10px">
                  Is the beneficial owner a USA person<span style="color: red"
                    >?</span
                  >
                </h6>
                <div style="display: flex; gap: 10px">
                  <input
                    type="radio"
                    id="html"
                    placeholder="usaPerson"
                    name="usaPerson"
                    value="HTML"
                  />
                  <label for="html" style="margin-top: 10px">Yes</label><br />
                  <input
                    type="radio"
                    id="css"
                    placeholder="usaPerson"
                    name="usaPerson"
                    value="CSS"
                  />
                  <label for="css" style="margin-top: 10px">No</label><br />
                </div>
                <div class="row mt-3">
                  <div class="center"></div>
                </div>
                <!-- <div class="eBKYjJ cgMkag">
                  <div class="row">
                    <div class="input-field col s4">
                      <button
                        data-component="primary-button"
                        type="submit"
                        role="button"
                        tabindex="0"
                        (click)="homePrevious()"
                        class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 buttons-Add"
                      >
                        Previous
                      </button>
                    </div>
                    <div class="input-field col s4">
                      <button
                        data-component="primary-button"
                        type="submit"
                        role="button"
                        tabindex="0"
                        (click)="routeToPersonal()"
                        class="buttons"
                        style="
                          height: 40px;
                          padding: 10px 30px;
                          min-width: 170px;
                          color: #2185f4 !important;
                        "
                      >
                        Continue
                      </button>
                    </div>
                    <div class="input-field col s4">
                      <button
                        data-component="primary-button"
                        type="submit"
                        role="button"
                        tabindex="0"
                        (click)="addUboData()"
                        class="epjTBn"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div> -->

                <div class="primary-back-continue">
                  <div class="row">
                    <div class="input-field col s4">
                      <button
                        data-component="primary-button"
                        type="submit"
                        role="button"
                        tabindex="0"
                        class="fts-button-tertiary"
                        (click)="homePrevious()"
                      >
                        Previous
                      </button>
                    </div>
                    <div class="input-field col s4">
                      <button
                        data-component="primary-button"
                        type="submit"
                        role="button"
                        tabindex="0"
                        class="fts-button-secondary-small"
                        (click)="routeToPersonal()"
                      >
                        Continue
                      </button>
                    </div>
                    <div class="input-field col s4">
                      <button
                        data-component="primary-button"
                        type="submit"
                        role="button"
                        tabindex="0"
                        (click)="addUboData()"
                        class="fts-button-primary"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>

                <div class="display-table">
                  <table>
                    <thead>
                      <tr>
                        <th style="position: relative; left: 21%">Name</th>
                        <th style="position: relative; left: 32rem">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        class="t-hover"
                        *ngFor="let data of UboDataArray; let i = index"
                      >
                        <td style="position: relative; left: 21%">
                          {{ data.firstname }}
                        </td>
                        <td style="position: relative; left: 32rem">
                          <div>
                            <img
                              (click)="setUboValues(data, i)"
                              src="../../../../assets/document-editor.png"
                              style="width: 22px; cursor: pointer"
                            />
                            <img
                              (click)="deleteRecord2(i)"
                              src="../../../../assets/delete.png"
                              style="width: 18px; cursor: pointer"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-card-large>

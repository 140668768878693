import { Component, Input } from '@angular/core';

@Component({
  selector: 'login-tabs',
  templateUrl: './login-tabs.component.html',
  styleUrls: ['./login-tabs.component.css']
})
export class LoginTabsComponent {

  @Input() public labelOne: string = '';
  @Input() public imageOne: string;
  @Input() public labelTwo: string = '';
  @Input() public imageTwo: string;


  constructor() {
    this.imageOne = '';
    this.imageTwo = '';

    localStorage.setItem('companyCode', "Business");
    localStorage.setItem('signupRole', "Business");
  }

  businessTab() {
    localStorage.setItem('companyCode', "Business");
    localStorage.setItem('signupRole', "Business");
  }

  individualTab() {
    localStorage.setItem('companyCode', "Personal");
    localStorage.setItem('signupRole', "Individual");
  }

}

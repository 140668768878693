<div>
  <ul class="tabs">
    <li class="tab">
      <a href="#local" (click)="businessTab()" >
        <round-button image={{imageTwo}} label={{labelTwo}}></round-button>
      </a>
    </li>

    <li class="tab ml-35">
      <a active href="#add" (click)="individualTab()">
        <round-button image={{imageOne}} label={{labelOne}}></round-button>
      </a>
    </li>
  </ul>
</div>

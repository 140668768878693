import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromCustomer from '../reducer/businessAddress.reducer';

export const selectBusinessState = createFeatureSelector<fromCustomer.BusinessAddressState>(
  fromCustomer.businessAddressFeatureKey,
);


export const selectBusinessAddress = createSelector(
  selectBusinessState,
  (state: fromCustomer.BusinessAddressState) => state.businessAddress
);

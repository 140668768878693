import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromCustomer from '../reducer/businessOwner.reducer';

export const selectBusinessOwnerState = createFeatureSelector<fromCustomer.BusinessOwnerState>(
  fromCustomer.businessOwnerFeatureKey,
);


export const selectBusinessOwner = createSelector(
  selectBusinessOwnerState,
  (state: fromCustomer.BusinessOwnerState) => state.businessOwner
);

import {Action, createReducer, on} from '@ngrx/store';
import * as BusinessActions from '../action/personal.actions';
import * as BusinessAddressAction from '../action/personalAddress.actions';

export const personalFeatureKey = 'personalDetail';

export interface personalDetailState {
  personalInformation: {}
}

export const initialState: personalDetailState = {
  personalInformation: {}
};

export const personalReducer = createReducer(
  initialState,
  on(BusinessActions.addPerson,
    (state: personalDetailState, {personalInformation}) =>
      ({...state,
        personalInformation,
      }))
);



export function PersonalReducer(state: personalDetailState | undefined, action: Action): any {
  return personalReducer(state, action);
}
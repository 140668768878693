import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromCustomer from '../reducer/personalDocument.reducer';

export const selectPersonalDocState = createFeatureSelector<fromCustomer.PersonalDocumentState>(
  fromCustomer.personalDocumentFeatureKey,
);


export const selectPersonalDocument = createSelector(
  selectPersonalDocState,
  (state: fromCustomer.PersonalDocumentState) => state.personalDocument
);

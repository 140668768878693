
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SignUpService } from '../service/sign-up.service';
import * as _ from 'lodash';
import { Account } from '../model/account';
import { Otp } from '../model/otp';
import { Address } from '../model/address';
import { Document } from '../model/document';
import { Customer } from '../model/customer';
import { Business } from '../model/business';
import { BusinessCustomer } from '../model/businessCustomer';
import { Subject, Observable } from 'rxjs';
import * as data from '../forms-meta/forms.json';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
declare var $: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})


export class SignUpComponent implements OnInit {

  @ViewChild("passbaseButton") passbaseButton: ElementRef;

  public termsAndCondition: boolean = false;
  public privacyPolicy: boolean = false;
  public selectedType: string;
  public forms: any[];
  public formSubmitted: any = {};
  public formGroups: any = {};
  public currentForm: any = null;
  public hasNewSteps: boolean = false;
  private res = {};
  public checkType: String = null;
  public isSuccess: boolean = false;
  public isApiSuccess: boolean = false;
  public isError: boolean;
  public isApiError: boolean;
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public userId: number;
  public videoOptions: MediaTrackConstraints = {
  };

  public request = {
    FullName: "",
    firstName: "",
    lastName: "",
    gender: "",
    nationality: "",
    email: "",
    phone: "",
    dateOfBirth: "",
    type: "",
    otpId1: "",
    otpId2: "",
    otpId3: "",
    otpId4: "",
    addressLine1: "",
    businessAddressLine1:"",
    businessAddressLine2:"",
    businessPostalCode:"",
    businessCity:"",
    businesssProvince:"",
    businesDocFront:"",
    businesDocBack:"",
    businessCountry:"",
    addressLine2: "",
    city: "",
    postalCode: "",
    state: "",
    country: "",
    verification: "",
    passport:"",
    idDocNum: "",
    idFront: "",
    idBack: "",
    businessType:"",
    registeredName:"",
    registerationNumber:"",
    taxNumber:"",
    industry:"",
    subIndustry:"",
    website:"",
    registrationType:""
  };


  public errors: WebcamInitError[] = [];
  public webcamImage: WebcamImage = null;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  constructor(private signUpService: SignUpService,
    private ngxLoader: NgxUiLoaderService,
    private formBuilder: FormBuilder) {
  }

  ngAfterViewInit() {
    const onFinished = (error, authKey, additionalAttributes) => {
      console.log(authKey);
      if (error) {
        console.error(error);
      }
      else {
        setTimeout(function () {
          fetch(
            `https://api.passbase.com/api/v1/authentications/by_key/${authKey}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'IRaWoy8CDzsmDCkgJjjaQjc33DevnkyZnIyOiMgOlGH6u0oE9PZkVSGE5zy8ymuALILS5ml6mDshn4AwssgZbJTqfqDcrWHnt6NuZFhYcV8KBEkSbmPiibuikYvPopup'
              }
            }
          ).then(response => {
            this.res = response.json;
            //  let passBaseRes=this.res.authentication.authentication_assessments
            console.log(this.res.authentication);
            // console.log(passBaseRes);
          });
        }, 99900)
      }
    }


    // Passbase.renderButton(
    //   this.passbaseButton.nativeElement,
    //   onFinished,
    //   "MnzyWdfrNIAGxu1lUp6l16RpAxGPvHAmoadigB8jMeCsN6x5xoqloZ6HL2TQGfbg",
    //   {
    //     integrationType: "signup",
    //     theme: {
    //       darkTheme: true,
    //       systemDarkMode: true
    //     }
    //   }
    // );

  }

  ngOnInit() {
    this.forms = (data as any).default;
    let countryForm: FormGroup = this.formBuilder.group({
      country: ['', Validators.required]
    });

    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
    this.formGroups["countryForm"] = countryForm;
    this.formSubmitted['countryForm'] = false;

  }


  addnewUser() {
    let formObj = [
      {
        "fieldName": "firstName1",
        "label": "First Name",
        "placeholder": "First Name",
        "type": "text",
        "required": true,
        "minLength": 4,
        "columnSize": "col-12"
      },
      {
        "fieldName": "lastName1",
        "label": "Last Name",
        "placeholder": "Last Name",
        "type": "text",
        "required": false,
        "minLength": 4,
        "columnSize": "col-12"
      },
      {
        "fieldName": "dateOfBirth",
        "label": "Date of Birth",
        "placeholder": "yyyy-mm-dd",
        "type": "date",
        "required": true,
        "minValue": "1900-01-01",
        "columnSize": "col-4"
      },
      {
        "fieldName": "gender",
        "label": "Gender",
        "placeholder": "Gender",
        "type": "select",
        "required": true,
        "options": [
          {
            "label": "Male",
            "value": "male"
          },
          {
            "label": "Female",
            "value": "female"
          }
        ],
        "columnSize": "col-4"
      },
      {
        "fieldName": "nationality",
        "label": "Nationality",
        "placeholder": "Nationality",
        "type": "text",
        "required": true,
        "minLength": 5,
        "columnSize": "col-4"
      }, {
        "fieldName": "addUser",
        "type": "button",
        "value": "add",
        "columnSize": "col m12 s12"
      }
    ];

    let control = this.forms[1].steps[5].fields;
    for (var key in formObj) {
      control.push(formObj[key])
    }
  }

  changeCountry() {
    this.formSubmitted['countryForm'] = true;
    if (this.formGroups['countryForm'].invalid) {
      return;
    }
    this.hasNewSteps = false;
    this.currentForm = this.forms.filter(x => x.country === this.formGroups['countryForm'].value['country'])[0];
    this.currentForm.steps.forEach(step => {
      let form = {};
      step.fields.forEach(field => {
        let f: any[] = [''];
        let v: any[] = [];
        let t: any[] = [];
        form[field.type] = t;

        if (field.required) {
          v.push(Validators.required);
        }
        if (field.minLength !== undefined) {
          v.push(Validators.minLength(field.minLength));
        }
        if (field.maxLength !== undefined) {
          v.push(Validators.maxLength(field.maxLength));
        }
        if (field.type === 'email') {
          v.push(Validators.email);
        }
        if (field.minValue !== undefined) {
          v.push(Validators.min(field.minValue))
        }
        if (field.maxValue !== undefined) {
          v.push(Validators.max(field.maxValue))
        }
        if (field.pattern !== undefined) {
          v.push(Validators.pattern(field.pattern));
        }
        if (v.length > 1) {
          f.push(v);
          // console.log(field.type);
        } else if (v.length == 1) {
          // console.log(field.type);
          f.push(v[0]);
        }
        form[field.fieldName] = f;

      });
      this.formGroups[step.stepId] = this.formBuilder.group(form);
    });
    if (this.currentForm.steps.length > 0) {
      this.hasNewSteps = true;
      this.ngxLoader.start();
      setTimeout(() => {
        document.getElementById('countryWindow').classList.add('d-none');
        document.getElementById('countryStep').classList.remove('font-weight-bold-500');
        let next = this.currentForm.steps[0].uniqueId;
        document.getElementById(next + 'Step').classList.add('font-weight-bold-500');
        document.getElementById(next + 'Window').classList.remove('d-none');
        this.ngxLoader.stop();
      }, 0);
    }
  }

  onOptionsSelected(event) {
    if (event == 'IdCard' || event == 'Passport') {
      this.checkType = event;
    }
  }

  showSuccess() {
    setTimeout(function () {
      this.isError = false;
      $('#successMessage').fadeOut();
    }, 1000);
  }

  showError() {
    this.isError = true;
    setTimeout(function () {
      $('#errorMessage').fadeOut();
    }, 1000);
  }

  createUser(request) {
    this.signUpService.saveUser(request).subscribe((result) => {
      if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
        this.userId = result.responseData.user.id;
        this.isSuccess = true;
        this.isError = false;
        this.showSuccess();
      }
      else {
        this.isError = true;
        this.showError();
      }
    }, error => { console.log(error) });
  }

  createMerchant(request) {
    this.signUpService.createBusiness(request).subscribe((result) => {
      if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
        this.userId = result.responseData.user.id;
        this.isApiSuccess = true;
        this.isApiError = false;
        this.showSuccess();
      }
      else {
        this.isApiError = true;
        this.showError();
      }
    }, error => { console.log(error) });
  }

  createCustomer(request) {
    this.signUpService.createCustomer(request).subscribe((result) => {
      if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
        this.userId = result.responseData.user.id;
        this.isApiSuccess = true;
        this.isApiError = false;
        this.showSuccess();
      }
      else {
        this.isApiError = true;
        this.showError();
      }
    }, error => { console.log(error) });
  }

  verifyOtp(request) {
    this.signUpService.sendOtp(request).subscribe((result) => {
      if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
        this.isSuccess = true;
        this.isError = false;
        this.showSuccess();
      }
      else {
        this.isError = true;
        this.showError();
      }
    }, error => { console.log(error) })
  }

  continue(current, next) {
    this.formSubmitted[current + 'Step'] = true;
    if (this.formGroups[current + 'Step'].invalid) {
      console.log(this.formGroups[current + 'Step'].controls);
      return;
    }

    this.currentForm.steps.forEach(x => {
      _.merge(this.request, this.formGroups[x.stepId].value);
    });


    if (this.checkType != null) {
      if (this.checkType == 'IdCard') {
        next = "cnic";
        this.checkType = null;
      }
      else if (this.checkType == 'Passport') {
        next = "fileInfo";
        this.checkType = null;
      }
    }
    else if (next == "contactInfo") {
      this.currentForm.steps.forEach(x => {
        _.merge(this.request, this.formGroups[x.stepId].value);
      });
      let accountModel = new Account(null, this.request.FullName, this.request.phone, this.request.email, this.selectedType);
      this.createUser(accountModel);

    }
    else if (current == "contactInfo") {
      this.currentForm.steps.forEach(x => {
        _.merge(this.request, this.formGroups[x.stepId].value);
      });
      let finalOtp = `${this.request.otpId1}${this.request.otpId2}${this.request.otpId3}${this.request.otpId4}`;
      const otpModel = new Otp(this.userId, finalOtp)
      this.verifyOtp(otpModel);
    }

    setTimeout(() => {
      if (!this.isError) {
        document.getElementById(current + 'Window').classList.add('d-none');
        document.getElementById(current + 'Step').classList.remove('font-weight-bold-500');
        document.getElementById(next + 'Window').classList.remove('d-none');
        document.getElementById(next + 'Step').classList.add('font-weight-bold-500');
      }
      else {
      }
    }, 100);
    // console.log(this.request);   
    // console.log(current,next);

  }

  back(current, back) {
    document.getElementById(current + 'Window').classList.add('d-none');
    document.getElementById(current + 'Step').classList.remove('font-weight-bold-500');
    if (back == null) {
      back = this.currentForm.steps[this.currentForm.steps.length - 1].uniqueId;
    }
    document.getElementById(back + 'Window').classList.remove('d-none');
    document.getElementById(back + 'Step').classList.add('font-weight-bold-500');
  }

  createAccount() {
    document.getElementById('termsAndConditionError').classList.add('d-none');
    document.getElementById('privacyPolicyError').classList.add('d-none');
    if (!this.termsAndCondition || !this.privacyPolicy) {
      if (!this.termsAndCondition)
        document.getElementById('termsAndConditionError').classList.remove('d-none');
      if (!this.privacyPolicy)
        document.getElementById('privacyPolicyError').classList.remove('d-none');
      return;
    }
    this.ngxLoader.start();
    let request = {};
    this.currentForm.steps.forEach(x => {
      _.merge(request, this.formGroups[x.stepId].value);
    });

    const addressModel = new Address(null, this.request.addressLine1,
      this.request.addressLine2, this.request.city, this.request.postalCode,
      this.request.state, this.request.country);

    let userDocument: Document[] = [];

    if (this.request.verification == 'IdCard') {
      let front = new Document(null, this.request.verification,
        this.request.idDocNum, this.request.idFront, 1);
        userDocument.push(front);

      let back = new Document(null, this.request.verification,
        this.request.idDocNum, this.request.idBack, 1);
        userDocument.push(back);
    }
    else{
      let passportDocumet = new Document(null, this.request.verification,
        this.request.idDocNum, this.request.passport, 1);
        userDocument.push(passportDocumet);
    }

  if(this.selectedType =="Individual"){

    let accountModel = new Account(null, this.request.FullName, this.request.phone, this.request.email, this.selectedType);

    const customerModel = new BusinessCustomer(null, accountModel,"operator",
      this.request.firstName, this.request.lastName, this.request.gender,
      this.request.nationality, this.request.dateOfBirth, 0, addressModel, userDocument);
    this.createCustomer(customerModel);
}

else{
    let customerList: BusinessCustomer[] = [];  
    let businessDocument: Document[] = [];

    let accountModel = new Account(null, this.request.FullName, this.request.phone, this.request.email, this.selectedType);
    
    var businessDoc = new Document(null, this.request.verification,
      this.request.idDocNum, this.request.businesDocFront, 1);

      businessDocument.push(businessDoc);
      var businessDoc = new Document(null, this.request.verification,
        this.request.idDocNum, this.request.businesDocBack, 1);
  
        businessDocument.push(businessDoc);
        
    var custModel = new BusinessCustomer(null, accountModel,"operator",this.request.firstName, 
    this.request.lastName,this.request.gender,this.request.nationality, 
    this.request.dateOfBirth, 0,addressModel,userDocument);

    customerList.push(custModel);

    const businessAddressModel = new Address(null, this.request.businessAddressLine1,
    this.request.businessAddressLine2, this.request.businessCity, this.request.businessPostalCode,
    this.request.businesssProvince, this.request.businessCountry);

    const merchantModel = new Business(null,this.request.registeredName,this.request.registerationNumber,
    this.request.taxNumber,this.request.website,"registered-date","0",businessAddressModel,businessDocument,customerList)
    this.createMerchant(merchantModel);
  }

  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    // console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    console.log(webcamImage.imageAsDataUrl);
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
}
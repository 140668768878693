<app-card-large>
  <div class="dyEAXN">
    <!-- RIGHT PANEL-->
    <div class="bCDrPV djdIYm">
      <div class="mjYRwVK">
        <!-- FORM CONTENT-->
        <div class="main-panel__content">
          <div class="main-panel__content">
            <h1 class="main-heading nonreferral">Account Information</h1>
            <h2 class="main-sub-heading">
              To get started, please provide your work email and phone number
            </h2>
          </div>
        </div>

        <!-- <form name="form" [formGroup]="accountForm">
          <div class="form__page sign-up__step-one">
            <div class="form__group">
              <div class="form__group">
                <label
                  data-component="form-input-email-label"
                  for="email"
                  class="form__label"
                  >Work Email Address</label
                >
                <input
                  id="userEmail"
                  name="email"
                  type="text"
                  formControlName="userEmail"
                  [ngClass]="{ invalid: submitted && f.userEmail.errors }"
                  autocomplete="username"
                  class="fts-input-field register-w"
                />
              </div>
            </div>
            <div class="form__group">
              <div class="form__group">
                <label
                  data-component="form__label"
                  for="name"
                  class="form__label"
                  >Full Name <span style="color: red">*</span></label
                >
                <input
                  id="name"
                  formControlName="fullName"
                  [ngClass]="{ invalid: submitted && f.fullName.errors }"
                  name="name"
                  type="text"
                  class="fts-input-field register-w"
                  value=""
                />
                <div *ngIf="submitted && f.fullName.errors" class="text-danger">
                  <div *ngIf="f.fullName.errors.required">
                    Full Name is required
                  </div>
                  <div *ngIf="f.fullName.errors?.pattern">
                    Please provide a valid Name
                  </div>
                </div>
              </div>
            </div>

            <div class="form__group">
              <div class="form__group">
                <label
                  data-component="form__label"
                  for="name"
                  class="form__label"
                  >Phone Number <span style="color: red">*</span></label
                >
                <input
                  id="name"
                  formControlName="phoneNumber"
                  [mask]="'999999999999999'"
                  placeholder="XXXXXXXXXXXXXXX"
                  [ngClass]="{ invalid: submitted && f.phoneNumber.errors }"
                  type="text"
                  class="fts-input-field register-w"
                />
                <div
                  *ngIf="submitted && f.phoneNumber.errors"
                  class="text-danger"
                >
                  <div *ngIf="f.phoneNumber.errors.required">
                    Phone Number is required
                  </div>
                </div>
              </div>
            </div>
            <div class="primary-back-continue">
              <div class="">
                <button
                  data-component="primary-button"
                  type="submit"
                  role="button"
                  tabindex="0"
                  (click)="onPrevious()"
                  class="fts-button-tertiary"
                >
                  Previous
                </button>
              </div>
              <div class="">
                <button
                  data-component="primary-button"
                  type="submit"
                  role="button"
                  tabindex="0"
                  (click)="addAccount()"
                  class="fts-button-primary"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </form> -->
        <form name="form" [formGroup]="accountForm">
          <div class="form__group">
            <div class="form__group">
              <label data-component="form__label" for="name" class="form__label"
                >Full Name <span style="color: red">*</span></label
              >
              <input
                id="name"
                formControlName="fullName"
                [ngClass]="{ invalid: submitted && f.fullName.errors }"
                name="name"
                type="text"
                class="fts-input-field register-w"
                value=""
              />
              <div *ngIf="submitted && f.fullName.errors" class="text-danger">
                <div *ngIf="f.fullName.errors.required">
                  Full Name is required
                </div>
                <div *ngIf="f.fullName.errors?.pattern">
                  Please provide a valid Name
                </div>
              </div>
            </div>
          </div>

          <div class="input-field" style="margin-top: 10px">
            <label
              data-component="form-input-email-label"
              for="email"
              class="form__label"
            >
              Company name<span style="color: red">*</span></label
            >
            <input
              id="companyName"
              name="companyName"
              type="text"
              formControlName="companyName"
              [ngClass]="{ invalid: submitted && f.companyName.errors }"
              autocomplete="username"
              class="fts-input-field register-w"
            />
          </div>

          <div class="row">
            <div class="input-field col s4" style="margin-top: 10px">
              <label
                data-component="form-input-email-label"
                for="email"
                class="form__label"
                >Work email address<span style="color: red">*</span></label
              >
              <input
                id="userEmail"
                name="email"
                type="text"
                formControlName="userEmail"
                [ngClass]="{ invalid: submitted && f.userEmail.errors }"
                autocomplete="username"
                class="fts-input-field register-w"
              />
              <div *ngIf="isError" class="text-danger">
                <div>Email already registered</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s4" style="margin-top: 10px">
              <label
                data-component="form-input-name-label"
                for="name"
                class="form__label"
                >Password <span style="color: red">*</span></label
              >
              <input
                id="name"
                formControlName="password"
                [ngClass]="{ invalid: submitted && f.password.errors }"
                name="password"
                type="password"
                class="fts-input-field input-width"
                value
              />
              <div style="color: red" *ngIf="f.password.hasError('required')">
                Password is required
              </div>
              <div style="color: red" *ngIf="f.password.hasError('minlength')">
                Password must be at least 8 characters long
              </div>
            </div>
            <div class="input-field col s4" style="margin-top: 10px">
              <label
                data-component="form-input-name-label"
                for="name"
                class="form__label"
                >Confirm password <span style="color: red">*</span></label
              >
              <input
                id="password"
                formControlName="confirmPassword"
                [ngClass]="{ invalid: f.confirmPassword.errors }"
                name="name"
                type="password"
                class="fts-input-field input-width"
                value
              />
              <div
                style="color: red"
                *ngIf="f.confirmPassword.hasError('required')"
              >
                Confirm password is required
              </div>
              <div style="color: red" *ngIf="passwordNotMatch">
                Passwords do not match
              </div>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s4" style="margin-top: 10px">
              <label
                data-component="form-input-name-label"
                for="name"
                class="form__label"
                >Phone Number <span style="color: red">*</span></label
              >
              <input
                id="name"
                formControlName="phoneNumber"
                placeholder="+XX-XXXXXXXXXXXX"
                (input)="validateMENAPhoneNumber()"
                [ngClass]="{ invalid: submitted && f.phoneNumber.errors }"
                type="text"
                class="fts-input-field register-w"
              />
              <div
                *ngIf="submitted && f.phoneNumber.errors"
                class="text-danger"
              >
                <div *ngIf="f.phoneNumber.hasError('required')">
                  Phone number is required
                </div>
                <div *ngIf="f.phoneNumber.hasError('invalidMENA')">
                  Invalid country code
                </div>
              </div>
              <div style="color: red" *ngIf="numberAlreadyExit">
                Phone number already exit
              </div>
            </div>
          </div>
          <div class="primary-back-continue">
            <!-- <div class="">
              <button
                data-component="primary-button"
                type="submit"
                role="button"
                tabindex="0"
                (click)="onPrevious()"
                class="fts-button-tertiary"
              >
                Previous
              </button>
            </div> -->
            <div class="">
              <button
                data-component="primary-button"
                type="submit"
                role="button"
                tabindex="0"
                (click)="addAccount()"
                class="fts-button-primary"
              >
                Continue
              </button>
            </div>
          </div>
          <!-- <div class="eBKYjJ cgMkag">
            <div class="row">
              <div class="input-field col s4">
                <button
                  data-component="primary-button"
                  type="submit"
                  role="button"
                  tabindex="0"
                  (click)="onPrevious()"
                  class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 buttons-Add"
                >
                  Previous
                </button>
              </div>
              <div class="col s4">
                <button class="epjTBn" (click)="addAccount(mymodal)">
                  Continue
                </button>
              </div>
            </div>
          </div> -->
        </form>
      </div>
      <!--FORM CONTENT -->
    </div>
  </div>
</app-card-large>
<!-- RIGHT PANEL-->
<ng-template #mymodal let-modal style="margin-left: 300px">
  <div style="padding: 15px 15px">
    <div class="bgBody" style="overflow-y: scroll; margin-top: 10px">
      <p style="padding: 10px 10px; border: 1px solid black; margin-top: 10px">
        Thanks for verifying your email address! Thanks for verifying your email
        address! Thanks for verifying your email address! Thanks for verifying
        your email address! Thanks for verifying your email address! Thanks for
        verifying your email address! Thanks for verifying your email address!
        Thanks for verifying your email address! Thanks for verifying your email
        address!
      </p>
      <div style="margin-top: 10px">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value
            id="flexCheckDefault"
          />
          <label class="form-check-label" for="flexCheckDefault">
            I ACCEPT THE TERMS AND CONDITIONSAND PRIVACY POLICY
          </label>
        </div>
      </div>
      <div style="display: flex; justify-content: center; gap: 20px">
        <div>
          <button
            class="fts-button-tertiary"
            value="success"
            (click)="modal.close('Save click')"
          >
            CANCIL
          </button>
        </div>
        <div>
          <button
            class="fts-button-primary"
            value="success"
            (click)="modal.close('Save click')"
          >
            REGISTER
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<card-container>
  <div class="dyEAXN">
    <!-- RIGHT PANEL-->
    <div class="bCDrPV djdIYm">
      <div class="jYRwVK">
        <!-- FORM CONTENT-->
        <div class="hnZgAZ">
          <div class="husZCp">
            <h1
              data-component="heading"
              class="main-panel__heading nonreferral"
            >
              Verify your email address
            </h1>
            <h2 data-component="subheading" class="main-panel__subheading">
              <div class="main-panel__subheading">
                We just emailed a six-digit code to <b>{{ currentEmail }}</b
                >.<br />Keep this window open while you check your inbox, then
                enter the code below.
              </div>
            </h2>
          </div>
        </div>

        <form name="form" [formGroup]="optForm">
          <!-- <div class="djdIYm">
                    <div class="djdIYm"> -->
          <div class="otp">
            <input
              type="text"
              data-component="verification-input-0"
              name="segment-input-0"
              maxlength="1"
              placeholder="0"
              formControlName="first"
              [ngClass]="{ invalid: submitted && f.first.errors }"
              class="verify-otp-fields col-lg-1"
              autocomplete="off"
              value=""
              #first
            />

            <input
              type="text"
              data-component="verification-input-1"
              name="segment-input-1"
              maxlength="1"
              placeholder="0"
              formControlName="second"
              [ngClass]="{ invalid: submitted && f.second.errors }"
              class="verify-otp-fields col-lg-1"
              autocomplete="off"
              value=""
              #second
            />

            <input
              type="text"
              data-component="verification-input-2"
              name="segment-input-2"
              maxlength="1"
              placeholder="0"
              formControlName="third"
              [ngClass]="{ invalid: submitted && f.third.errors }"
              class="verify-otp-fields col-lg-1"
              autocomplete="off"
              value=""
              #third
            />

            <!-- </div>
                    </div> -->
            <!-- <div class="styles__Root-sc-1myek4t-0 fRpMao styles__Root-ju42zr-0 feQLsv" >—</div> -->
            <!-- <div class="Segment__StyledInputSegment-ic7hna-0 mPYdo Input__StyledSegments-ybap36-1 fLjbOO"> -->
            <input
              type="text"
              data-component="verification-input-3"
              name="segment-input-3"
              maxlength="1"
              placeholder="0"
              formControlName="fourth"
              [ngClass]="{ invalid: submitted && f.fourth.errors }"
              class="verify-otp-fields col-lg-1"
              autocomplete="off"
              value=""
              #fourth
            />

            <input
              type="text"
              data-component="verification-input-4"
              name="segment-input-4"
              maxlength="1"
              placeholder="0"
              formControlName="fifth"
              [ngClass]="{ invalid: submitted && f.fifth.errors }"
              class="verify-otp-fields col-lg-1"
              autocomplete="off"
              value=""
              #fifth
            />

            <input
              type="text"
              data-component="verification-input-5"
              name="segment-input-5"
              maxlength="1"
              placeholder="0"
              formControlName="sixsth"
              [ngClass]="{ invalid: submitted && f.sixsth.errors }"
              class="verify-otp-fields col-lg-1"
              autocomplete="off"
              value=""
              #sixsth
            />

            <!-- </div> -->
          </div>
          <br />
          <strong class="timer" *ngIf="timerRunning"
            >Time left: {{ timeLeft }} seconds</strong
          >

          <div *ngIf="submitted && f.first.errors" class="text-danger">
            <div
              *ngIf="f.first.errors.required"
              style="text-align: center; margin-bottom: 10px"
            >
              6 digit OTP is required
            </div>
          </div>

          <div class="red text-danger" *ngIf="isError">
            <div style="text-align: center; margin-bottom: 10px">
              Invalid OTP.
            </div>
          </div>
          <br />
          <div class="primary-back-continue">
            <div class="">
              <button
                data-component="primary-button"
                type="submit"
                role="button"
                tabindex="0"
                (click)="onPrevious()"
                class="fts-button-tertiary"
              >
                Previous
              </button>
            </div>
            <div class="">
              <button
                data-component="primary-button"
                type="submit"
                role="button"
                tabindex="0"
                class="fts-button-primary"
                (click)="verifyOtp(mymodal)"
              >
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
      <!--FORM CONTENT -->
    </div>
  </div>
  <!-- RIGHT PANEL-->

  <ng-template #mymodal let-modal>
    <div class="bgBody">
      <img
        class="image"
        src="http://54.216.113.184:8080/dao_optbanner.png"
        alt="success"
        style="width: 100px; height: 100px"
      />
      <p>Thanks for verifying your email address!</p>
      <p>{{ modalText }}</p>
      <div class="centered">
        <button
          value="success"
          class="button button1"
          (click)="modal.close('Save click')"
        >
          Continue
        </button>
      </div>
    </div>
  </ng-template>
</card-container>

<div class="modal addModal" id="confirmPinModal">
  <div class="modal-body">
    <div class="modal-dialog" role="document">
      <form name="form" class="modal-content">
        <div class="modal-body">
          <ng-content></ng-content>
        </div>
      </form>
    </div>
  </div>
</div>

import { Component, Input } from "@angular/core";

@Component({
  selector: 'card-container',
  templateUrl: './card-container.component.html',
  styleUrls: ['./card-container.component.css']
})
export class CardContainerComponent {

  @Input()
  public heading: string = '';


  constructor() {
  }
}

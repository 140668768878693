import {Action, createReducer, on} from '@ngrx/store';
import * as BusinessAddressAction from '../action/personalAddress.actions';


export const personalAddressFeatureKey = 'personalAddress';

export interface PersonalAddressState {
  personalAddress: {};
}

export const initialState: PersonalAddressState = {
  personalAddress: {}
};

export const personalAddressReducer = createReducer(
  initialState,
  on(BusinessAddressAction.addPersonalAddr,
    (state: PersonalAddressState, {personalAddress}) =>
      ({...state,
        personalAddress,
      }))
);

export function BusinessAddessReducer(state: PersonalAddressState | undefined, action: Action): any {
  return personalAddressReducer(state, action);
}

import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BusinessDetailService } from '../../business-details/service/business-detail.service';
import { Data } from '../../providers/data';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PersonalDetailService } from '../service/personal-detail.service';

@Injectable()
export class PersonalAddressResolver implements Resolve<any> {
  constructor(private businessDetailService: BusinessDetailService, private data: Data,
    private personalService: PersonalDetailService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let requests = [this.businessDetailService.fetchCountries(), 
          this.businessDetailService.getBusinesType(),
          this.personalService.getTransactionAll(),
          this.personalService.getEmployeeNum(),
          this.personalService.getbusinessActivityAll(),
        this.personalService.getShareOfSourceCapital(),
      this.personalService.getMonthlyTurnoverAll(),];
        return forkJoin(requests).pipe(map((res) => {
          let response = {
            countries      : res[0],
            documentsType  : res[1],
            transactionAll : res[2],
            employeenum    : res[3],
            businessActivityAll:res[4],
            shareOfSourceCapital:res[5],
            monthlyTurnoverAll:res[6]
            
          }
          return response;
       
         

        }));

   
  }
}

<ngx-ui-loader></ngx-ui-loader>
<div class="container-fluid">
    <div class="row content">
        <div class="col-sm-4 sidenav">
            <h3>
                <img src="../../assets/logo.png">
                <div class="heading">
                    Create Your Account<br>
                    Business Account
                </div>
            </h3>
            <ul class="steps">
                <li class="pointer-cursor font-weight-bold-500" id="countryStep"></li>
                <ng-container *ngIf="currentForm != null">
                    <!-- {{step.stepName}} -->
                    <li class="pointer-cursor" *ngFor="let step of currentForm.steps" id="{{step.stepId}}"></li>
                    <!-- Create Your Account -->
                    <li class="pointer-cursor" *ngIf="currentForm.availableInCountry" id="createAccountStep"></li>
                    <!-- <li class="pointer-cursor" *ngIf="!currentForm.availableInCountry" id="notAvailableStep">Add To Wishlist</li> -->
                </ng-container>
                <img src="../../assets/abc.png">
            </ul>
        </div>
        <div class="col-sm-8 mainnav">
            <div class="row">
                <div class="col-8 shad">
                    <div class="info-box" id="countryWindow">
                        <div class="row">
                            <div class="heading">Open Your Account<br>In Just Few Clicks</div>
                            <div class="box2">
                                <div class="abc">
                                    <img class="im" src="../../assets/check_icon.png" />
                                    <div class="caption">Confirm your email address</div>
                                </div>

                                <div class="abc">
                                    <img class="im" src="../../assets/downloadapp_icon.png" />
                                    <div class="caption">Download App</div>
                                </div>

                                <div class="abc">
                                    <img class="im" src="../../assets/verifyidentity_icon.png" />
                                    <div class="caption">Verify yourIdentity</div>
                                </div>

                                <div class="abc">
                                    <img class="im" src="../../assets/debitcard_icon.png" />
                                    <div class="caption">Get your Qbanc Debit Card</div>
                                </div>

                            </div>
                        </div>

                        <form [formGroup]="formGroups['countryForm']" (ngSubmit)="changeCountry()">
                            <div class="row">
                                <div class="col-10">
                                    <h3>
                                        Select Account Type
                                    </h3>
                                    <p>
                                        Select your Account
                                    </p>
                                </div>
                                <div class="form-group col-11">
                                    <label for="country">Type</label>
                                    <select formControlName="country" class="form-control" [(ngModel)]="selectedType"
                                        [ngClass]="{ 'is-invalid': formSubmitted['countryForm'] && formGroups['countryForm'].controls['country'].errors}">
                                        <option disabled selected>Select Type</option>
                                        <option *ngFor="let form of forms" value="{{form.country}}">{{form.country}}
                                        </option>
                                    </select>
                                    <small class="text-danger"
                                        *ngIf="formSubmitted['countryForm'] && formGroups['countryForm'].controls['country'].errors?.required">Type
                                        Is Required</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <!-- <button type="button" class="btn btn-primary" (click)="next('countryWindow','countryStep','personalInfoWindow', 'personalInfoStep')">Continue</button> -->
                                    <button type="submit" class="btn btn-primary">Continue</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <ng-container *ngIf="hasNewSteps">
                        <div class="p-3 mb-2" *ngIf="isSuccess">
                            <div id="successMessage">
                                <div class="bg-success ">
                                    <p>SUCCESS! OTP sent successfully.</p>
                                </div>
                            </div>
                        </div>
                        <div class="p-3 mb-2" *ngIf="isError">
                            <div id="errorMessage">
                                <div class="bg-danger">
                                    <p>Something Went Wrong.</p>
                                </div>
                            </div>
                        </div>
                        <div class="info-box d-none" *ngFor="let step of currentForm.steps" id="{{step.stepWindowId}}">
                            <form [formGroup]="formGroups[step.stepId]"
                                (ngSubmit)="continue(step.uniqueId,step.nextStepId)">
                                <div class="row">
                                    <div class="col-11">
                                        <h3>
                                            {{step.stepName}}
                                        </h3>
                                        <p *ngFor="let description of step.stepDescription">
                                            {{description}}
                                        </p>
                                    </div>
                                    <div *ngIf="step.stepId === 'selfieStep'">
                                        <webcam [height]="500" [width]="500" [trigger]="triggerObservable"
                                            (imageCapture)="handleImage($event)" *ngIf="showWebcam"
                                            [allowCameraSwitch]="allowCameraSwitch" [imageQuality]="1"
                                            (cameraSwitched)="cameraWasSwitched($event)"
                                            (initError)="handleInitError($event)"></webcam>
                                        <button class="actionBtn" (click)="triggerSnapshot();">Take A Snapshot</button>

                                        <div class="parent" *ngIf="webcamImage">
                                            <img [src]="webcamImage.imageAsDataUrl" />
                                        </div>

                                    </div>
                                    <div *ngFor="let field of step.fields" class="{{field.columnSize}}">
                                        <div class="form-group">
                                            <label for="{{field.fieldName}}">{{field.label}}</label>
                                            <ng-container *ngIf="field.type == 'radio'; else select">
                                                <br>
                                                <div class="form-check form-check-inline"
                                                    *ngFor="let option of field.options">
                                                    <input class="form-check-input"
                                                        formControlName="{{field.fieldName}}" type="radio"
                                                        name="{{field.fieldName}}" id="{{option.value}}"
                                                        value="{{option.value}}"
                                                        [ngClass]="{ 'is-invalid': formSubmitted[step.stepId] && formGroups[step.stepId].controls[field.fieldName].errors}">
                                                    <label class="form-check-label" for="{{option.value}}">
                                                        {{option.label}}
                                                    </label>
                                                </div>
                                            </ng-container>
                                            <ng-template #select>
                                                <ng-container *ngIf="field.type == 'select'; else date">
                                                    <br>
                                                    <select class="form-control" id="{{field.fieldName}}" #mySelect
                                                        formControlName="{{field.fieldName}}"
                                                        (change)='onOptionsSelected(mySelect.value)'
                                                        [ngClass]="{ 'is-invalid': formSubmitted[step.stepId] && formGroups[step.stepId].controls[field.fieldName].errors}">
                                                        <option disabled selected></option>
                                                        <option value="{{option.value}}"
                                                            *ngFor="let option of field.options">{{option.label}}
                                                        </option>
                                                    </select>
                                                </ng-container>
                                            </ng-template>
                                            <ng-template #date>
                                                <ng-container *ngIf="field.type == 'date'; else button">
                                                    <input type="{{field.type}}" min="{{field.minValue}}"
                                                        class="form-control" id="{{field.fieldName}}"
                                                        formControlName="{{field.fieldName}}"
                                                        placeholder="{{field.placeholder}}"
                                                        [ngClass]="{ 'is-invalid': formSubmitted[step.stepId] && formGroups[step.stepId].controls[field.fieldName].errors}" />
                                                </ng-container>
                                            </ng-template>
                                            <ng-template #button>
                                                <ng-container *ngIf="field.type == 'button'; else other">
                                                    <button type="{{field.type}}" class="btn btn-primary"
                                                        id="{{field.fieldName}}" (click)="addnewUser()"
                                                        [ngClass]="{ 'is-invalid': formSubmitted[step.stepId] && formGroups[step.stepId].controls[field.fieldName].errors}">
                                                        {{field.value}}
                                                    </button>
                                                </ng-container>
                                            </ng-template>
                                            <ng-template #other>
                                                <input type="{{field.type}}" class="form-control"
                                                    id="{{field.fieldName}}" formControlName="{{field.fieldName}}"
                                                    placeholder="{{field.placeholder}}"
                                                    [ngClass]="{ 'is-invalid': formSubmitted[step.stepId] && formGroups[step.stepId].controls[field.fieldName].errors}" />
                                                <label>
                                                </label>
                                            </ng-template>

                                            <div
                                                *ngIf="formSubmitted[step.stepId] && formGroups[step.stepId].controls[field.fieldName].errors?.required">
                                                <small class="text-danger">{{field.label}} Is Required</small></div>
                                            <div
                                                *ngIf="formSubmitted[step.stepId] && formGroups[step.stepId].controls[field.fieldName].errors?.email">
                                                <small class="text-danger">{{field.label}} Must Be Email</small></div>
                                            <div
                                                *ngIf="formSubmitted[step.stepId] && formGroups[step.stepId].controls[field.fieldName].errors?.pattern">
                                                <small class="text-danger">{{field.label}} Must Be In Proper Pattern For
                                                    e.g {{field.example}}</small></div>
                                            <div
                                                *ngIf="formSubmitted[step.stepId] && formGroups[step.stepId].controls[field.fieldName].errors?.minlength">
                                                <small class="text-danger">Minimum Length of {{field.label}} Must Be
                                                    {{formGroups[step.stepId].controls[field.fieldName].errors?.minlength.requiredLength}}
                                                    Characters</small></div>
                                            <div
                                                *ngIf="formSubmitted[step.stepId] && formGroups[step.stepId].controls[field.fieldName].errors?.maxlength">
                                                <small class="text-danger">Maximum Length of {{field.label}} Must Be
                                                    {{formGroups[step.stepId].controls[field.fieldName].errors?.maxlength.requiredLength}}
                                                    Characters</small></div>

                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <button type="button" class="btn btn-link"
                                                (click)="back(step.uniqueId,step.previousStepId)">Back</button>
                                            <button type="submit" class="btn btn-primary">Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ng-container>
                    <div class="info-box d-none" id="createAccountWindow">
                        <div class="row">
                            <div class="p-3 mb-2" *ngIf="isApiSuccess">
                                <div id="successMessage">
                                    <div class="bg-success ">
                                        <p>SUCCESS! User Registered successfully.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="p-3 mb-2" *ngIf="isApiError">
                                <div id="errorMessage">
                                    <div class="bg-danger">
                                        <p>Something Went Wrong.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <h3>Create Account</h3>
                                <p>
                                    You must check following conditions
                                </p>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" [(ngModel)]="termsAndCondition"
                                            id="termsAncCondition">
                                        <label class="form-check-label" for="termsAncCondition">
                                            We are a FinTech enablement company, providing financial platforms ranging
                                            from Digital Banking, Mobile Money, Mobile Wallet and various Financial
                                            Solutions
                                        </label>
                                        <br>
                                        <small class="text-danger d-none" id="termsAndConditionError">Please Accept
                                            Terms And Conditions</small>
                                    </div>
                                    <br>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" [(ngModel)]="privacyPolicy"
                                            id="privacyPolicy">
                                        <label class="form-check-label" for="privacyPolicy">
                                            We are a FinTech enablement company, providing financial platforms ranging
                                            from Digital Banking, Mobile Money, Mobile Wallet and various Financial
                                            Solutions
                                        </label>
                                        <br>
                                        <br>
                                        <label>
                                            <div #passbaseButton></div>
                                        </label>
                                        <small class="text-danger d-none" id="privacyPolicyError">Please Accept Privacy
                                            Policy</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button type="button" class="btn btn-link"
                                    (click)="back('createAccount',null)">Back</button>
                                <button type="button" class="btn btn-primary" (click)="createAccount()">Create
                                    Account</button>
                                    <div class="p-3 mb-2" *ngIf="isApiSuccess">
                                        <button href="#/" id="successButton" class="btn btn-primary"
                                        type="submit" >Done!
                                        </button>
                                    </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
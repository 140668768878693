import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenricateService } from '../email-check/service/authenricate.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { EmailCheckComponent } from './component/email-check.component';
import { RouterModule } from '@angular/router';
import { UIModule } from '../util/ui/ui-module';


@NgModule({
  declarations: [
    EmailCheckComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    UIModule
  ],
  providers:[AuthenricateService],
  exports: [
    EmailCheckComponent
  ]
})
export class EmailCheckModule {}

import {Action, createReducer, on} from '@ngrx/store';
import * as BusinessActions from '../action/business.actions';
import * as BusinessAddressAction from '../action/businessAddress.actions';

export const businessFeatureKey = 'business';

export interface BusinessState {
  business: {}
}

export const initialState: BusinessState = {
  business: {}
};

export const businessReducer = createReducer(
  initialState,
  on(BusinessActions.addBusiness,
    (state: BusinessState, {business}) =>
      ({...state,
        business,
      }))
);



export function Businessreducer(state: BusinessState | undefined, action: Action): any {
  return businessReducer(state, action);
}
<app-card-large>
  <div class="dyEAXN">
    <div class="bCDrPV djdIYm">
      <div class="mjYRwVK">
        <div class="main-panel__content">
          <div class="main-panel__content">
            <h1 class="main-heading nonreferral">Business Partners</h1>
            <h2 class="main-sub-heading">
              Please provide your business partners
            </h2>
          </div>
        </div>

        <form name="f" [formGroup]="personalDocumentsForm" class="form-content">
          <div>
            <h1
              data-component="heading"
              class="small-heading"
              style="margin-top: -40px"
            >
              Business partners
            </h1>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4" style="margin-top: 5px">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >would you like to use an existing person for this role<span
                  tyle="color: red;"
                  >?</span
                ></label
              >
              <select
                #customerSelect
                name="team_size"
                (change)="fetchCustomerData(customerSelect.value)"
                id="team_size"
                class="fts-select-small"
                formControlName="customer"
                placeholder="person"
              >
                <option value="" disabled selected>Select User</option>
                <option
                  *ngFor="let customer of customers"
                  [value]="customer.id"
                >
                  {{ customer.firstName }} {{ customer.lastName }}
                </option>
              </select>
              <div *ngIf="submitted && f.customer.errors" class="text-danger">
                <div *ngIf="f.customer.errors.required">
                  would you like to use an existing person for this role is
                  required
                </div>
              </div>
            </div>
            <div class="input-field col s4" style="margin-top: 30px">
              <button
                class="fts-button-tertiary-small"
                (click)="partnersForm()"
              >
                Clear
              </button>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s4" style="margin-top: 5px">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >First name<span style="color: red">*</span></label
              >
              <input
                id="text"
                placeholder="first name"
                formControlName="firstname"
                type="text"
                class="fts-input-field input-width"
              />
              <div *ngIf="submitted && f.firstname.errors" class="text-danger">
                <div *ngIf="f.firstname.errors.required">
                  first name is required
                </div>
              </div>
            </div>
            <div class="input-field col s4" style="margin-top: 5px">
              <label for="team_size" class="form__label"
                >Last name<span style="color: red">*</span></label
              >
              <input
                id="name"
                placeholder="last name"
                formControlName="lastname"
                type="text"
                class="fts-input-field input-width"
              />
              <div *ngIf="submitted && f.lastname.errors" class="text-danger">
                <div *ngIf="f.lastname.errors.required">
                  last name is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4" style="margin-top: 5px">
              <label for="team_size" class="form__label"
                >Nationality<span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                formControlName="nationality"
                class="fts-select-small"
              >
                <option disabled selected>Nationality</option>
                <option
                  *ngFor="let countrty of countries"
                  [value]="countrty.id"
                >
                  {{ countrty.name }}
                </option>
              </select>

              <div
                *ngIf="submitted && f.nationality.errors"
                class="text-danger"
              >
                <div *ngIf="f.nationality.errors.required">
                  Nationality is required
                </div>
              </div>
            </div>
            <div class="input-field col s4" style="margin-top: 5px">
              <label for="team_size" class="form__label"
                >Date of birth<span style="color: red">*</span></label
              >
              <input
                id="name"
                placeholder="Date of birth"
                type="date"
                formControlName="birth"
                class="fts-input-field input-width"
              />
              <div *ngIf="submitted && f.birth.errors" class="text-danger">
                <div *ngIf="f.birth.errors.required">
                  Date of birth is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4" style="margin-top: 5px">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Place of birth<span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                class="fts-select"
                formControlName="place"
                placeholder="Date of birth"
              >
                <option disabled selected>place of birth</option>
                <option
                  *ngFor="let countrty of countries"
                  [value]="countrty.id"
                >
                  {{ countrty.name }}
                </option>
              </select>

              <div *ngIf="submitted && f.place.errors" class="text-danger">
                <div *ngIf="f.place.errors.required">
                  place of birth is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4" style="margin-top: 5px">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Id document type<span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                class="fts-select-small"
                formControlName="doctype"
                placeholder="XXXXXXXXXXXXXXX"
              >
                <option disabled selected>document type</option>
                <option *ngFor="let type of documentsType" [value]="type.id">
                  {{ type.label }}
                </option>
              </select>
              <div *ngIf="submitted && f.doctype.errors" class="text-danger">
                <div *ngIf="f.doctype.errors.required">
                  Id document type is required
                </div>
              </div>
            </div>
            <div class="input-field col s4" style="margin-top: 5px">
              <label for="team_size" class="form__label"
                >Id document number<span style="color: red">*</span></label
              >
              <input
                id="name"
                placeholder="document number"
                formControlName="docnum"
                type="text"
                class="fts-input-field input-width"
              />
              <div *ngIf="submitted && f.docnum.errors" class="text-danger">
                <div *ngIf="f.docnum.errors.required">
                  Id document number is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4" style="margin-top: 5px">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Id document issuing country<span style="color: red"
                  >*</span
                ></label
              >
              <select
                name="team_size"
                id="team_size"
                class="fts-select-small"
                formControlName="docissuing"
                placeholder="XXXXXXXXXXXXXXX"
              >
                <option disabled selected>id document issuing</option>
                <option
                  *ngFor="let countrty of countries"
                  [value]="countrty.id"
                >
                  {{ countrty.name }}
                </option>
              </select>

              <div *ngIf="submitted && f.docissuing.errors" class="text-danger">
                <div *ngIf="f.docissuing.errors.required">
                  Id document issuing country is required
                </div>
              </div>
            </div>
            <div class="input-field col s4" style="margin-top: 5px">
              <label for="team_size" class="form__label"
                >Id document expiry date<span style="color: red">*</span></label
              >
              <input
                id="name"
                placeholder="document expiry"
                type="date"
                formControlName="expdate"
                class="fts-input-field input-width"
              />
              <div *ngIf="submitted && f.expdate.errors" class="text-danger">
                <div *ngIf="f.expdate.errors.required">
                  Id document expiry date is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4" style="margin-top: 5px">
              <label for="team_size" class="form__label"
                >Mobile number <span style="color: red">*</span></label
              >
              <input
                id="name"
                formControlName="mob"
                placeholder="XXXXXXXXXXXXXXX"
                type="text"
                class="fts-input-field register-w"
              />
              <div *ngIf="submitted && f.mob.errors" class="text-danger">
                <div *ngIf="f.mob.errors.required">
                  mobile number is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4" style="margin-top: 5px">
              <label for="team_size" class="form__label"
                >Email <span style="color: red">*</span></label
              >
              <input
                id="userEmail"
                name="email"
                type="text"
                formControlName="email"
                class="fts-input-field register-w"
              />
            </div>
            <div *ngIf="submitted && f.email.errors" class="text-danger">
              <div *ngIf="f.email.errors.required">Email is required</div>
            </div>
          </div>
          <h5 class="main-sub-heading" style="margin-top: 10px">
            Parmanent address <span style="color: red">*</span>
          </h5>
          <div class="row">
            <div class="input-field col s4" style="margin-top: 5px">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Country <span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                class="fts-select-small"
                formControlName="country"
                placeholder="XXXXXXXXXXXXXXX"
              >
                <option disabled selected>Country</option>
                <option
                  *ngFor="let countrty of countries"
                  [value]="countrty.id"
                >
                  {{ countrty.name }}
                </option>
              </select>

              <div *ngIf="submitted && f.country.errors" class="text-danger">
                <div *ngIf="f.country.errors.required">country is required</div>
              </div>
            </div>
            <div class="input-field col s4" style="margin-top: 5px">
              <label for="team_size" class="form__label">
                City<span style="color: red">*</span></label
              >
              <select id="city" formControlName="city" class="fts-select-small">
                <!-- <option *ngFor="let city of cities"[value]="city.id">{{city.name}}</option> 
                                     -->
                <option *ngFor="let city of cities" [value]="city.id">
                  {{ city.name }}
                </option>
              </select>
              <div *ngIf="submitted && f.city.errors" class="text-danger">
                <div *ngIf="f.city.errors.required">city is required</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s4" style="margin-top: 5px">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Street <span style="color: red">*</span></label
              >
              <input
                id="name"
                placeholder="Street"
                formControlName="street"
                type="text"
                class="fts-input-field input-width"
              />
              <div *ngIf="submitted && f.street.errors" class="text-danger">
                <div *ngIf="f.street.errors.required">street is required</div>
              </div>
              <!-- <div  class="text-danger">
                                  <div >Country is required</div>
                                  </div> -->
            </div>
            <div class="input-field col s4" style="margin-top: 5px">
              <label for="team_size" class="form__label">
                Zip code<span style="color: red">*</span></label
              >
              <input
                id="name"
                formControlName="zip"
                placeholder="XXX"
                type="number"
                class="fts-input-field input-width"
              />
              <div *ngIf="submitted && f.zip.errors" class="text-danger">
                <div *ngIf="f.zip.errors.required">zip code is required</div>
              </div>
            </div>
            <!-- <button class="btn-remove" style="display: flex; gap: 15px;">
                               <img style="height: 20px; width: 20px;" src="../../../assets/addicon.png" alt="" srcset="">
                               <img style="height: 15px; width: 15px;" src="../../../assets/crossicon.png" alt="" srcset="">
                               </button> -->
          </div>
          <h5 class="main-sub-heading" style="margin-top: 10px">
            Tax information<span style="color: red">*</span>
          </h5>
          <div class="row">
            <div class="input-field col s4" style="margin-top: 5px">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Country <span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                class="fts-select-small"
                formControlName="city1"
                placeholder="XXXXXXXXXXXXXXX"
              >
                <option disabled selected>Country</option>
                <option
                  *ngFor="let countrty of countries"
                  [value]="countrty.id"
                >
                  {{ countrty.name }}
                </option>
              </select>

              <div *ngIf="submitted && f.city1.errors" class="text-danger">
                <div *ngIf="f.city1.errors.required">country is required</div>
              </div>
              <!-- <div  class="text-danger">
                                  <div >Country is required</div>
                                  </div> -->
            </div>
            <div class="input-field col s4" style="margin-top: 5px">
              <label for="team_size" class="form__label"
                >Tax identification number (tin)
                <span style="color: red">*</span></label
              >
              <input
                id="name"
                formControlName="tax1"
                placeholder="Tax"
                type="text"
                class="fts-input-field input-width"
              />
              <div *ngIf="submitted && f.tax1.errors" class="text-danger">
                <div *ngIf="f.tax1.errors.required">
                  Tax Identification Number (tin) is required
                </div>
              </div>
            </div>
          </div>
          <h5 class="form__label" style="margin-top: 10px">
            Do you have a multiple citizenship<span style="color: red">*</span>
          </h5>
          <div style="display: flex; gap: 10px">
            <input
              type="radio"
              formControlName="citizenship"
              id="html"
              name="citizenship"
              value="yes"
            />
            <label for="html" style="margin-top: 10px">Yes</label><br />
            <input
              type="radio"
              id="css"
              formControlName="citizenship"
              name="citizenship"
              value="no"
            />
            <label for="css" style="margin-top: 10px">No</label><br />
          </div>
          <div class="row">
            <div class="input-field col s4" style="margin-top: 5px">
              <label for="team_size" class="form__label"
                >Ownership <span style="color: red">*</span></label
              >
              <input
                class="fts-input-field register-w"
                type="text"
                formControlName="position"
                placeholder="Co"
              />
              <p class="text-muted">Ownership of a person should be (Co)</p>
            </div>
          </div>
          <h6 class="form__label" style="margin-top: 10px">
            Is the person.his/her immedate family members or close associates
            politically exposed persons (or have been so during the past 12
            months)<span style="color: red">?</span>
          </h6>
          <div style="display: flex; gap: 10px">
            <input
              type="radio"
              id="html"
              formControlName="persons"
              name="persons"
              value="yes"
            />
            <label for="html" style="margin-top: 10px">Yes</label><br />
            <input
              type="radio"
              id="css"
              formControlName="persons"
              name="persons"
              value="no"
            />
            <label for="css" style="margin-top: 10px">No</label><br />
          </div>
          <h6 class="form__label" style="margin-top: 10px">
            Does the beneficial owner act as a nominee<span style="color: red"
              >?</span
            >
          </h6>
          <div style="display: flex; gap: 10px">
            <input
              type="radio"
              id="html"
              formControlName="nominee"
              name="nominee"
              value="yes"
            />
            <label for="html" style="margin-top: 10px">Yes</label><br />
            <input
              type="radio"
              id="css"
              formControlName="nominee"
              name="nominee"
              value="no"
            />
            <label for="css" style="margin-top: 10px">No</label><br />
          </div>
          <h6 class="form__label" style="margin-top: 10px">
            Does the beneficial owner act as a trustee<span style="color: red"
              >?</span
            >
          </h6>
          <div style="display: flex; gap: 10px">
            <input
              type="radio"
              id="html"
              formControlName="trustee"
              name="trustee"
              value="yes"
            />
            <label for="html" style="margin-top: 10px">Yes</label><br />
            <input
              type="radio"
              id="css"
              formControlName="trustee"
              name="trustee"
              value="no"
            />
            <label for="css" style="margin-top: 10px">No</label><br />
          </div>
          <h6 class="form__label" style="margin-top: 10px">
            Is the beneficial owner a USA person
            <span style="color: red">?</span>
          </h6>
          <div style="display: flex; gap: 10px">
            <input
              type="radio"
              id="html"
              formControlName="usaPerson"
              name="usaPerson"
              value="yes"
            />
            <label for="html" style="margin-top: 10px">Yes</label><br />
            <input
              type="radio"
              id="css"
              formControlName="usaPerson"
              name="usaPerson"
              value="no"
            />
            <label for="css" style="margin-top: 10px">No</label><br />
          </div>
          <div class="primary-back-continue">
            <div class="row">
              <div class="input-field col s4">
                <button
                  data-component="primary-button"
                  type="submit"
                  role="button"
                  tabindex="0"
                  class="fts-button-tertiary"
                  (click)="onPrevious()"
                >
                  Previous
                </button>
              </div>
              <div class="input-field col s4">
                <button
                  data-component="primary-button"
                  type="submit"
                  role="button"
                  tabindex="0"
                  class="fts-button-secondary-small"
                  (click)="saveBusinessRoles()"
                >
                  Continue
                </button>
              </div>
              <div class="input-field col s4">
                <button
                  data-component="primary-button"
                  type="submit"
                  role="button"
                  tabindex="0"
                  (click)="addDocData()"
                  class="fts-button-primary"
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          <div class="display-table">
            <table>
              <thead>
                <tr>
                  <th style="position: relative; left: 35%">Name</th>
                  <th style="position: relative; left: 35rem">Action</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  class="t-hover"
                  *ngFor="let data of businessDataArray; let i = index"
                >
                  <td style="position: relative; left: 35%">
                    {{ data.firstname }}
                  </td>
                  <td style="position: relative; left: 35rem">
                    <div>
                      <img
                        (click)="setFormValues(data, i)"
                        src="../../../../assets/document-editor.png"
                        style="width: 22px; cursor: pointer"
                      />
                      <img
                        (click)="deleteRecord(i)"
                        src="../../../../assets/delete.png"
                        style="width: 18px; cursor: pointer"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>
  </div>
</app-card-large>

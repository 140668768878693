import {Action, createReducer, on} from '@ngrx/store';
import * as BusinessDocumentAction from '../action/businessDoc.actions';


export const businessDocumentFeatureKey = 'businessDocument';

export interface BusinessDocumentState {
  businessDoc: {};
}

export const initialState: BusinessDocumentState = {
  businessDoc: {}
};

export const businessDocumentReducer = createReducer(
  initialState,
  on(BusinessDocumentAction.addBusDocument,
    (state: BusinessDocumentState, {businessDoc}) =>
      ({...state,
        businessDoc,
      }))
);

export function BusinessDocumentReducer(state: BusinessDocumentState | undefined, action: Action): any {
  return businessDocumentReducer(state, action);
}

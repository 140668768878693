<div class="dyEAXN">
    <!-- RIGHT PANEL-->
    <div class="bCDrPV djdIYm">
        <div class="jYRwVK">
            <!-- FORM CONTENT-->
            <div class="hnZgAZ">
                <div class="husZCp">
                    <h1 data-component="heading"
                        class="styles__Root-sc-1myek4t-0 fdExrC Heading__Head-junqdu-1 lgVgrq">Verify your
                        Identity</h1>
                    <h2 data-component="subheading"
                        class="styles__Root-sc-1myek4t-0 cclCHJ Heading__SubHead-junqdu-2 goFGMk">Please
                        provide front scanned images of your Passport</h2>
                </div>
            </div>

            <form name="f" [formGroup]="passportForm">
                <div>
                    <label data-component="form-input-email-label" for="email"
                        class="styles__Root-sc-1myek4t-0 ezjDKc styles__Root-sc-138xw60-0 ubhdy">Passport
                        (FRONT SIDE)</label>
                    <div class="NecEC">
                        <div class="NAEPS">
                            <div class="jvubth">
                                <input id="email" name="email" type="file" placeholder="" placeholder=""
                                    #perImageBack (change)="processFile(perImageBack)"
                                    formControlName="front"
                                    [ngClass]="{'invalid': submitted &&  f.front.errors }"
                                    class="styles__Root-sc-1dahuaq-1 eWmkGc" >
                            </div>
                            <div *ngIf="submitted && f.front.errors" class="text-danger">
                                <div *ngIf="f.front.errors.required">Document is required</div>
                            </div>
                            <div>Note: Only JPEGs, PNGs or PDFs with a file size of max. 2MB are allowed</div>
                        </div>
                    </div>
                </div>

                <div class="eBKYjJ cgMkag">
                    <button data-component="primary-button" type="submit" role="button" tabindex="0"
                        class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 epjTBn"
                        (click)="addUserDocument()">Continue</button>
                </div>

            </form>

        </div>
        <!--FORM CONTENT -->


    </div>

</div> <!-- RIGHT PANEL-->
export class Account {
  constructor(
    public id: number,
    public fullName: string,
    public mobileNo: string,
    public email: string,
    public type: string,
    public status: number,
    public password: number
  ) { }
}
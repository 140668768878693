import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignUpService } from '../sign-up/service/sign-up.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { RouterModule } from '@angular/router';
import { VerifyOTPComponent } from './component/verify-otp.component';
import {MoveNextByMaxLengthDirective } from './component/movenext-directive';
import { UIModule } from '../util/ui/ui-module';

@NgModule({
  declarations: [
    VerifyOTPComponent,
    MoveNextByMaxLengthDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    UIModule
  ],
  exports: [
    VerifyOTPComponent
  ]
})
export class VerifyOTPModule {}

import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromCustomer from '../reducer/businessDocument.reducer';

export const selectBusinessDocumentState = createFeatureSelector<fromCustomer.BusinessDocumentState>(
  fromCustomer.businessDocumentFeatureKey,
);


export const selectBusinessDocument = createSelector(
  selectBusinessDocumentState,
  (state: fromCustomer.BusinessDocumentState) => state.businessDoc
);

import {Action, createReducer, on} from '@ngrx/store';
import * as BusinessOwnerAction from '../action/businessOwner.actions';


export const businessOwnerFeatureKey = 'businessOwner';

export interface BusinessOwnerState {
  businessOwner: {};
}

export const initialState: BusinessOwnerState = {
  businessOwner: {}
};

export const businessOwnerReducer = createReducer(
  initialState,
  on(BusinessOwnerAction.addBusOwners,
    (state: BusinessOwnerState, {businessOwner}) =>
      ({...state,
        businessOwner,
      }))
);

export function BusinessOwnerReducer(state: BusinessOwnerState | undefined, action: Action): any {
  return businessOwnerReducer(state, action);
}

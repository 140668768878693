import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StoreModule} from '@ngrx/store';
import { SignUpService } from '../sign-up/service/sign-up.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { RouterModule } from '@angular/router';
import {customerFeatureKey, reducer} from '../../app/account-details/store/reducer/customer.reducer';
import { AccountDetailsComponent } from './component/account-details.component';
import { NgxMaskModule } from 'ngx-mask';
import { UIModule } from '../util/ui/ui-module';


@NgModule({
  declarations: [
    AccountDetailsComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(customerFeatureKey, reducer),
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    UIModule,
    NgxMaskModule.forRoot()
  ],
  providers:[SignUpService],
  exports: [
    AccountDetailsComponent
  ]
})
export class AccountDetailsModule {}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'login-footer',
  templateUrl: './login-footer.component.html',
  styleUrls: ['./login-footer.component.css']
})

export class LoginFooterComponent {
  constructor() {
  }
}

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Business } from '../model/business';
import { selectCustomers } from '../../account-details/store/selector/customer.selectors';
import { Document } from '../model/document'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { SignUpService } from '../../sign-up/service/sign-up.service';
import { selectBusinessDocument } from '../store/selector/businessDocument.selectors';
import { selectBusinessOwner } from '../store/selector/businessOwner.selectors copy';
import { selectBusiness } from '../../business-details/store/selector/business.selectors';
import { selectBusinessAddress } from '../../business-details/store/selector/businessAddress.selectors';
import { addBusDocument } from '../store/action/businessDoc.actions';
import { BusinessDetailService } from '../service/business-detail.service'
import { environment } from '../../../../src/environments/environment';
import { Address } from '../model/address';
import { addBusiness } from '../store/action/business.actions';
import { Account } from 'src/app/account-details/model/account';
import { SharedService } from 'src/app/shared/shared.service';


declare var $: any;

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'business-documents',
  templateUrl: './business-documents.component.html',
  styleUrls: ['./business-documents.component.scss']
})


export class BusinessDocumentsComponent implements OnInit {
  public isSuccess: boolean = false;
  public isError: boolean = false;
  public submitted = false;
  public submitted2 = false;
  public previousResponse: any;
  public businessStakeholder: any;
  public business: Business;
  public accDetail: Account;
  public prevBusiness: Business
  public prevBusinessAddr: Address;
  public document: string;
  public businessDocument = [];
  public bDocument1: any;
  public bDocument2: any;
  public imageUrl: string;
  public industries: any;
  selectedFile: ImageSnippet;
  public closeResult: string;
  public prevCustomer: Account;
  public accountItemsForm: FormGroup;
  public previousData: any;
  public accountID: any;

  // documentsForm = this.formBuilder.group({
  //   businessRegistrationDocument: ['', Validators.required],
  //   ntnDocument: ['', Validators.required]
  // });

  documentsForm = this.formBuilder.group({
    lithuanaia: ['', Validators.required],
    Purpose: ['', Validators.required],
  });
  countries: any;
  states: any;
  cities: any;

  get f() { return this.accountItemsForm.controls; }
  get m() { return this.documentsForm.controls };


  constructor(private router: Router, private store: Store<Document>, private signUpService: SignUpService,
    private modalService: NgbModal, private ngxLoader: NgxUiLoaderService,
    private businessDetailService: BusinessDetailService, private formBuilder: FormBuilder, 
    private route: ActivatedRoute, private sharedData: SharedService,) {

      this.businessDetailService.fetchCountries().subscribe(response => {
        if (response.code == 'DAO_SUCCESS_00') {
          this.countries = response.responseData.countries;
          console.log(this.countries);
        }
      });

      this.businessDetailService.getActiveIndustries().subscribe(response => {
        if (response.code == 'DAO_SUCCESS_00') {
          this.industries = response.responseData.industries;
          // console.log(this.industries);
        }
      });

      this.accountItemsForm = this.formBuilder.group({
        accountVos: new FormArray([])
      });

    this.imageUrl = environment.api_base_url;

    this.store.pipe(select(selectCustomers)).subscribe((result: any) => {
      this.prevCustomer = result;
    });

    this.store.pipe(select(selectBusiness)).subscribe((result: any) => {
      this.prevBusiness = result;
    });
    this.store.pipe(select(selectBusinessAddress)).subscribe((result: any) => {
      this.prevBusinessAddr = result;
    });

    this.store.pipe(select(selectBusinessDocument)).subscribe((result: any) => {
      this.previousResponse = result;
    });

    this.store.pipe(select(selectBusinessOwner)).subscribe((result: any) => {
      this.businessStakeholder = result;
    });

  }


  ngOnInit() {
    // if (this.previousResponse) {
    //   this.bDocument1 = this.previousResponse[0].document;
    //   this.bDocument2 = this.previousResponse[1].document;
    // }

    this.previousData = this.sharedData.getData();
    console.log(this.previousData);
    this.accountID = localStorage.getItem('accountID')
    this.addItemDetail();
  }

  fetchStates(countryId) {
    this.countries.map(x => {
      if (x.id == countryId) {
        this.states = x.stateVos;
      }
    })
  }

  // processFile(imageInput: any) {
  //   const file: File = imageInput.files[0];
  //   const reader = new FileReader();
  //   reader.addEventListener('load', (event: any) => {
  //     this.selectedFile = new ImageSnippet(event.target.result, file);
  //     this.sendImageApi(this.selectedFile.file);
  //   });
  //   reader.readAsDataURL(file);
  // }

  sendImageApi(file){
    this.ngxLoader.start();
    this.businessDetailService.sendDocument(file).subscribe((result) => {
      if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
        this.document=result.responseData.fileID;
        let businessRegDoc = new Document(null,"Document","Business Registration",this.document, 1);
        this.businessDocument.push(businessRegDoc);
        this.ngxLoader.stop();
        this.isSuccess = true;
        this.isError = false;
      }
      else {
        this.isError = true;
        this.ngxLoader.stop();
      }
    }, error => { console.log(error) })
  }

  // sendImageApi(file) {
  //   this.ngxService.start();
  //   this.businessDetailService.sendDocument(file, "ID", "Business Registration").subscribe((result) => {
  //     if (result != null && result.code === "DAO_SUCCESS_00" && result.responseData != null) {
  //       this.document = result.responseData.Date.document;
  //       let businessRegDoc = new Document(null, "Document", "Business Registration", this.document, 1);
  //       this.businessDocument.push(businessRegDoc);
  //       this.ngxService.stop();
  //       this.isSuccess = true;
  //       this.isError = false;
  //     }
  //     else {
  //       this.isError = true;
  //       this.ngxService.stop();
  //     }
  //   }, error => { console.log(error) })
  // }

  addItemDetail() {
    let control = <FormArray>this.accountItemsForm.controls.accountVos;
    let group = this.formBuilder.group({
      // id: [''],
      identificationNo: ['', Validators.required],
      country: ['', Validators.required],

    });
    control.push(
      group
    );
  }

  removeItemDetail(index) {
    let control = <FormArray>this.accountItemsForm.controls['accountVos'];
    control.removeAt(index);
  }

  // addBusinessDocument(modal) {
  //   this.submitted = true;
  //   if (this.documentsForm.invalid) {
  //     return;
  //   }
  //   else {
  //     this.store.dispatch(addBusDocument(this.businessDocument));

  //     this.accDetail = new Account(this.prevCustomer.id, this.prevCustomer.fullName, this.prevCustomer.mobileNo,
  //       this.prevCustomer.email, this.prevCustomer.type, this.prevCustomer.status);

  //     let bussinessAddressModel = new Address(null, this.prevBusinessAddr.line1, this.prevBusinessAddr.line2,
  //       this.prevBusinessAddr.city, this.prevBusinessAddr.postalCode, this.prevBusinessAddr.state, this.prevBusinessAddr.country);

  //     let finalBusiness = new Business(null, this.prevBusiness.fullName, this.prevBusiness.registrationNo, this.prevBusiness.website,
  //       this.prevBusiness.status, bussinessAddressModel, this.prevBusiness.registrationType, this.prevBusiness.industry, this.businessDocument, this.accDetail, this.businessStakeholder,
  //       this.prevCustomer.email);
  //     this.business = finalBusiness;

  //     this.createUser(modal);
  //     // this.router.navigate(['/personalDetails']);
  //   }
  // }

  addBusinessDocument() {
    console.log(this.accountItemsForm.controls);
    console.log("form", this.documentsForm);

    this.submitted = true;
    this.submitted2 = true
    if (this.accountItemsForm.invalid || this.documentsForm.invalid) {
      return;
    }

    else {
      let body = {
        business: {
          account: {
            id: this.accountID
          },
          address: {
            line1: this.previousData?.business?.address?.line1,
            line2: this.previousData?.business?.address?.line2,
            city: this.previousData?.business?.address?.city,
            postalCode: this.previousData?.business?.address?.postalCode,
            state: this.previousData?.business?.address?.state,
            country: this.previousData?.business?.address?.country,
          },
          businessTaxInfo: {
            connectionType: this.documentsForm.value.lithuanaia,
            otherBankAccount: 0,
            purpose: this.documentsForm.value.Purpose,
            additionalTaxInfo:
              this.accountItemsForm.controls.accountVos.value
          },
          countryVo: this.previousData?.business?.industry,
          dateIncorporation: this.previousData?.business?.dateIncorporation,
          emailAddress: this.previousData?.business?.emailAddress,
          fullName: this.previousData?.business?.fullName,
          industry: this.previousData?.business?.industry,
          legalEntityIdentifier: this.previousData?.business?.legalEntityIdentifier,
          registrationNo: this.previousData?.business?.registrationNo,
          registrationType: this.previousData?.business?.registrationType,
          status: 0,
          taxNo: null,

          tradingAddress: {
            line1: this.previousData?.business?.tradingAddress?.line1,
            line2: this.previousData?.business?.tradingAddress?.line2,
            city: this.previousData?.business?.tradingAddress?.city,
            postalCode: this.previousData?.business?.tradingAddress?.postalCode,
            state: this.previousData?.business?.address?.state,
            country: this.previousData?.business?.tradingAddress?.country,
          },
          tradingName: this.previousData?.business?.tradingName,
          website: this.previousData?.business?.website,
        }
      }
      this.ngxLoader.start();
      this.businessDetailService.businessRegister(body).subscribe(resp => {
        console.log(resp);
        if (resp.code === "DAO_SUCCESS_00") {
          localStorage.setItem('businessID', resp.responseData.user.id)
          this.ngxLoader.stop();
          this.router.navigate(['/businessRoles']);
        } else {
          this.ngxLoader.stop();
          alert(resp.message)
        }
      })


    }
  }

  createUser(modal) {
    this.ngxLoader.start();
    this.signUpService.createBusiness(this.business).subscribe((result) => {
      if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
        let obj = result.responseData.user;
        localStorage.setItem('businessID', obj.id);
        this.sendCustomerNotify(obj.id)
        const merchantModel = new Business(obj.id, obj.fullName, obj.registrationNo,
          obj.website, obj.status, obj.businessAddresses, obj.registrationType, obj.industry, obj.businessDocuments,
          this.accDetail, this.businessStakeholder, this.prevCustomer.email)
        this.store.dispatch(addBusiness(merchantModel))
        this.isSuccess = true;
        this.ngxLoader.stop();
        // this.router.navigate(['/personalDetails']);
        this.openmodal(modal);
      }
      else {
        this.ngxLoader.stop();
        this.isError = true;
      }
    }, error => { console.log(error) });

  }

  openmodal(content) {
    this.modalService.open(content, { size: 'lg', windowClass: 'modal-xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.router.navigate(['/personalDetails']);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onPrevious() {
    this.router.navigate(['/businessAddress']);
  }

  sendCustomerNotify(id: any) {
    this.signUpService.sendCustomerNotify(id).subscribe((resp: any) => {
      console.log("customer notify res", resp);

    });
  }

}

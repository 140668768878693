import {Action, createReducer, on} from '@ngrx/store';
import * as BusinessDocumentAction from '../action/personalDocumentactions';


export const personalDocumentFeatureKey = 'personalDocument';

export interface PersonalDocumentState {
  personalDocument: {};
}

export const initialState: PersonalDocumentState = {
  personalDocument: {}
};

export const personalDocumentReducer = createReducer(
  initialState,
  on(BusinessDocumentAction.addPersonalDoc,
    (state: PersonalDocumentState, {personalDocument}) =>
      ({...state,
        personalDocument,
      }))
);

export function PersonalDocumentReducer(state: PersonalDocumentState | undefined, action: Action): any {
  return personalDocumentReducer(state, action);
}

import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromCustomer from '../reducer/personalAddress.reducer';

export const selectPersonalState = createFeatureSelector<fromCustomer.PersonalAddressState>(
  fromCustomer.personalAddressFeatureKey,
);


export const selectPersonalAddress = createSelector(
  selectPersonalState,
  (state: fromCustomer.PersonalAddressState) => state.personalAddress
);

import { Component } from '@angular/core';

interface DropdownOption {
  value: string;
  label: string;
  icon: string;
}

@Component({
  selector: 'dropdown-with-icon',
  templateUrl: './dropdown-with-icon.component.html',
  styleUrls: ['./dropdown-with-icon.component.css']
})
export class DropdownWithIconComponent {
  options: DropdownOption[] = [
    { value: '1', label: 'Option 1', icon: '🇺🇸' }, // Example flag emoji
    { value: '2', label: 'Option 2', icon: '💳' }, // Example credit card emoji
    // Add more options as needed
  ];

  selectedOption: DropdownOption | undefined;
  showDropdown = false;

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  selectOption(option: DropdownOption): void {
    this.selectedOption = option;
    this.showDropdown = false;
  }
}

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { addBusiAddr } from '../store/action/businessAddress.actions';
import { Business } from '../model/business';
import { selectBusinessAddress } from '../store/selector/businessAddress.selectors'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address } from '../model/address'
import { Data } from 'src/app/providers/data';
import { SharedService } from 'src/app/shared/shared.service';
import { BusinessDetailService } from '../service/business-detail.service';

declare var $: any;

@Component({
  selector: 'business-address',
  templateUrl: './business-address.component.html',
  styleUrls: ['./business-address.component.scss']
})


export class BusinessAddressComponent implements OnInit {
  public isSuccess: boolean = false;
  public isError: boolean = false;
  public business: Business;
  public submitted = false;
  public states: any;
  public countries: any;
  public cities: any;
  public previousResponse: any;
  public address: any;
  public businessTradingAddress: boolean = false;
  public val: boolean = false;
  public previousData : any;
  public accountID: any;

  // addressForm = this.formBuilder.group({
  //   line1: ['', [Validators.required, Validators.minLength(6)]],
  //   line2: [''],
  //   postalCode: ['', Validators.required],
  //   city: ['', Validators.required],
  //   state: ['', Validators.required],
  //   country: ['', Validators.required]
  // });

  addressForm = this.formBuilder.group({
    line1             : ['', [Validators.required, Validators.minLength(6)]],
    line2             : [''],
    postalCode        : ['', Validators.required],
    city              : ['', Validators.required],
    state             : ['', Validators.required],
    country           : ['', Validators.required],
    addressCheckbox   : [false],
    tradingLine1      : ['', Validators.required],
    tradingLine2      : ['', Validators.required], 
    tradingcountry    : ['', Validators.required],
    tradingstate      : ['', Validators.required],
    tradingcity       : ['', Validators.required],
    tradingpostalCode : ['', Validators.required],
  });


  get f() { return this.addressForm.controls };

  constructor(private router: Router, private route: ActivatedRoute, private store: Store<Address>, private data: Data,
    private formBuilder: FormBuilder, private sharedData: SharedService, private service: BusinessDetailService) {

    this.route.data.subscribe(response => {
      let res = response[0].countries;
      if (res.code === 'DAO_SUCCESS_00') {
        this.countries = res.responseData.countries;
      }
    })

    this.store.pipe(select(selectBusinessAddress)).subscribe((result) => {
      this.previousResponse = result;
    })
  }



  ngOnInit() {
    
    this.previousData = this.sharedData.getData();
    console.log("previousResonse", this.previousData);

    if (this.previousResponse) {
      this.addressForm.patchValue(this.previousResponse);
    }


    this.addressForm.get('country').valueChanges.subscribe(val => {
      this.fetchStates(val);
    });

    this.addressForm.get('state').valueChanges.subscribe(val => {
      this.fetchCities(val);
    });
  }


  fetchCities(stateId) {
    this.states.map(x => {
      if (x.id == stateId) {
        this.cities = x.cityVos;
      }
    })
  }

  fetchStates(countryId) {
    this.countries.map(x => {
      if (x.id == countryId) {
        this.states = x.stateVos;
      }
    })
  }

  // addBusinessAddress() {
  //   this.submitted = true;
  //   if (this.addressForm.invalid) {
  //     return;
  //   }
  //   else {
  //     const businessAddress = new Address(null, this.addressForm.value.line1,
  //       this.addressForm.value.line2, this.addressForm.value.city, this.addressForm.value.postalCode,
  //       this.addressForm.value.state, this.addressForm.value.country);
  //     this.store.dispatch(addBusiAddr(businessAddress))
  //     this.router.navigate(['/businessDocuments']);
  //   }
  // }

  addBusinessAddress() {
    this.submitted = true;
    if (this.addressForm.invalid) {
      return;
    }
    else {
      let body = {
        business: {
          emailAddress: this.previousData.emailAddress,
          fullName: this.previousData.companyName,
          legalEntityIdentifier: this.previousData.identifier,
          dateIncorporation: this.previousData.incorporation,
          industry: this.previousData.industry,
          address:{
              line1: this.addressForm.value.line1,
              line2: this.addressForm.value.line2,
              city: this.addressForm.value.city,
              postalCode: this.addressForm.value.postalCode,
              state: this.addressForm.value.state,
              country: this.addressForm.value.country,
          },
          tradingAddress:{
              line1: this.addressForm.getRawValue().tradingLine1,
              line2: this.addressForm.getRawValue().tradingLine2,
              city: this.addressForm.getRawValue().tradingcity,
              postalCode: this.addressForm.getRawValue().tradingpostalCode,
              state: this.addressForm.getRawValue().tradingstate,
              country: this.addressForm.getRawValue().tradingcountry,
          },
          account:{
              id: this.accountID
          },
          registrationNo: this.previousData.registrationNo,
          tradingName: this.previousData?.tradingNameValue,
          registrationType: this.previousData.registrationType,
          website: this.previousData.website,
          status: 0
        }
      }
      // this.businesService.businessRegister(body).subscribe(resp => {
      //   console.log(resp);
      // })
      const businessAddress = new Address(null, this.addressForm.value.line1,
        this.addressForm.value.line2, this.addressForm.value.city, this.addressForm.value.postalCode,
        this.addressForm.value.state, this.addressForm.value.country);
      this.store.dispatch(addBusiAddr(businessAddress))
      this.sharedData.setData(body);
      this.router.navigate(['/businessDocuments']);
    }
  }

  onPrevious() {
    const navigationExtras = {
      queryParams: { paramKey: 'previous' },
    };
  
    this.router.navigate(['/businessDetails'], navigationExtras);
  }

  showAddress(){
    this.val = this.addressForm.controls.addressCheckbox.value;
    if(this.val) {
      this.addressForm.get('tradingLine1').setValue(this.addressForm.controls.line1.value);
      this.addressForm.get('tradingLine2').setValue(this.addressForm.controls.line2.value);
      this.addressForm.get('tradingcountry').setValue(this.addressForm.controls.country.value);
      this.addressForm.get('tradingstate').setValue(this.addressForm.controls.state.value);
      this.addressForm.get('tradingcity').setValue(this.addressForm.controls.city.value);
      this.addressForm.get('tradingpostalCode').setValue(this.addressForm.controls.postalCode.value);
      this.addressForm.controls.tradingLine1.disable();
      this.addressForm.controls.tradingLine2.disable();
      this.addressForm.controls.tradingcountry.disable();
      this.addressForm.controls.tradingstate.disable();
      this.addressForm.controls.tradingcity.disable();
      this.addressForm.controls.tradingpostalCode.disable();
    } else if(!this.val){
      console.log("in check");
      
      this.addressForm.controls.tradingLine1.enable();
      this.addressForm.controls.tradingLine1.reset();
      this.addressForm.controls.tradingLine2.enable();
      this.addressForm.controls.tradingLine2.reset();
      this.addressForm.controls.tradingcountry.enable();
      this.addressForm.controls.tradingcountry.reset();
      this.addressForm.controls.tradingstate.enable();
      this.addressForm.controls.tradingstate.reset();
      this.addressForm.controls.tradingcity.enable();
      this.addressForm.controls.tradingcity.reset();
      this.addressForm.controls.tradingpostalCode.enable();
      this.addressForm.controls.tradingpostalCode.reset();
     
    }
    
    
  }

  keyword = 'text';
  public countriesCheck = [
    {
      id: 1,
      name: 'Albania',
    },
    {
      id: 2,
      name: 'Belgium',
    },
    {
      id: 3,
      name: 'Denmark',
    },
    {
      id: 4,
      name: 'Montenegro',
    },
    {
      id: 5,
      name: 'Turkey',
    },
    {
      id: 6,
      name: 'Ukraine',
    },
    {
      id: 7,
      name: 'Macedonia',
    },
    {
      id: 8,
      name: 'Slovenia',
    },
    {
      id: 9,
      name: 'Georgia',
    },
    {
      id: 10,
      name: 'India',
    },
    {
      id: 11,
      name: 'Russia',
    },
    {
      id: 12,
      name: 'Switzerland',
    }
  ];

  selectEvent(item) {
    // do something with selected item
    console.log(item);
    
  }

  fetchAddress(search: string) {
    let body = {
        address: search
    }
    this.service.fetchAddress(body).subscribe((resp: any) =>{
      if(resp.code =="DAO_SUCCESS_00") {
        if(resp.responseData.Data != null) {
          this.address = resp.responseData.Data.items;
        }
      } else {

      }
    });
  }

  onChangeSearch(search: string) {
    console.log(search)
    // fetch remote data from here
    this.fetchAddress(search);

  }

  onFocused(e) {
    // do something
  }


}
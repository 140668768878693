import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { selectPersonal } from '../store/selector/personal.selectors'
import { selectBusiness } from '../../../app/business-details/store/selector/business.selectors'
import { addPerson } from '../store/action/personal.actions';
import { Business } from '../../business-details/model/business';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Account } from '../../business-details/model/account';
import { BusinessCustomer } from '../model/businessCustomer'
import { SharedService } from 'src/app/shared/shared.service';
import { BusinessDetailService } from 'src/app/business-details/service/business-detail.service';

declare var $: any;

@Component({
  selector: 'personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})


export class PersonalDetailsComponent implements OnInit {
  public business: Business;
  public submitted = false;
  public userEmail: string
  public accType: string;
  public userFullName: string
  public userPhoneNum: string
  public previousResponse: any;
  public previousBusinessResponse: any;
  public firstName: any = localStorage.getItem('firstName');
  public lastName: any = localStorage.getItem('lastName');
  public businessID: any;
  public accountID: any;
  public documentsType: any;
  isYesSelected: boolean = false;
  isUsaYesSelected: boolean = false;
  countries: any;
  states: any;
  cities: any;


  // personalDetailForm = this.formBuilder.group({
  //   firstName: ['', [Validators.required, Validators.minLength(3)]],
  //   lastName: ['', [Validators.required, Validators.minLength(3)]],
  //   dateOfBirth: ['', [Validators.required, this.ageValidator]],
  //   gender: ['', Validators.required],
  //   nationality: ['', Validators.required]
  // })

  personalDetailForm = this.formBuilder.group({
    firstName: ['', [Validators.required, Validators.minLength(3)]],
    lastName: ['', [Validators.required, Validators.minLength(3)]],
    dateOfBirth: ['', [Validators.required, this.ageValidator]],
    gender: ['', Validators.required],
    nationality: ['', Validators.required],
    doctype: ['', Validators.required],
    docnum: ['', Validators.required],
    issucountry: ['', Validators.required],
    docexp: ['', Validators.required],
    mobnumber: ['', Validators.required],
    email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    attorney: ['no'],
    isUsaPerson: ['no'],
    benfActTrustee: ['no'],
    representativeNiminee: ['no'],
    taxIdentificationNo: [''],
    associatePolitically: [''],
    role: ['CEO'],
  })

  get f() { return this.personalDetailForm.controls };

  constructor(private router: Router, private store: Store<BusinessCustomer>, private businessService: BusinessDetailService,
    private formBuilder: FormBuilder, private route: ActivatedRoute, private sharedDatta: SharedService) {

    this.store.pipe(select(selectBusiness)).subscribe((result) => {
      this.previousBusinessResponse = result;
    });

    this.store.pipe(select(selectPersonal)).subscribe((result: any) => {
      this.previousResponse = result;
    });

    this.businessService.fetchCountries().subscribe(response => {
      if (response.code == 'DAO_SUCCESS_00') {
        this.countries = response.responseData.countries;
        console.log(this.countries);
      }
    });

    this.businessService.getBusinesType().subscribe(response => {
      if (response.code == 'DAO_SUCCESS_00') {
        this.documentsType = response.responseData.Data;
        console.log(this.documentsType);
      }
    });
  }

  ngOnInit() {
    this.personalDetailForm.controls.firstName.setValue(this.firstName);
    // this.personalDetailForm.get('firstName')?.disable();
    this.personalDetailForm.controls.lastName.setValue(this.lastName);
    // this.personalDetailForm.get('lastName')?.disable();

    this.businessID = localStorage.getItem('businessID');
    this.accountID = localStorage.getItem('accountID');
    //  this.business=this.data.storage.businessFullInfo;
    this.userEmail = localStorage.getItem('userEmail');
    this.userFullName = localStorage.getItem('userFullName');
    this.userPhoneNum = localStorage.getItem('userPhone');
    if (this.previousResponse) {
      let checkPrev = this.previousResponse.accountVo;
      if (checkPrev) {
        this.accType = this.previousResponse.accountVo.type;
      }
      else {
        this.accType = "operator";
      }
      this.personalDetailForm.patchValue(this.previousResponse)
    }
  }

  fetchCities(stateId) {
    this.states.map((x: { id: any; cityVos: any; }) => {
      if (x.id == stateId) {
        this.cities = x.cityVos;
      }
    })
  }

  fetchStates(countryId) {
    this.countries.map(x => {
      if (x.id == countryId) {
        this.states = x.stateVos;
      }
    })
  }

  addPersonalDetail() {
    this.submitted = true;
    if (this.personalDetailForm.invalid) {
      return;
    }
    else {
      var customerList: BusinessCustomer[] = [];
      let accountModel = new Account(null, this.userFullName, this.userPhoneNum, this.userEmail, "operator");

      var custModel = new BusinessCustomer(this.previousResponse.id, this.previousBusinessResponse.id, accountModel, this.accType, this.personalDetailForm.getRawValue().firstName,
        this.personalDetailForm.getRawValue().lastName, this.personalDetailForm.value.gender, this.personalDetailForm.value.nationality,
        this.personalDetailForm.value.dateOfBirth, 3, null, null);

      customerList.push(custModel);

      this.store.dispatch(addPerson(custModel))

      this.sharedDatta.setData(this.personalDetailForm.getRawValue());

      this.router.navigate(['/personalAddress']);
    }
  }

  onPrevious() {
    this.router.navigate(['/businessRoles']);
  }

  ageValidator(control: FormControl) {
    if (control.value == null || control.value == '')
      return null;
    let today = new Date();
    let birthDate = new Date(control.value);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age >= 18) {
      return null;
    }
    return {
      minAge: true
    };
  }

  onAttorneyChange() {
    let val = this.personalDetailForm.controls.attorney.value;
    if (val === "yes") {
      this.isYesSelected = true;
    } else {
      this.isYesSelected = false;
    }
  }

  onUsaChange() {
    let val = this.personalDetailForm.controls.isUsaPerson.value;
    if (val === "yes") {
      this.isUsaYesSelected = true;
    } else {
      this.isUsaYesSelected = false;
    }
  }
}
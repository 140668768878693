<app-card-large>
  <div class="dyEAXN">
    <!-- RIGHT PANEL-->
    <div class="bCDrPV djdIYm">
      <div class="mjYRwVK">
        <!-- FORM CONTENT-->
        <!-- <div class="hnZgAZ">
                    <div class="husZCp"> -->
        <!-- <div class="main-panel__content">
                            <div class="main-panel__content">
                                <h1  class="main-panel__heading nonreferral">Review</h1>
                            </div>
                        </div> -->

        <!-- </div> -->
        <!-- <br> -->
        <form name="form" [formGroup]="informationForm">
          <div class="row">
            <div class="col-sm-4">
              <label
                data-component="form-input-email-label"
                class="form__label"
                for="email"
              >
                First Name <span style="color: red">*</span></label
              >
              <div class="data-div">
                {{ firstName }}
              </div>
            </div>
            <div class="col-sm-4">
              <label
                data-component="form-input-email-label"
                class="form__label"
                for="email"
              >
                Last Name <span style="color: red">*</span></label
              >
              <div class="data-div">
                {{ lastName }}
              </div>
            </div>
          </div>
          <!-- <div class="row"> -->
          <!-- <div class="col-sm-4">
                            <label data-component="form-input-email-label" 
                            class="styles__Root-sc-1myek4t-0 ezjDKc styles__Root-sc-138xw60-0 ubhdy" 
                            for="email"> Last Name <span style="color: red;">*</span></label>
                            <div class="data-div">
                                {{ lastName }}
                              </div>
                        </div> -->
          <!-- </div> -->
          <div class="row">
            <div class="col-sm-4">
              <label
                data-component="form-input-email-label"
                class="form__label"
                for="email"
              >
                Date Of Birth <span style="color: red">*</span></label
              >
              <div class="data-div">
                {{ dateOfBirth }}
              </div>
            </div>
            <div class="col-sm-4">
              <label
                data-component="form-input-email-label"
                class="form__label"
                for="email"
                >Nationality<span style="color: red">*</span></label
              >
              <div class="data-div">
                {{ nationality }}
              </div>
            </div>
          </div>
          <!-- <div class="row"> -->
          <!-- <div class="col-sm-4">
                            <label data-component="form-input-email-label" 
                            class="styles__Root-sc-1myek4t-0 ezjDKc styles__Root-sc-138xw60-0 ubhdy" 
                            for="email"> Nationality <span style="color: red;">*</span></label>
                            <div class="data-div">
                                {{ nationality }}
                              </div>
                        </div> -->
          <!-- </div> -->
          <div class="row" *ngIf="isNotPassport">
            <div class="col-sm-4">
              <label
                data-component="form-input-email-label"
                class="form__label"
                for="email"
              >
                ID Number <span style="color: red">*</span></label
              >
              <div class="data-div">
                {{ idNumber }}
              </div>
            </div>
            <div class="" *ngIf="isNotId">
              <div class="col-sm-4">
                <label
                  data-component="form-input-email-label"
                  class="form__label"
                  for="email"
                >
                  Passport Number <span style="color: red">*</span></label
                >
                <div class="data-div">
                  {{ idNumber }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row" *ngIf="isNotId">
                        <div class="col-sm-4">
                            <label data-component="form-input-email-label" 
                            class="styles__Root-sc-1myek4t-0 ezjDKc styles__Root-sc-138xw60-0 ubhdy" 
                            for="email"> Passport Number <span style="color: red;">*</span></label>
                            <div class="data-div">
                                {{ idNumber }}
                              </div>
                        </div>
                    </div> -->
          <div class="row">
            <div class="col-sm-4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Date of Expiry <span style="color: red">*</span></label
              >
              <input
                id="name"
                name="name"
                type="date"
                autocomplete="name"
                formControlName="expiry"
                [ngClass]="{ invalid: submitted && f.expiry.errors }"
                class="styles__Root-sc-1dahuaq-1 eWmkGc"
                value=""
              />
              <div *ngIf="submitted && f.expiry.errors" class="text-danger">
                <div *ngIf="f.expiry.errors.required">Expiry required</div>
              </div>
            </div>
            <div class="col-sm-4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Email <span style="color: red">*</span></label
              >
              <input
                id="name"
                formControlName="email"
                [ngClass]="{ invalid: submitted && f.email.errors }"
                name="name"
                type="text"
                autocomplete="name"
                class="styles__Root-sc-1dahuaq-1 eWmkGc"
                value=""
              />
              <div *ngIf="submitted && f.email.errors" class="text-danger">
                <div *ngIf="f.email.errors.required">Email is required</div>
              </div>
            </div>
          </div>

          <!-- <div class="row"> -->
          <!-- <div class="col-sm-4">
                            <label data-component="form-input-name-label" class="styles__Root-sc-1myek4t-0 ezjDKc styles__Root-sc-138xw60-0 ubhdy" for="name">Email <span style="color: red;">*</span></label>
                            <input id="name" formControlName="email"
                            [ngClass]="{'invalid': submitted &&  f.email.errors }" name="name" type="text"
                            autocomplete="name" class="styles__Root-sc-1dahuaq-1 eWmkGc" value="">
                            <div *ngIf="submitted && f.email.errors" class="text-danger">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                            </div>
                        </div> -->
          <!-- </div> -->
          <div class="row">
            <div class="col-sm-4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Address <span style="color: red">*</span></label
              >
              <input
                id="name"
                formControlName="address"
                name="name"
                type="text"
                autocomplete="name"
                class="styles__Root-sc-1dahuaq-1 eWmkGc"
                value=""
              />
            </div>
            <div class="col-sm-4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Town <span style="color: red">*</span></label
              >
              <input
                id="postTown"
                formControlName="postTown"
                name="postTown"
                type="text"
                autocomplete="name"
                class="styles__Root-sc-1dahuaq-1 eWmkGc"
                value=""
              />
            </div>
          </div>
          <!-- <div class="row">
                        <div class="col-sm-4">
                            <label data-component="form-input-name-label" class="form__label" for="name">Town <span style="color: red;">*</span></label>
                            <input id="postTown" formControlName="postTown" name="postTown" type="text"
                            autocomplete="name" class="styles__Root-sc-1dahuaq-1 eWmkGc" value="">
                        </div>
                    </div> -->
          <div class="row">
            <div class="col-sm-4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Postal Code <span style="color: red">*</span></label
              >
              <input
                id="name"
                formControlName="postCode"
                name="name"
                type="text"
                autocomplete="name"
                class="styles__Root-sc-1dahuaq-1 eWmkGc"
                value=""
              />
            </div>
            <div class="col-sm-4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Country <span style="color: red">*</span></label
              >
              <input
                id="name"
                formControlName="resCountry"
                name="name"
                type="text"
                autocomplete="name"
                class="styles__Root-sc-1dahuaq-1 eWmkGc"
                value=""
              />
            </div>
          </div>
          <!-- <div class="row">
                        <div class="col-sm-4">
                            <label data-component="form-input-name-label" class="form__label" for="name">Country <span style="color: red;">*</span></label>
                            <input id="name" formControlName="resCountry" name="name" type="text"
                            autocomplete="name" class="styles__Root-sc-1dahuaq-1 eWmkGc" value="">
                        </div>
                    </div> -->
          <!-- <div class="eBKYjJ cgMkag"> -->
          <div class="row">
            <div class="continue-btn">
              <button
                data-component="primary-button"
                type="submit"
                role="button"
                tabindex="0"
                class="epjTBn"
                (click)="saveData()"
              >
                Save
              </button>
            </div>
          </div>
          <!-- </div> -->
        </form>
      </div>
      <!--FORM CONTENT -->
    </div>
  </div>
</app-card-large>

<!-- RIGHT PANEL-->

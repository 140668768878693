import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Data } from 'src/app/providers/data';
import { Router, ActivatedRoute } from '@angular/router';
import { Business } from 'src/app/business-details/model/business';
import { AuthenticationService } from '../service/authentication.service';


@Component({
  selector: 'app-pass-base-verification',
  templateUrl: './pass-base-verification.component.html',
  styleUrls: ['./pass-base-verification.component.scss']
})
export class PassBaseVerificationComponent implements OnInit {
  public isError: boolean;
  public isSuccess: boolean = false;


  @ViewChild("passbaseButton") passbaseButton: ElementRef;
    public business : Business;


    constructor(private router: Router,
      private data: Data,
      private signUpService:AuthenticationService){}


  ngOnInit(): void {
    this.business=this.data.storage.businessFullDetails;

  }


  ngAfterViewInit() {
    const onFinished = (error, authKey, additionalAttributes) => {
      console.log(authKey);
      if (error) {
        console.error(error);
      }
      else {
        setTimeout(function () {
          fetch(
            `https://api.passbase.com/api/v1/authentications/by_key/${authKey}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'IRaWoy8CDzsmDCkgJjjaQjc33DevnkyZnIyOiMgOlGH6u0oE9PZkVSGE5zy8ymuALILS5ml6mDshn4AwssgZbJTqfqDcrWHnt6NuZFhYcV8KBEkSbmPiibuikYvPopup'
              }
            }
          ).then(response => {
            this.res = response.json;
            //  let passBaseRes=this.res.authentication.authentication_assessments
            console.log(this.res.authentication);
            // console.log(passBaseRes);
          });
        }, 99900)
      }
    }


    // Passbase.renderButton(
    //   this.passbaseButton.nativeElement,
    //   onFinished,
    //   "MnzyWdfrNIAGxu1lUp6l16RpAxGPvHAmoadigB8jMeCsN6x5xoqloZ6HL2TQGfbg",
    //   {
    //     integrationType: "signup",
    //     theme: {
    //       darkTheme: true,
    //       systemDarkMode: true
    //     }
    //   }
    // );

  }

  createUser(){
    this.signUpService.createBusiness(this.business).subscribe((result) => {
      if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
        this.isSuccess = true;
      }
      else {
        this.isError = true;

      }
    }, error => { console.log(error) });

this.router.navigate(['/createAccount']);
  }



}

<div class="dropdown">
    <button class="dropdown-toggle" (click)="toggleDropdown()">
      <span class="icon">{{ selectedOption?.icon }}</span>
      <span>{{ selectedOption?.label }}</span>
      <span class="arrow" [class.active]="showDropdown">&#9660;</span>
    </button>
    <div class="dropdown-menu" [class.show]="showDropdown">
      <div *ngFor="let option of options" (click)="selectOption(option)">
        <span class="icon">{{ option.icon }}</span>
        <span>{{ option.label }}</span>
      </div>
    </div>
  </div>
  
<div [class.selected]="selectedWalletId === wallet.id" class="card-widget-main">
  <div class="flex-container">
    <div class="flag-container col">
      <img class="flag-image" [src]="wallet.refCurrencies?.flagUrl" />
    </div>

    <div class="card-content col">
      <div class="currency-code row">
        {{ wallet.refCurrencies?.currencyCode }}
      </div>
      <div class="account-details row">{{ val }} {{ wallet.accountNumber.slice(-4) }}</div>
      <div class="balance-details row">
        {{ wallet.availableBalance | currency:'USD':'symbol':'1.2-2' }}
      </div>
    </div>
    <div class="col radio-button-check">
      <input type="checkbox" class="filled-in" [(ngModel)]="wallet.isSelected"
        [checked]="selectedWalletId === wallet.id" (change)="onWalletChange($event, wallet.id)" />
      <span></span>
    </div>

  </div>
</div>
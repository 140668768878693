import { Address } from "./address";
import { Document } from "./document";
import { BusinessCustomer } from "./businessCustomer";

export class Business { 
  constructor(  
    public id: number,
    public fullName: string,
    public registrationNo: string,
    public taxNo: string,
    public website:string,
    public registrationDate: string,
    public status: string,
    public address: Address,
    public documents: Document[],
    public customer: BusinessCustomer[],
    ){}  }
import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Store } from '@ngrx/store';
import { addCustomer } from '../store/action/customer.actions';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../service/account.service';
import { Account } from '../model/account'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';


declare var $: any;

@Component({
  selector: 'account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})


export class AccountDetailsComponent implements OnInit {
  [x: string]: any;
  public submitted = false;
  public closeResult: string;
  public navUrl: string;
  public passwordNotMatch: boolean = false;
  // public phoneNumber: boolean = false;
  public errorMessage: any = '';
  public error: boolean = false;
  public numberAlreadyExit: boolean = false;

  accountForm = this.formBuilder.group({
    userEmail: ['', Validators.required,Validators.email],
    companyName: ['', Validators.required],
    fullName: ['', Validators.required],
    phoneNumber: ['', this.menaCountryCodeValidator],
    password: ['', [Validators.required, Validators.minLength(8), this.passwordValidator],],
    confirmPassword: ['', Validators.required],
  });

  get f() { return this.accountForm.controls; }

  constructor(private accountService: AccountService, private store: Store<Account>, private ngxLoader: NgxUiLoaderService,
    private router: Router, private formBuilder: FormBuilder, private modalService: NgbModal,
     private toastr: ToastrService, private route: ActivatedRoute,) {

    this.route.queryParams.subscribe(params => {
      console.log(params);

      this.firstName = params['firstName'];
      this.lastName = params['lastName'];
      this.phoneNumber = params['phoneNumber'];
      this.email = params['email'];
    });

    // this.accountForm.get('phoneNumber').disable();
  }

  ngOnInit() {
    let fullName = this.firstName + ' ' + this.lastName
    console.log(fullName)
    // this.accountForm.get("userEmail").setValue(localStorage.getItem('userEmail'));
    this.accountForm.get('phoneNumber').setValue(this.phoneNumber);
    this.accountForm.get('fullName').setValue(fullName);
    this.accountForm.get('userEmail').setValue(this.email);
   
    localStorage.setItem('userFullName', fullName);
    localStorage.setItem('email', this.email);

    // if((this.data.storage != undefined || this.data.storage != null)){
    //     if(this.data.storage.check==1){
    //       this.accountForm.patchValue({
    //         fullName:localStorage.getItem('userFullName'),
    //         phoneNumber:localStorage.getItem('userPhone')
    //       })
    //     }
    // }
  }

  openmodal(content) {
    this.modalService.open(content, { size: 'lg', windowClass: 'modal-xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.router.navigate([this.navUrl]);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  addAccount() {
    this.submitted = true;
    if (this.accountForm.invalid) {
      return;
    }
    else {
      if (this.accountForm.controls.password.value === this.accountForm.controls.confirmPassword.value) {
        this.passwordNotMatch = false;
      } else {
        this.passwordNotMatch = true
        return
      }
      console.log(this.accountForm.controls)
      let accountModel = new Account(null, this.accountForm.getRawValue().fullName, this.accountForm.getRawValue().phoneNumber, this.accountForm.getRawValue().userEmail, "operator", 0, this.accountForm.value.password);
      this.ngxLoader.start();
      this.accountService.saveUser(accountModel).subscribe((result) => {
        if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
          this.ngxLoader.stop();
          // localStorage.setItem('userPhone',this.accountForm.value.phoneNumber+"");
          localStorage.setItem('accountID', result.responseData.user.id + "");
          this.router.navigate(['/businessDetails']);
          localStorage.setItem('userEmail', this.accountForm.value.userEmail + "")
          localStorage.setItem('userFullName', this.accountForm.value.fullName);
          this.numberAlreadyExit = false;
          // localStorage.setItem('userFullName', result.responseData.user.fullName);
          //   this.userId = result.responseData.user.id;
          //   this.isSuccess = true;
          //   this.isError = false;
          let res = new Account(result.responseData.user.id, result.responseData.user.fullName, this.accountForm.value.phoneNumber, this.accountForm.value.userEmail, "operator", 0, this.accountForm.value.password);
          this.store.dispatch(addCustomer(res))
          // }
          // else {
          //   this.isError = true;
        } else if (result.code === "ERROR_55") {
          this.numberAlreadyExit = true;
        }
        else {
          this.ngxLoader.stop();
          this.isError = true;
          this.numberAlreadyExit = false;
        }
        // this.router.navigate(['/verifyOTP']);
      }, error => { console.log(error) });
    }



  }

  // addAccount(modal) {
  //   console.log(this.accountForm.controls);

  //   this.submitted = true;
  //   if (this.accountForm.invalid) {
  //     return;
  //   }
  //   else {
  //     if (this.accountForm.controls.password.value === this.accountForm.controls.confirmPassword.value) {
  //       this.passwordNotMatch = false;
  //     } else {
  //       this.passwordNotMatch = true
  //       return
  //     }

  //     localStorage.setItem("fullName", this.accountForm.value.fullName);
  //     localStorage.setItem("email", this.accountForm.value.userEmail);
  //     localStorage.setItem("password", this.accountForm.value.password);
  //     localStorage.setItem("phoneNum", this.accountForm.value.phoneNumber);

  //     let accountModel = new Account(this.accountForm.value.fullName, this.accountForm.value.phoneNumber, this.accountForm.value.userEmail, "operator", "0", this.accountForm.value.password);
  //     this.ngxService.start();
  //     this.accountService.sendOtp({
  //       phoneNumber: this.accountForm.value.phoneNumber,
  //       phoneCode: '+92'
  //     }).subscribe(res => {
  //       console.log(res);
  //       if (res.code === "SUCCESS_00" && res.responseData != null) {
  //         localStorage.setItem("otpNumber", res.responseData.data.otpNumber)
  //         this.numberAlreadyExit = false;
  //         this.router.navigate(['/verifyOTP']);
  //       } else if (res.code === "ERROR_55") {
  //         this.numberAlreadyExit = true;
  //       } else {
  //         this.numberAlreadyExit = false;
  //         console.log(res);
  //       }
  //     });
  //   }
  // }

  showSuccess() {
    this.toastr.success('Hello world!', 'Toastr fun!');
  }

  onPrevious() {
    this.router.navigate(['/emailCheck'],
      {
        queryParams: { previous: true }
      });
  }
  passwordValidator(control: any): { [key: string]: boolean } | null {
    const password = control.value;
    if (!password) {
      return null;

    }
    const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;

    if (!regex.test(password)) {
      return { invalidPassword: true };
    }

    return null;
  }


  validateMENAPhoneNumber() {
    const phoneNumber = this.f.phoneNumber.value;
    if (/^\+\d{1,4}\d{7,}$/.test(phoneNumber)) {
      this.f.phoneNumber.setErrors(null);
    } else {
      this.f.phoneNumber.setErrors({ 'invalidMENA': true });
    }
  }

}
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { selectBusiness } from '../store/selector/business.selectors'
import { addBusiness } from '../store/action/business.actions';
import { addBusOwners } from '../store/action/businessOwner.actions';
import { Business } from '../model/business';
import { BusinessDetailService } from '../service/business-detail.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from 'src/app/shared/shared.service';
declare var $: any;

@Component({
  selector: 'business-details',
  templateUrl: './business-details.component.html',
  styleUrls: ['./business-details.component.scss']
})


export class BusinessDetailsComponent implements OnInit {
  [x: string]: any;
  isYesSelected: boolean = false;
  public isSuccess: boolean = false;
  public isDisable: boolean = false;
  public isError: boolean = false;
  public accountItemsForm: FormGroup;
  public submitted = false;
  public previousResponse: any;
  public industries: any;
  public registrationType: any;
  public companyName: any;
  isContinueButtonDisabled = true;
  public showErrorMessage: boolean = false;
  public errorMessage: any;

  // informationForm = this.formBuilder.group({
  //   fullName : ['',[Validators.required]],
  //   registrationType : ['',Validators.required],
  //   registrationNo : ['',[Validators.required, Validators.minLength(8)]],
  //   website : ['',[Validators.required,Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]],
  //   industry : ['',Validators.required]
  // })

  informationForm = this.formBuilder.group({
    companyName: ['', [Validators.required]],
    registrationType: ['', Validators.required],
    registrationNo: ['', [Validators.required, Validators.minLength(4)]],
    website: ['', this.invalidwebsite],
    industry: ['', Validators.required],
    incorporation: ['', Validators.required],
    identifier: ['', Validators.required],
    emailAddress: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    tradingName: ['no'],
    tradingNameValue: ['']
  });
  countries: any;
  addressForm: any;
  states: any;
  cities: any;

  get f() { return this.informationForm.controls };

  get af() { return this.accountItemsForm.controls; }

  constructor(private businessService: BusinessDetailService, private router: Router,
    private ngxLoader: NgxUiLoaderService, private store: Store<Business>,
    private formBuilder: FormBuilder, private route: ActivatedRoute, private sharedData: SharedService,) {

    this.companyName = localStorage.getItem("companyName");
    console.log("company name", this.companyName);


    // this.route.data.subscribe(response => {
    //   let res = response[0].countries;
    //   if (res.code === 'DAO_SUCCESS_00') {
    //     this.countries = res.responseData.countries;
    //   }
    //   console.log(this.countries);

    // });

    this.businessService.fetchCountries().subscribe(response => {
      if (response.code == 'DAO_SUCCESS_00') {
        this.countries = response.responseData.countries;
        console.log(this.countries);
      }

    });

    this.accountItemsForm = this.formBuilder.group({
      accountVos: new FormArray([])
    });
    this.store.pipe(select(selectBusiness)).subscribe((result) => {
      this.previousResponse = result;
    })


    this.businessService.getActiveIndustries().subscribe(response => {
      if (response.code == 'DAO_SUCCESS_00') {
        this.industries = response.responseData.industries;
        // console.log(this.industries);
      }

    });
    
    this.businessService.getRegistrationType().subscribe(response => {
      console.log(response);
      if (response.code == 'DAO_SUCCESS_00') {
        this.registrationType = response.responseData.types;
        console.log(this.registrationType);
      }
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const receivedParam = params['paramKey'];
      if (receivedParam === undefined) {
        this.isContinueButtonDisabled = true;
      } else {
        this.isContinueButtonDisabled = false;
      }
      // Use the receivedParam as needed
    });
    this.informationForm.get("companyName").setValue(this.companyName);
    if (this.previousResponse) {
      this.informationForm.patchValue(this.previousResponse);
    }
    // this.addItemDetail();
  }

  addBusinessInfo() {

    this.submitted = true;
    console.log(this.informationForm.controls)
    if (this.informationForm.invalid) {
      return;
    }
    else {
      this.store.dispatch(addBusOwners(this.accountItemsForm.getRawValue().accountVos));

      const merchantModel = new Business(null, this.informationForm.value.fullName, this.informationForm.value.registrationNo,
        this.informationForm.value.website, "0", null, this.informationForm.value.registrationType, this.informationForm.value.industry, [], null, [], null);

      this.store.dispatch(addBusiness(merchantModel));
      this.sharedData.setData(this.informationForm.getRawValue());
      this.router.navigate(['/businessAddress']);
    }

  }

  // addBusinessInfo() {
  //   this.showErrorMessage = false; // Reset the error message flag
  //   console.log(this.informationForm);
  //   console.log(this.accountItemsForm);


  //   this.submitted = true;

  //   if (this.informationForm.invalid) {
  //     return;
  //   }
  //   else if (this.accountItemsForm.invalid) {
  //     return;
  //   }


  //   // let arr=<FormArray>this.accountItemsForm.get("accountVos");
  //   //   for(var i =0; i<arr.length;i++){
  //   //     let group=arr.at(i);
  //   //   group.get("fullName").setValue(group.get("firstName").value+" "+group.get("lastName").value);
  //   // }

  //   // console.log(this.accountItemsForm.get("accountVos").value);

  //   else {
  //     // Prepare the data array to check email and phone existence
  //     let arr = <FormArray>this.accountItemsForm.get('accountVos');
  //     let dataToCheck: { email: string; phone: string }[] = [];

  //     for (let i = 0; i < arr.length; i++) {
  //       let group = arr.at(i);
  //       let email = group.get('email').value;
  //       let phone = group.get('mobileNo').value;

  //       dataToCheck.push({ email, phone });
  //     }

  //     // Use the userService to check email and phone existence
  //     this.businessService.checkEmailAndPhoneExist(dataToCheck).subscribe((result) => {
  //       // Check if any email or phone already exists
  //       console.log(result);

  //       if (result.code === '400') {
  //         // Set a flag to indicate the error
  //         this.showErrorMessage = true;
  //         this.errorMessage = result.message
  //         return
  //       } else {
  //         this.showErrorMessage = false;
  //         this.errorMessage = ''
  //         // Continue with dispatching actions
  //         for (let i = 0; i < arr.length; i++) {
  //           let group = arr.at(i);
  //           group.get('fullName').setValue(group.get('firstName').value + ' ' + group.get('lastName').value);
  //         }

  //         this.store.dispatch(addBusOwners(this.accountItemsForm.getRawValue().accountVos));

  //         const merchantModel = new Business(null, this.informationForm.getRawValue().fullName, this.informationForm.value.registrationNo,
  //           this.informationForm.value.website, '0', null, this.informationForm.value.registrationType,
  //           this.informationForm.value.industry, [], null, [], null);

  //         this.store.dispatch(addBusiness(merchantModel));
  //         this.router.navigate(['/businessAddress']);
  //       }
  //     },
  //       (error) => {
  //         // Set a flag to indicate the error
  //         this.showErrorMessage = true;
  //         this.errorMessage = error.message
  //         console.error('Error checking email and phone existence:', error);
  //       }
  //     );
  //   }
  // }

  fetchCities(stateId) {
    this.states.map((x: { id: any; cityVos: any; }) => {
      if (x.id == stateId) {
        this.cities = x.cityVos;
      }
    })
  }

  fetchStates(countryId) {
    this.countries.map(x => {
      if (x.id == countryId) {
        this.states = x.stateVos;
      }
    })
  }

  addItemDetail() {
    this.isDisable = false;
    let control = <FormArray>this.accountItemsForm.controls.accountVos
    let group = this.formBuilder.group({
      id: [''],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      fullName: [''],
      gender: ['', Validators.required],
      mobileNo: ['', Validators.required],
      type: ['Stakeholder'],
      designation: ['', Validators.required],
      status: [2]
    })
    control.push(
      group
    )
  }

  removeItemDetail(index) {
    let control = <FormArray>this.accountItemsForm.controls['accountVos']
    control.removeAt(index);
  }

  getActiveIndustries() {
    this.businessService.getActiveIndustries().subscribe(res => {
      if (res.code === 'SUCCESS') {

      } else {
      }
    });
  }

  selectTradingName() {
    let val = this.informationForm.controls.tradingName.value;
    if (val === "yes") {
      this.isYesSelected = true;
      this.informationForm.controls["tradingNameValue"].setValidators([Validators.required])
    } else {
      this.isYesSelected = false;
      this.informationForm.controls['tradingNameValue'].clearValidators()
    }
  }

  // verifyCompanyName() {
  //   const registrationNoControl = this.informationForm.get('registrationNo');
  //   const fullNameControl = this.informationForm.get('fullName');

  //   // Reset any previous errors
  //   registrationNoControl.setErrors(null);
  //   fullNameControl.setErrors(null);

  //   // Check if registrationNo is less than 8 digits
  //   if (registrationNoControl.value && registrationNoControl.value.length < 8) {
  //     registrationNoControl.setErrors({ minlength: true });
  //     fullNameControl.setValue('');
  //   } else {
  //     // Reset the error if the length is valid

  //     let body = {
  //       countries: "GB",
  //       regNo: this.informationForm.controls.registrationNo.value
  //     };
  //     this.ngxLoader.start();
  //     this.businessService.verifyBusinesName(body).subscribe((resp: any) => {
  //       console.log(resp);
  //       if (resp.code === "DAO_SUCCESS_00" && resp.responseData && resp.responseData.Data.companies) {
  //         const companies = resp.responseData.Data.companies;

  //         if (companies.length === 0 || (companies.length > 0 && companies[0].status !== "Active")) {
  //           // Show error message and disable continue button
  //           registrationNoControl.setErrors({ companyNotExist: true });
  //           this.isContinueButtonDisabled = true;
  //           fullNameControl.setValue('');
  //           this.ngxLoader.stop();
  //         } else {
  //           const company = companies[0];
  //           // Set the full name and disable the control if status is "Active"
  //           fullNameControl.setValue(company.name);
  //           fullNameControl.disable();
  //           this.isContinueButtonDisabled = false;
  //           this.ngxLoader.stop();
  //         }
  //       } else if (resp.code === "DAO_ERROR_95") {
  //         // Show error message for invalid registration number and disable continue button
  //         registrationNoControl.setErrors({ invalidRegistrationNumber: true });
  //         fullNameControl.setValue('');
  //         this.isContinueButtonDisabled = true;
  //         this.ngxLoader.stop();
  //       } else {
  //         this.ngxLoader.stop();
  //         // Handle other error cases if needed
  //       }
  //     });
  //   }
  // }

  addPrevious() {

    this.router.navigate(['/accountDetails']);
  }
  validateWebsite() {
    const website = this.f.website.value;
    // Define a regex pattern for a website URL (example pattern).
    const urlPattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}(\S*)?$/;

    if (urlPattern.test(website)) {
      this.f.website.setErrors(null);
    } else {
      this.f.website.setErrors({ 'invalidwebsite': true });
    }
  }

}

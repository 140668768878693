import { Component, Input } from '@angular/core';

@Component({
  selector: 'tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css']
})

export class TabComponent {
  @Input() public labelOne: string = '';
  @Input() public imageOne: string;
  @Input() public labelTwo: string = '';
  @Input() public imageTwo: string;


  constructor() {
    this.imageOne = '';
    this.imageTwo = '';
  }
}

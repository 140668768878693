<div class="panel-wrapper">
    <div class="feature-panel--quotes-container">
        <div class="jaYPZW JkoxV feature-panel--quotes quotes--uber">
            <div class="sign-up__quote selected" data-company="uber">
                <p class="sign-up__quote__excerpt">
                    “QBANC provides one-stop faster and cheaper global payment, remittance, and treasury management for businesses.”
                </p>
                <i class="sign-up__quote__logo sign-up__quote__logo--uber"></i>
            </div>

        </div>
    </div>

    <div class="main-panel">

        <div class="main-panel__table">
            <div class="main-panel__table-cell">
                <div class="main-panel__switch">
                    <!--<span class="main-panel__switch__text">Already have an account?</span>
                    <a class="main-panel__switch__button" id="login-sign-in-button"
                        href="loginaa64.html?redir=%2Fd%2FABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789%2B%2F&amp;redirHash=">
                        Sign in
                    </a>-->
                </div>
                <div class="main-panel__content">





                        <h1 class="main-panel__heading nonreferral">
                            Please Verify your Self!
                            <span class="main-panel__subheading">
                                Accept payments from around the world
                            </span>
                        </h1>

                        <div class="form__page sign-up__step-one">
                            <div class="form__group">
                                <!-- <label for="email" class="form__label">Work Email Address</label> -->
                                <div #passbaseButton></div>
                            </div>
                            <div class="form__group" style="display: none;">
                                <label for="phone" class="form__label">Phone Number</label>
                                <!-- <input type="text" class="form__input" name="phone" id="phone"
                                    placeholder="Example (222) 555-1234 or +49 (351) 125-3456" value="" /> -->
                                <div class="alert alert-error alert-phone">
                                    Please enter a phone number.
                                </div>
                            </div>
                        </div>

                        <div class="eBKYjJ cgMkag">
                            <button data-component="primary-button" type="submit" 
                                role="button" tabindex="0"
                                class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 epjTBn"
                                (click)="createUser()" >Create User</button>
                        </div>

                        <div class="text--center" style="display: none;">
                            <button type="submit" class="form__button ea__prehide sign-up__submit" >
                                Continue
                            </button>
                            <a class="form__button ea__redirect form__button--pink-accessible sign-up__submit"
                            style="display: none;">
                                Continue
                            </a>
                        </div>
                        <p class="form__legal ea__prehide" style="display: none;">
                            By clicking "<span class="sign-up__cta-text">Continue</span>" I agree to InVision's
                            <a href="http://www.invisionapp.com/terms_of_service" target="_blank">Terms of
                                Service</a> and
                            <a href="https://www.invisionapp.com/privacy" target="_blank">Privacy Policy</a>.
                        </p>
                </div> <!-- main-panel__content -->
            </div>
        </div>
    </div> <!-- main-panel -->
</div> <!-- panel-wrapper -->

import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromCustomer from '../reducer/personal.reducer';

export const selectPersonalState = createFeatureSelector<fromCustomer.personalDetailState>(
  fromCustomer.personalFeatureKey,
);


export const selectPersonal = createSelector(
  selectPersonalState,
  (state: fromCustomer.personalDetailState) => state.personalInformation
);

<app-card-large>
  <div class="dyEAXN">
    <div class="bCDrPV djdIYm">
      <div class="mjYRwVK">
        <div class="main-panel__content">
          <div class="main-panel__content">
            <h1 class="main-heading nonreferral">Business Information</h1>
            <h2 class="main-sub-heading">
              Please provide details about your business
            </h2>
          </div>
        </div>
        <!-- <form name="form" [formGroup]="informationForm">
          <div class="row">
            <div class="col-sm-11">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Registration Number <span style="color: red">*</span></label
              >
              <input
                id="name"
                name="name"
                type="text"
                (change)="verifyCompanyName()"
                formControlName="registrationNo"
                [ngClass]="{ invalid: submitted && f.registrationNo.errors }"
                autocomplete="name"
                class="fts-input-field register-w"
                value=""
              />
              <div *ngIf="f.registrationNo.errors" class="text-danger">
                <div *ngIf="submitted && f.registrationNo.errors.required">
                  Registration Number is required
                </div>
                <div *ngIf="f.registrationNo.errors.minlength">
                  Required atleast 8 numbers
                </div>
                <div *ngIf="f.registrationNo.errors.companyNotExist">
                  Company not active. Please check the registration number.
                </div>
                <div *ngIf="f.registrationNo.errors.invalidRegistrationNumber">
                  Invalid registration number.
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-5">
              <label
                data-component="form-input-email-label"
                class="form__label"
                for="email"
                >Business Name <span style="color: red">*</span></label
              >
              <input
                id="text"
                name="text"
                type="text"
                formControlName="fullName"
                [ngClass]="{ invalid: submitted && f.fullName.errors }"
                autocomplete="username"
                class="fts-input-field input-width"
                value=""
              />
              <div *ngIf="submitted && f.fullName.errors" class="text-danger">
                <div *ngIf="f.fullName.errors.minlength">
                  Required atleast 5 Characters
                </div>
                <div *ngIf="f.fullName.errors.required">
                  Business Name is required
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <label for="team_size" class="form__label"
                >Registration Type <span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                formControlName="registrationType"
                [ngClass]="{ invalid: submitted && f.registrationType.errors }"
                class="fts-select"
              >
                <option
                  *ngFor="let type of registrationType"
                  [value]="type.type"
                >
                  {{ type.type }}
                </option>
              </select>
              <div
                *ngIf="submitted && f.registrationType.errors"
                class="text-danger"
              >
                <div *ngIf="f.registrationType.errors.required">
                  Registration type is required
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              <label for="team_size" class="form__label"
                >Website<span style="color: red">*</span></label
              >
              <input
                type="text"
                autocomplete="name"
                formControlName="website"
                [ngClass]="{ invalid: submitted && f.website.errors }"
                class="fts-input-field input-width"
                value=""
              />
              <div *ngIf="submitted && f.website.errors" class="text-danger">
                <div *ngIf="f.website.errors.required">Website is required</div>
                <div *ngIf="f.website.errors?.pattern">
                  Please provide a valid Website
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <label for="team_size" class="form__label"
                >Industry<span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                formControlName="industry"
                [ngClass]="{ invalid: submitted && f.industry.errors }"
                class="fts-select"
              >
                <option
                  *ngFor="let industry of industries"
                  [value]="industry.name"
                >
                  {{ industry.name }}
                </option>
              </select>
              <div *ngIf="submitted && f.industry.errors" class="text-danger">
                <div *ngIf="f.industry.errors.required">
                  Industry is required
                </div>
              </div>
            </div>
          </div>
          <div class="Stakeholders-add">
            <h5 class="main-sub-heading">Stakeholders</h5>
            
            <div
              style="color: red"
              *ngIf="showErrorMessage"
              class="error-message"
            >
              {{ errorMessage }}
            </div>
            <form class="col s12" [formGroup]="accountItemsForm">
              <div formArrayName="accountVos">
                <div
                  class="border-card"
                  *ngFor="
                    let detail of accountItemsForm.get('accountVos')?.controls;
                    let i = index
                  "
                >
                  <ng-container class="card" [formGroupName]="i">
                    
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="form__label">First Name</label>
                        <input
                          class="fts-input-field input-width validate-empty"
                          formControlName="firstName"
                          type="text"
                          min="1"
                          required
                          [ngClass]="{
                            invalid: submitted && detail.get('firstName').errors
                          }"
                        />
                        <div
                          *ngIf="submitted && detail.get('firstName').errors"
                        >
                          <div
                            *ngIf="
                              submitted &&
                              detail.get('firstName').errors?.required
                            "
                            class="text-danger"
                          >
                            Required
                          </div>
                          <div
                            *ngIf="
                              submitted &&
                              detail.get('firstName').errors?.minlength
                            "
                            class="text-danger"
                          >
                            Atleast 3 Ch.
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-3">
                        <label class="form__label">Last Name</label>
                        <input
                          class="fts-input-field input-width validate-empty"
                          formControlName="lastName"
                          type="text"
                          min="1"
                          required
                          [ngClass]="{
                            invalid: submitted && detail.get('lastName').errors
                          }"
                        />
                        <div *ngIf="submitted && detail.get('lastName').errors">
                          <div
                            *ngIf="
                              submitted &&
                              detail.get('lastName').errors?.required
                            "
                            class="text-danger"
                          >
                            Required
                          </div>
                          <div
                            *ngIf="
                              submitted &&
                              detail.get('lastName').errors?.minlength
                            "
                            class="text-danger"
                          >
                            Atleast 3 Ch.
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-3">
                        <label class="form__label">Role</label>
                        <input
                          class="fts-input-field input-width validate-empty"
                          formControlName="designation"
                          type="text"
                          required
                          [ngClass]="{
                            invalid:
                              submitted && detail.get('designation').errors
                          }"
                        />
                        <div
                          *ngIf="submitted && detail.get('designation').errors"
                        >
                          <div
                            *ngIf="
                              submitted && detail.get('designation').errors
                            "
                            class="text-danger"
                          >
                            Required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-3">
                        <label class="form__label">Email</label>
                        <input
                          class="fts-input-field input-width validate-empty"
                          formControlName="email"
                          type="text"
                          required
                          [ngClass]="{
                            invalid: submitted && detail.get('email').errors
                          }"
                        />
                        <div *ngIf="submitted && detail.get('email').errors">
                          <div
                            *ngIf="
                              submitted && detail.get('email').errors?.required
                            "
                            class="text-danger"
                          >
                            Required
                          </div>
                          <div
                            *ngIf="
                              submitted && detail.get('email').errors?.pattern
                            "
                            class="text-danger"
                          >
                            Invalid Email
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <label class="form__label">Phone</label>
                        <input
                          class="fts-input-field input-width validate-empty"
                          formControlName="mobileNo"
                          type="text"
                          [mask]="'999999999999999'"
                          required
                          [ngClass]="{
                            invalid: submitted && detail.get('mobileNo').errors
                          }"
                        />
                        <div *ngIf="submitted && detail.get('mobileNo').errors">
                          <div
                            *ngIf="submitted && detail.get('mobileNo').errors"
                            class="text-danger"
                          >
                            Required
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <label class="form__label">Gender</label>
                        <select
                          class="fts-select gender-width"
                          formControlName="gender"
                          [ngClass]="{
                            invalid: submitted && detail.get('gender').errors
                          }"
                        >
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        <div
                          *ngIf="submitted && detail.get('gender').errors"
                          class="text-danger"
                        >
                          Required
                        </div>
                      </div>
                    </div>

                    <div class="input-field">
                      <a
                        (click)="removeItemDetail(i)"
                        class="mb-6 btn-floating waves-effect waves-light red accent-2"
                      >
                        <img src="../../../assets/close.png" alt="" />
                      </a>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row">
                <div class="col m12 s12">
                  <a (click)="addItemDetail()" class="accent-2">
                    <img src="../../../assets/Vector.png" alt="" />
                  </a>
                </div>
              </div>
            </form>
          </div>
        </form> -->

        <form name="form" [formGroup]="informationForm">
          <div class="row">
            <div class="input-field col s6">
              <label
                data-component="form-input-email-label"
                class="form__label"
                for="companyName"
                >Company name <span style="color: red">*</span></label
              >
              <input
                id="companyName"
                name="companyName"
                type="text"
                formControlName="companyName"
                [ngClass]="{ invalid: submitted && f.companyName.errors }"
                autocomplete="companyName"
                class="fts-input-field register-w"
                value
              />
              <div
                *ngIf="submitted && f.companyName.errors"
                class="text-danger"
              >
                <div *ngIf="f.companyName.errors.minlength">
                  Required atleast 5 characters
                </div>
                <div *ngIf="f.companyName.errors.required">
                  Company name is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label
                data-component="form-input-email-label"
                class="form__label"
                for="email"
                >Company email <span style="color: red">*</span></label
              >
              <input
                id="email"
                name="text"
                type="text"
                formControlName="emailAddress"
                [ngClass]="{ invalid: submitted && f.emailAddress.errors }"
                autocomplete="emailAddress"
                class="fts-input-field register-w"
                value
              />
              <div
                *ngIf="submitted && f.emailAddress.errors"
                class="text-danger"
              >
                <div *ngIf="f.emailAddress.errors.required">
                  Email address is required
                </div>
                <div *ngIf="f.emailAddress.errors?.pattern">
                  Please provide a valid email address
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label for="team_size" class="form__label">
                Country of incorporation<span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                formControlName="industry"
                class="fts-select"
              >
                <option disabled selected>Country incorporatin</option>
                <option
                  *ngFor="let countrty of countries"
                  [value]="countrty.id"
                >
                  {{ countrty.name }}
                </option>
              </select>
              <div *ngIf="submitted && f.industry.errors" class="text-danger">
                <div *ngIf="f.industry.errors.required">
                  countrty incorporatin is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Registration number <span style="color: red">*</span></label
              >
              <input
                id="name"
                name="name"
                type="text"
                formControlName="registrationNo"
                onkeypress="return /[0-9]/i.test(event.key)"
                [ngClass]="{ invalid: submitted && f.registrationNo.errors }"
                autocomplete="name"
                class="fts-input-field input-width"
                value
              />
              <div
                *ngIf="submitted && f.registrationNo.errors"
                class="text-danger"
              >
                <div *ngIf="f.registrationNo.errors.required">
                  Registration number is required
                </div>
                <div *ngIf="f.registrationNo.errors.minlength">
                  Required atleast 4 numbers
                </div>
              </div>
            </div>
            <div class="input-field col s4">
              <label for="team_size" class="form__label">
                Select type of legal entity
                <span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                formControlName="registrationType"
                [ngClass]="{ invalid: submitted && f.registrationType.errors }"
                class="fts-select-small"
              >
                <option value disabled selected>
                  Select type of legal entity
                </option>
                <option
                  *ngFor="let type of registrationType"
                  [value]="type.type"
                >
                  {{ type.type }}
                </option>
              </select>
              <div
                *ngIf="submitted && f.registrationType.errors"
                class="text-danger"
              >
                <div *ngIf="f.registrationType.errors.required">
                  select type of legal entity is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label for="team_size" class="form__label"
                >Website<span style="color: red">*</span></label
              >
              <input
                type="text"
                autocomplete="name"
                formControlName="website"
                [ngClass]="{ invalid: submitted && f.website.errors }"
                class="fts-input-field register-w"
                (blur)="validateWebsite()"
              />
              <div *ngIf="submitted && f.website.errors" class="text-danger">
                <div *ngIf="f.website.hasError('required')">
                  Website is required
                </div>
                <div *ngIf="f.website.hasError('invalidwebsite')">
                  Please provide a valid website
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Date of incorporation(if available)<span style="color: red"
                  >*</span
                ></label
              >
              <input
                id="name"
                name="name"
                type="date"
                formControlName="incorporation"
                placeholder="incorporation"
                [ngClass]="{ invalid: submitted && f.incorporation.errors }"
                autocomplete="name"
                class="fts-input-field input-width"
                value
              />
              <div
                *ngIf="submitted && f.incorporation.errors"
                class="text-danger"
              >
                <div *ngIf="f.incorporation.errors.required">
                  Date of incorporation is required
                </div>
              </div>
            </div>
            <div class="input-field col s4">
              <label for="team_size" class="form__label"
                >Legal entity identifier<span style="color: red">*</span></label
              >
              <input
                name="name"
                type="text"
                onkeypress="return /[0-9]/i.test(event.key)"
                formControlName="identifier"
                placeholder="legal entity"
                class="fts-input-field"
                value
              />
              <div *ngIf="submitted && f.identifier.errors" class="text-danger">
                <div *ngIf="f.identifier.errors.required">
                  Legal entity identifier is required
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex"
            style="margin-top: 10px;"
          >
            <span class="form__label">
              Do you have trading name<span style="color: red">*</span>
            </span>
            <label>
              <input
                type="radio"
                (change)="selectTradingName()"
                formControlName="tradingName"
                value="yes"
                style="margin-left: 377px"
              />
              Yes
            </label>
            <label class="radio-btn">
              <input
                type="radio"
                (change)="selectTradingName()"
                formControlName="tradingName"
                value="no"
              />
              No
            </label>
          </div>
          <div *ngIf="isYesSelected">
            <input
              class="fts-input-field register-w"
              formControlName="tradingNameValue"
              type="text"
              placeholder="trading name"
            />
            <div
              *ngIf="submitted && f.tradingNameValue.errors"
              class="text-danger"
            >
              <div *ngIf="f.tradingNameValue.errors.required">
                Name is required
              </div>
            </div>
          </div>
          <!-- <div class="eBKYjJ cgMkag">
            <div class="row">
              <div class="input-field col s4">
                <button
                  data-component="primary-button"
                  type="submit"
                  role="button"
                  tabindex="0"
                  class="fts-button-tertiary"
                  (click)="addPrevious()"
                >
                  Previous
                </button>
              </div>
              <div class="input-field col s4">
                <button
                  data-component="primary-button"
                  type="submit"
                  role="button"
                  tabindex="0"
                  class="epjTBn"
                  (click)="addBusinessInfo()"
                >
                  Continue
                </button>
              </div>
            </div>
          </div> -->
        </form>
        <div class="primary-back-continue">
          <div class="">
            <button
              data-component="primary-button"
              type="submit"
              role="button"
              tabindex="0"
              (click)="addPrevious()"
              class="fts-button-tertiary"
            >
              Previous
            </button>
          </div>
          <div class="">
            <button
              data-component="primary-button"
              type="submit"
              role="button"
              tabindex="0"
              (click)="addBusinessInfo()"
              class="fts-button-primary"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="eBKYjJ cgMkag">
          <button
            data-component="primary-button"
            type="submit"
            role="button" [disabled]="isContinueButtonDisabled"
            tabindex="0"
            class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 epjTBn"
            (click)="addBusinessInfo()"
          >
            Continue
          </button>
        </div> -->
  </div>
</app-card-large>

import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from 'console';
import { formatDate } from '@angular/common';
import { BusinessDetailService } from '../../service/business-detail.service';

@Component({
  selector: 'app-businessroles',
  templateUrl: './businessroles.component.html',
  styleUrls: ['./businessroles.component.scss']
})
export class BusinessrolesComponent implements OnInit {

  fieldArray: Array<any> = [];
  newAttribute: any = {};
  @Input() name: string;
  firstField = true;
  firstFieldName = 'First Item name';
  isEditItems: boolean;
  formDataArray: any[] = [];
  directorDataArray: any[] = [];
  shareholderDataArray: any[] = [];
  businessShareholderDataArray: any[] = [];
  UboDataArray: any[] = [];
  public editingIndex: any;
  public directorIndex: any;
  public shareIndex: any;
  public UboIndex: any;
  businessID: any;
  public authorizedSignatoryPayload: any
  public accountID: any;
  public customers: any;
  directorPayload: any;
  shareDataPayload: any[];
  uboPayload: any[];
  selectedOption: string = '';
  public directorTabCustomer: any;
  public selectedCustomerData: any;
  public shareholderTabCustomer: any;
  public uboTabCustomer: any;
  public authorizedContinueButton: boolean = false
  isYesSelected: boolean = false;
  isDirecotUSASelected: boolean = false;
  isShareHolderUSASelected: boolean = false;
  isUboUSASelected: boolean = false;
  public isIndividual: boolean = true;
  public isBusiness: boolean = false;
  public shareHolderTypeIndividual: boolean = true;
  public shareHolderTypeBusiness: boolean = false;
  public documentsType: any;
  public status: any;

  addFieldValue(index) {
    if (this.fieldArray.length <= 2) {
      this.fieldArray.push(this.newAttribute);
      this.newAttribute = {};
    } else {

    }
  }

  deleteFieldValue(index) {
    this.fieldArray.splice(index, 1);
  }

  onEditCloseItems() {
    this.isEditItems = !this.isEditItems;
  }

  submitted: boolean = false;
  submittedbusiness: boolean = false;
  submittedShare: boolean = false;
  submitted2: boolean = false;
  submitted3: boolean = false;
  submitted4: boolean = false;
  countries: any;
  states: any;
  cities: any;
  constructor(private router: Router, private formBuilder: FormBuilder, private renderer: Renderer2, private el: ElementRef,
    private route: ActivatedRoute, private BusinessDetail: BusinessDetailService, private cdr: ChangeDetectorRef) {

    this.BusinessDetail.fetchCountries().subscribe(response => {
      if (response.code == 'DAO_SUCCESS_00') {
        this.countries = response.responseData.countries;
        console.log(this.countries);
      }
    });

    this.BusinessDetail.getBusinesType().subscribe(response => {
      if (response.code == 'DAO_SUCCESS_00') {
        this.documentsType = response.responseData.Data;
        console.log(this.documentsType);
      }
    });
  }

  @ViewChild('homeTab') homeTab: any;
  @ViewChild('profileTab') profileTab: any;
  @ViewChild('contacttab') contacttab: any;
  @ViewChild('ubotab') ubotab: any;

  BusinessDirector = this.formBuilder.group({

    corporateFullName: ['', Validators.required],
    countryIncorporation: ['', Validators.required],
    typeCorporate: ['', Validators.required],
    phoneNumber: ['', Validators.required],
    registerationNumber: ['', Validators.required],
    dateincorporation: ['', Validators.required],
  });
  get k() { return this.BusinessDirector.controls };

  BusinessShare = this.formBuilder.group({

    corporateFullName: ['', Validators.required],
    countryIncorporations: ['', Validators.required],
    typeCorporates: ['', Validators.required],
    phoneNumbers: ['', Validators.required],
    registerationNumber: ['', Validators.required],
    dateincorporation: ['', Validators.required],
  });

  get s() { return this.BusinessShare.controls };

  busineesroles = this.formBuilder.group({

    lastname: ['', Validators.required],
    firstname: ['', Validators.required],
    birth: ['', Validators.required],
    place: ['', Validators.required],
    nationality: ['', Validators.required],
    Iddoc: ['', Validators.required],
    docnum: ['', Validators.required],
    docissuing: ['', Validators.required],
    expdate: ['', Validators.required],
    mobnum: ['', Validators.required],
    email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    country: ['', Validators.required],
    city: ['', Validators.required],
    street: ['', Validators.required],
    zip: ['', Validators.required],
    countryone: ['', Validators.required],
    taxnum: ['', Validators.required],
    citizenship: ["",],
    position: ["", Validators.required],
    persons: ["",],
    nominee: ["",],
    trustee: ["",],
    usaPerson: ['']
  });
  get t() { return this.busineesroles.controls };

  busineesroles1 = this.formBuilder.group({

    lastname: ['', Validators.required],
    firstname: ['', Validators.required],
    birth: ['', Validators.required],
    place: ['', Validators.required],
    nationality: ['', Validators.required],
    Iddoc: ['', Validators.required],
    docnum: ['', Validators.required],
    docissuing: ['', Validators.required],
    expdate: ['', Validators.required],
    mobnum: ['', Validators.required],
    email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    country: ['', Validators.required],
    city: ['', Validators.required],
    street: ['', Validators.required],
    zip: ['', Validators.required],
    countryone: ['', Validators.required],
    taxnum: ['', Validators.required],
    citizenship: ["",],
    position: ["", Validators.required],
    persons: ["",],
    nominee: ["",],
    trustee: ["",],
    usaPerson: ["",],

  });



  get c() { return this.busineesroles1.controls };

  busineesroles2 = this.formBuilder.group({

    lastname: ['', Validators.required],
    firstname: ['', Validators.required],
    birth: ['', Validators.required],
    place: ['', Validators.required],
    nationality: ['', Validators.required],
    Iddoc: ['', Validators.required],
    docnum: ['', Validators.required],
    docissuing: ['', Validators.required],
    expdate: ['', Validators.required],
    mobnum: ['', Validators.required],
    email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    country: ['', Validators.required],
    city: ['', Validators.required],
    street: ['', Validators.required],
    zip: ['', Validators.required],
    countryone: ['', Validators.required],
    taxnum: ['', Validators.required],
    person: ['', Validators.required],
    citizenship: ["",],
    position: ["", Validators.required],
    persons: ["",],
    nominee: ["",],
    trustee: ["",],
    usaPerson: ["",]

  });

  get b() { return this.busineesroles2.controls };

  busineesroles4 = this.formBuilder.group({

    lastname: ['', Validators.required],
    firstname: ['', Validators.required],
    birth: ['', Validators.required],

    place: ['', Validators.required],
    nationality: ['', Validators.required],
    Iddoc: ['', Validators.required],
    docnum: ['', Validators.required],
    docissuing: ['', Validators.required],
    expdate: ['', Validators.required],
    mobnum: ['', Validators.required],
    email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    country: ['', Validators.required],
    city: ['', Validators.required],
    street: ['', Validators.required],
    zip: ['', Validators.required],
    countryone: ['', Validators.required],
    taxnum: ['', Validators.required],
    person: ['', Validators.required],
    citizenship: ["",],
    position: ["", Validators.required],
    persons: ["",],
    nominee: ["",],
    trustee: ["",],
    usaPerson: ["",]
  });

  get d() { return this.busineesroles4.controls };

  ngOnInit(): void {
    // console.log("value", this.isIndividual);
    this.businessID = localStorage.getItem('businessID');
    this.accountID = localStorage.getItem('accountID');
    this.status = localStorage.getItem('status');

    this.busineesroles4.get('country').valueChanges.subscribe(val => {
      this.fetchStates(val);
      this.withoutStatefetchCities(val);
    });

    this.busineesroles2.get('country').valueChanges.subscribe(val => {
      this.fetchStates(val);
      this.withoutStatefetchCities(val);
    });

    this.busineesroles1.get('country').valueChanges.subscribe(val => {
      this.fetchStates(val);
      this.withoutStatefetchCities(val);
    });

    this.busineesroles.get('country').valueChanges.subscribe(val => {
      this.fetchStates(val);
      this.withoutStatefetchCities(val);
    });

    if (this.busineesroles.get('state') != null) {
      this.busineesroles.get('state').valueChanges.subscribe(val => {
        this.fetchCities(val);
      });
    }

    if (this.busineesroles4.get('state') != null) {
      this.busineesroles4.get('state').valueChanges.subscribe(val => {
        this.fetchCities(val);
      });
    }
    if (this.busineesroles2.get('state') != null) {
      this.busineesroles2.get('state').valueChanges.subscribe(val => {
        this.fetchCities(val);
      });
    }
    if (this.busineesroles1.get('state') != null) {
      this.busineesroles1.get('state').valueChanges.subscribe(val => {
        this.fetchCities(val);
      });
    }
    // console.log("Status", this.status);
    if (this.status === "2") {
      setTimeout(() => {
        this.getCustmerList()
        this.homeTab.nativeElement.click();
      }, 200)
    }
    if (this.status === "3") {
      setTimeout(() => {
        this.getCustmerList()
        this.profileTab.nativeElement.click();
      }, 200)

    } else if (this.status === '4') {
      setTimeout(() => {
        this.getCustmerList()
        this.contacttab.nativeElement.click();
      }, 200);
    } else if (this.status === '5') {
      setTimeout(() => {
        this.getCustmerList()
        this.ubotab.nativeElement.click();
      }, 200);
    }
  }

  onSelectType(value: any) {
    console.log(value);

    if (value === "individual") {
      this.isIndividual = true;
      this.isBusiness = false
    } else {
      this.isIndividual = false;
      this.isBusiness = true
    }
  }

  onShareHolderType(value: any) {
    console.log(value);
    if (value === "individual") {
      this.shareHolderTypeIndividual = true;
      this.shareHolderTypeBusiness = false
    } else {
      this.shareHolderTypeIndividual = false;
      this.shareHolderTypeBusiness = true
    }
  }

  addFormData() {
    let body = [];

    if (this.busineesroles.valid) {
      if (typeof this.editingIndex !== 'undefined') {
        // Update existing data at the editingIndex
        this.formDataArray[this.editingIndex] = this.busineesroles.value;
        // Reset the form and clear the editingIndex
        this.editingIndex = undefined;
      } else {
        // Add new data to the array
        this.formDataArray.push(this.busineesroles.value);
      }
      body.push(this.formDataArray);
      console.log('this is body', body);
      console.log("this.form data", this.busineesroles);
      this.busineesroles.reset();
      if (this.formDataArray.length > 0) {
        this.authorizedContinueButton = true;
      } else {
        this.authorizedContinueButton = false;
      }
    } else {
      // Handle invalid form data
    }
  }

  addDirectorData() {
    if (this.busineesroles1.valid) {
      if (typeof this.directorIndex !== 'undefined') {
        this.directorDataArray[this.directorIndex] = this.busineesroles1.value;
        this.directorIndex = undefined;
      } else {
        this.directorDataArray.push(this.busineesroles1.value);
      }
      console.log("this.form data", this.busineesroles1);
      this.busineesroles1.reset();
    } else {
      this.directorDataArray.push(this.busineesroles1.value);
    }
  }

  addShareholderData() {
    if (this.busineesroles2.valid) {
      if (typeof this.shareIndex !== 'undefined') {
        this.shareholderDataArray[this.shareIndex] = this.busineesroles2.value;
        this.shareIndex = undefined;
      } else {
        this.shareholderDataArray.push(this.busineesroles2.value);
      }
      console.log("this.form data", this.busineesroles2);
      this.busineesroles2.reset();
    } else { }
  }

  addUboData() {
    if (this.busineesroles4.valid) {
      if (typeof this.UboIndex !== 'undefined') {
        this.UboDataArray[this.UboIndex] = this.busineesroles4.value;
        this.UboIndex = undefined;
      } else {
        this.UboDataArray.push(this.busineesroles4.value);
      }
      console.log("this.form data", this.busineesroles4);
      this.busineesroles4.reset();
    } else { }
  }

  fetchCities(stateId) {
    this.states.map((x: { id: any; cityVos: any; }) => {
      if (x.id == stateId) {
        this.cities = x.cityVos;
      }
    })
  }

  fetchStates(countryId) {
    this.countries.map(x => {
      if (x.id == countryId) {
        this.states = x.stateVos;
      }
    })
  }

  withoutStatefetchCities(countryId) {
    this.countries.map(x => {
      if (x.id == countryId) {
        x.stateVos.map(statess => {
          if (statess.country == countryId) {
            this.cities = statess.cityVos;
          } else {
            this.cities = [];
          }
        });
      }
    })
  }

  routeToPersonal() {
    if (this.UboDataArray.length === 0) {
      console.log(this.busineesroles4.controls)
      this.submitted = true;
      if (this.busineesroles4.invalid) {
        return;
      }
    }

    if (this.UboDataArray.length != 0) {
      const transformedArray: any[] = [];

      // Iterate through the inputArray and transform each item
      this.UboDataArray.forEach(item => {
        const uboItem: any = {
          businessId: this.businessID,
          firstName: item.firstname,
          lastName: item.lastname,
          nationality: item.nationality,
          email: item.email,
          phone: item.mobnum,
          placeofBirth: item.place,
          accountVo: {
            id: this.accountID
          },
          status: 0,
          dateOfBirth: item.birth,
          customerAddressVo: {
            line1: item.street,
            line2: "",
            city: item.city,
            postalCode: item.zip,
            state: null,
            country: item.country,
          },
          customerDocumentDetailsVo: [{
            docType: item.Iddoc,
            docNumber: item.docnum,
            docCountry: item.docissuing,
            expiryDate: item.expdate
          }],
          taxIdentificationNo: item.taxnum,
          multiCitizenship: item.citizenship == 'no' ? 0 : 1,
          exposedPerson: item.persons == 'no' ? 0 : 1,
          designation: item.position,
          benfActNominee: item.nominee == 'no' ? 0 : 1,
          benfActTrustee: item.trustee == 'no' ? 0 : 1,
          benfUSAPerson: item.usaPerson == 'no' ? 0 : 1,
          isAuthorisedSignatory: 0,
          isDirector: 0,
          isShareHolder: 0,
          isUbo: 1,
          businessPartners: 0,
          taxCountry: item.countryone,
          type: "individual",
        };
        // Push the transformed item to the result array
        transformedArray.push(uboItem);
      });

      this.uboPayload = transformedArray;
      console.log(this.uboPayload);
      this.BusinessDetail.businessRoles(this.uboPayload).subscribe(response => {
        console.log(response);

      })
      this.router.navigate(['/personalDetails']);
    }
  }

  openProfileTab() {
    if (this.formDataArray.length === 0) {
      console.log(this.busineesroles.controls)
      this.submitted = true;
      if (this.busineesroles.invalid) {
        return;
      }
    }

    if (this.formDataArray.length != 0) {
      const transformedArray: any[] = [];

      // Iterate through the inputArray and transform each item
      this.formDataArray.forEach(item => {
        const transformedItem: any = {
          businessId: this.businessID,
          firstName: item.firstname,
          lastName: item.lastname,
          nationality: item.nationality,
          email: item.email,
          phone: item.mobnum,
          placeofBirth: item.place,
          accountVo: {
            id: this.accountID
          },
          status: 0,
          dateOfBirth: item.birth,
          customerAddressVo: {
            line1: item.street,
            line2: "",
            city: item.city,
            postalCode: item.zip,
            state: null,
            country: item.country,
          },
          customerDocumentDetailsVo: [{
            docType: item.Iddoc,
            docNumber: item.docnum,
            docCountry: item.docissuing,
            expiryDate: item.expdate
          }],
          taxIdentificationNo: item.taxnum,
          multiCitizenship: item.citizenship == 'no' ? 0 : 1,
          exposedPerson: item.persons == 'no' ? 0 : 1,
          designation: item.position,
          benfActNominee: item.nominee == 'no' ? 0 : 1,
          benfActTrustee: item.trustee == 'no' ? 0 : 1,
          benfUSAPerson: item.usaPerson == 'no' ? 0 : 1,
          isAuthorisedSignatory: 1,
          isDirector: 0,
          isShareHolder: 0,
          isUbo: 0,
          businessPartners: 0,
          taxCountry: item.countryone,
          type: "individual",
        };
        // Push the transformed item to the result array
        transformedArray.push(transformedItem);
      });

      this.authorizedSignatoryPayload = transformedArray;
      console.log(this.authorizedSignatoryPayload);

      this.BusinessDetail.businessRoles(this.authorizedSignatoryPayload).subscribe(response => {
        console.log(response);
        if (response.code == "DAO_SUCCESS_00") {
          setTimeout(() => {
            this.getCustmerList()
          }, 200);
          this.profileTab.nativeElement.click();
        }
      })
    }

  }

  getCustmerList() {

    this.BusinessDetail.getCustomer(this.businessID,).subscribe(response => {
      if (response.code === 'DAO_SUCCESS_00') {
        this.customers = response.responseData.Data;
      }
      console.log(this.customers);
    })
  }

  opencontacttab() {
    if (this.directorDataArray.length === 0) {
      console.log(this.busineesroles1.controls)
      this.submitted = true;
      if (this.busineesroles1.invalid) {
        return;
      }
    }

    if (this.directorDataArray.length != 0) {
      const transformedArray: any[] = [];

      // Iterate through the inputArray and transform each item
      this.directorDataArray.forEach(item => {
        const directorDataItem: any = {
          businessId: this.businessID,
          firstName: item.firstname,
          lastName: item.lastname,
          nationality: item.nationality,
          email: item.email,
          phone: item.mobnum,
          placeofBirth: item.place,
          accountVo: {
            id: this.accountID
          },
          status: 0,
          dateOfBirth: item.birth,
          customerAddressVo: {
            line1: item.street,
            line2: "",
            city: item.city,
            postalCode: item.zip,
            state: null,
            country: item.country,
          },
          customerDocumentDetailsVo: [{
            docType: item.Iddoc,
            docNumber: item.docnum,
            docCountry: item.docissuing,
            expiryDate: item.expdate
          }],
          taxIdentificationNo: item.taxnum,
          multiCitizenship: item.citizenship == 'no' ? 0 : 1,
          exposedPerson: item.persons == 'no' ? 0 : 1,
          designation: item.position,
          benfActNominee: item.nominee == 'no' ? 0 : 1,
          benfActTrustee: item.trustee == 'no' ? 0 : 1,
          benfUSAPerson: item.usaPerson == 'no' ? 0 : 1,
          isAuthorisedSignatory: 0,
          isDirector: 1,
          isShareHolder: 0,
          isUbo: 0,
          businessPartners: 0,
          taxCountry: item.countryone,
          type: "individual",
        };
        // Push the transformed item to the result array
        transformedArray.push(directorDataItem);
      });

      this.directorPayload = transformedArray;
      console.log(this.directorPayload);

      this.BusinessDetail.businessRoles(this.directorPayload).subscribe(response => {
        console.log(response);
        if (response.code == "DAO_SUCCESS_00") {
          setTimeout(() => {
            this.getCustmerList()
          }, 200);
          this.contacttab.nativeElement.click();
        }
      })
    }
  }

  routeToUboTab() {
    if (this.shareholderDataArray.length === 0) {
      console.log(this.busineesroles2.controls)
      this.submitted = true;
      if (this.busineesroles2.invalid) {
        return;
      }
    }

    if (this.shareholderDataArray.length != 0) {
      const transformedArray: any[] = [];

      // Iterate through the inputArray and transform each item
      this.shareholderDataArray.forEach(item => {
        const shareDataItem: any = {
          businessId: this.businessID,
          firstName: item.firstname,
          lastName: item.lastname,
          nationality: item.nationality,
          email: item.email,
          phone: item.mobnum,
          placeofBirth: item.place,
          accountVo: {
            id: this.accountID
          },
          status: 0,
          dateOfBirth: item.birth,
          customerAddressVo: {
            line1: item.street,
            line2: "",
            city: item.city,
            postalCode: item.zip,
            state: null,
            country: item.country,
          },
          customerDocumentDetailsVo: [{
            docType: item.Iddoc,
            docNumber: item.docnum,
            docCountry: item.docissuing,
            expiryDate: item.expdate
          }],
          taxIdentificationNo: item.taxnum,
          multiCitizenship: item.citizenship == 'no' ? 0 : 1,
          exposedPerson: item.persons == 'no' ? 0 : 1,
          designation: item.position,
          benfActNominee: item.nominee == 'no' ? 0 : 1,
          benfActTrustee: item.trustee == 'no' ? 0 : 1,
          benfUSAPerson: item.usaPerson == 'no' ? 0 : 1,
          isAuthorisedSignatory: 0,
          isDirector: 0,
          isShareHolder: 1,
          isUbo: 0,
          businessPartners: 0,
          taxCountry: item.countryone,
          type: "individual",
        };
        // Push the transformed item to the result array
        transformedArray.push(shareDataItem);
      });

      this.shareDataPayload = transformedArray;
      console.log(this.shareDataPayload);
      this.BusinessDetail.businessRoles(this.shareDataPayload).subscribe(response => {
        console.log(response);
        if (response.code == "DAO_SUCCESS_00") {
          setTimeout(() => {
            this.getCustmerList()
          }, 200);
          this.ubotab.nativeElement.click();
        }
      })
    }
  }
  addBusinessAddress() {
    this.submitted = true;
    if (this.busineesroles.invalid) {
      return;
    }
  }

  setFormValues(item: any, index: any) {
    console.log("index", index);
    console.log("busineesroles")
    this.busineesroles.setValue({
      lastname: item.lastname,
      firstname: item.firstname,
      birth: item.birth,
      // personalcode: item.personalcode,
      place: item.place,
      nationality: item.nationality,
      Iddoc: item.Iddoc,
      docnum: item.docnum,
      docissuing: item.docissuing,
      expdate: item.expdate,
      mobnum: item.mobnum,
      email: item.email,
      country: item.country,
      city: item.city,
      street: item.street,
      zip: item.zip,
      countryone: item.countryone,
      taxnum: item.taxnum,
      persons: item.persons,
      citizenship: item.citizenship,
      position: item.position,
      nominee: item.nominee,
      trustee: item.trustee,
      usaPerson: item.usaPerson,
      type: "individual",
    });
    this.editingIndex = index;
  }

  setDirectorValues(item: any, index: any) {
    console.log("index", index);
    console.log("busineesroles1")
    this.busineesroles1.setValue({
      lastname: item.lastname,
      firstname: item.firstname,
      birth: item.birth,
      // personalcode: item.personalcode,
      place: item.place,
      nationality: item.nationality,
      Iddoc: item.Iddoc,
      docnum: item.docnum,
      docissuing: item.docissuing,
      expdate: item.expdate,
      mobnum: item.mobnum,
      email: item.email,
      country: item.country,
      city: item.city,
      street: item.street,
      zip: item.zip,
      countryone: item.countryone,
      taxnum: item.taxnum,
      citizenship: item.citizenship,
      persons: item.persons,
      position: item.position,
      nominee: item.nominee,
      trustee: item.trustee,
      usaPerson: item.usaPerson,
      type: "individual",
    });

    this.directorIndex = index;
  }

  setShareValues(item: any, index: any) {
    console.log("index", index);
    console.log("busineesroles2")
    this.busineesroles2.setValue({
      lastname: item.lastname,
      firstname: item.firstname,
      birth: item.birth,
      // personalcode: item.personalcode,
      place: item.place,
      nationality: item.nationality,
      Iddoc: item.Iddoc,
      docnum: item.docnum,
      docissuing: item.docissuing,
      expdate: item.expdate,
      mobnum: item.mobnum,
      email: item.email,
      country: item.country,
      city: item.city,
      street: item.street,
      zip: item.zip,
      countryone: item.countryone,
      taxnum: item.taxnum,
      // type      : item.type,
      person: item.person,
      citizenship: item.citizenship,
      position: item.position,
      persons: item.persons,
      nominee: item.nominee,
      trustee: item.trustee,
      usaPerson: item.usaPerson,
      type: "individual",
    });
    this.shareIndex = index;
  }

  setUboValues(item: any, index: any) {
    console.log("index", index);
    console.log("busineesroles4")
    this.busineesroles4.setValue({
      lastname: item.lastname,
      firstname: item.firstname,
      birth: item.birth,
      // personalcode: item.personalcode,
      place: item.place,
      nationality: item.nationality,
      Iddoc: item.Iddoc,
      docnum: item.docnum,
      docissuing: item.docissuing,
      expdate: item.expdate,
      mobnum: item.mobnum,
      email: item.email,
      country: item.country,
      city: item.city,
      street: item.street,
      zip: item.zip,
      countryone: item.countryone,
      taxnum: item.taxnum,
      person: item.person,
      citizenship: item.citizenship,
      position: item.position,
      nominee: item.nominee,
      persons: item.persons,
      trustee: item.trustee,
      usaPerson: item.usaPerson,
      type: "individual",
    });
    this.UboIndex = index;
  }

  deleteRecord(idToDelete: number): void {
    let record = this.formDataArray
    if (idToDelete >= 0 && idToDelete < record.length) {
      record.splice(idToDelete, 1);
    }
  }

  deleteRecord1(idToDelete: number): void {
    let record = this.directorDataArray
    if (idToDelete >= 0 && idToDelete < record.length) {
      record.splice(idToDelete, 1);
    }
  }

  deleteRecord2(idToDelete: number): void {
    let record = this.UboDataArray
    if (idToDelete >= 0 && idToDelete < record.length) {
      record.splice(idToDelete, 1);
    }
  }

  deleteRecord3(idToDelete: number): void {
    let record = this.shareholderDataArray
    if (idToDelete >= 0 && idToDelete < record.length) {
      record.splice(idToDelete, 1);
    }
  }

  refreshForm() {
    this.busineesroles1.reset(); // Form ko reset karein
  }
  refreshshareholder() {
    this.busineesroles2.reset(); // Form ko reset karein
  }

  uboForm() {
    this.busineesroles4.reset(); // Form ko reset karein
  }

  getSingleCustomer() {
    // const customerId = event.target.value;
    const customerIndex = this.selectedOption;
    console.log(customerIndex);
  }

  getCountryId(value: String) {
    this.countries.map((i, v) => {
      if (i.name == value) {
        value = i.id;
      }

    })
    return value;
  }


  getdocumentsType(value: String) {
    this.documentsType.map((i, v) => {
      if (i.name == value) {
        value = i.id;
      }

    })
    return value;
  }

  fetchCustomerData(value: string) {
    this.directorTabCustomer = value;
    this.selectedCustomerData = this.getSelectedCustomerByFilter(this.directorTabCustomer)[0]
    console.log( this.selectedCustomerData);
    
    this.busineesroles1.patchValue({
      firstname: this.selectedCustomerData.firstName,
      lastname: this.selectedCustomerData.lastName,
      birth: formatDate(this.selectedCustomerData.dateOfBirth, "yyyy-MM-dd", "en-US"),
      // personalcode: this.selectedCustomerData.personalcode,
      place: this.getCountryId(this.selectedCustomerData.placeofBirth),
      nationality: this.getCountryId(this.selectedCustomerData.nationality),
      Iddoc: this.selectedCustomerData.customerDocumentDetailsVo[0].docType ?? null,
      docnum: this.selectedCustomerData.customerDocumentDetailsVo[0].docNumber ?? null,
      docissuing: this.getCountryId(this.selectedCustomerData.customerDocumentDetailsVo[0].docCountry ?? null),
      expdate: formatDate(this.selectedCustomerData.customerDocumentDetailsVo[0].expiryDate ?? null, "yyyy-MM-dd", "en-US"),
      mobnum: this.selectedCustomerData.phone,
      email: this.selectedCustomerData.email,
      country: this.getCountryId(this.selectedCustomerData.customerAddressVo.country),
      city: this.selectedCustomerData.customerAddressVo.city,
      street: this.selectedCustomerData.customerAddressVo.line1,
      zip: this.selectedCustomerData.customerAddressVo.postalCode,
      countryone: this.getCountryId(this.selectedCustomerData.taxCountry),
      taxnum: this.selectedCustomerData.taxIdentificationNo,
      citizenship: this.selectedCustomerData.multiCitizenship == null ? 'no' : 'yes',
      position: this.selectedCustomerData.designation,
      persons: this.selectedCustomerData.exposedPerson == null ? 'no' : 'yes',
      nominee: this.selectedCustomerData.benfActNominee == null ? 'no' : 'yes',
      trustee: this.selectedCustomerData.benfActTrustee == null ? 'no' : 'yes',
      usaPerson: this.selectedCustomerData.benfUSAPerson == null ? 'no' : 'yes',

      // add remaining formCOntrols here
    })


  }
  getSelectedCustomerByFilter(id: any) {
    console.log('customer ID', id);
    return this.customers.filter((item) => item.id == id);
  }
  getshareHolderByFilter(id: any) {
    console.log('customer ID', id);
    return this.customers.filter((item) => item.id == id);
  }

  getshareUboByFilter(id: any) {
    console.log('customer ID', id);
    return this.customers.filter((item) => item.id == id);
  }

  fetchshareholderData(value: string) {
    this.shareholderTabCustomer = value;
    this.selectedCustomerData = this.getshareHolderByFilter(this.shareholderTabCustomer)[0]
    console.log(this.selectedCustomerData);

    this.busineesroles2.patchValue({
      firstname: this.selectedCustomerData.firstName,
      lastname: this.selectedCustomerData.lastName,
      birth: formatDate(this.selectedCustomerData.dateOfBirth, "yyyy-MM-dd", "en-US"),
      // personalcode: this.selectedCustomerData.personalcode,
      place: this.getCountryId(this.selectedCustomerData.placeofBirth),
      nationality: this.getCountryId(this.selectedCustomerData.nationality),
      Iddoc: this.selectedCustomerData.customerDocumentDetailsVo[0].docType ?? null,
      docnum: this.selectedCustomerData.customerDocumentDetailsVo[0].docNumber ?? null,
      docissuing: this.getCountryId(this.selectedCustomerData.customerDocumentDetailsVo[0].docCountry ?? null),
      expdate: formatDate(this.selectedCustomerData.customerDocumentDetailsVo[0].expiryDate ?? null, "yyyy-MM-dd", "en-US"),
      mobnum: this.selectedCustomerData.phone,
      email: this.selectedCustomerData.email,
      country: this.getCountryId(this.selectedCustomerData.customerAddressVo.country),
      city: this.selectedCustomerData.customerAddressVo.city,
      street: this.selectedCustomerData.customerAddressVo.line1,
      zip: this.selectedCustomerData.customerAddressVo.postalCode,
      countryone: this.getCountryId(this.selectedCustomerData.taxCountry),
      taxnum: this.selectedCustomerData.taxIdentificationNo,
      citizenship: this.selectedCustomerData.multiCitizenship == null ? 'no' : 'yes',
      position: this.selectedCustomerData.designation,
      persons: this.selectedCustomerData.exposedPerson == null ? 'no' : 'yes',
      nominee: this.selectedCustomerData.benfActNominee == null ? 'no' : 'yes',
      trustee: this.selectedCustomerData.benfActTrustee == null ? 'no' : 'yes',
      usaPerson: this.selectedCustomerData.benfUSAPerson == null ? 'no' : 'yes',

      // customer : this.selectedCustomerData.customer

      // add remaining formCOntrols here
    })

  }


  fetchUboData(value: string) {
    this.uboTabCustomer = value;
    this.selectedCustomerData = this.getSelectedCustomerByFilter(this.uboTabCustomer)[0]
    console.log(this.selectedCustomerData);

    this.busineesroles4.patchValue({
      firstname: this.selectedCustomerData.firstName,
      lastname: this.selectedCustomerData.lastName,
      birth: formatDate(this.selectedCustomerData.dateOfBirth, "yyyy-MM-dd", "en-US"),
      // personalcode: this.selectedCustomerData.personalcode,
      place: this.getCountryId(this.selectedCustomerData.placeofBirth),
      nationality: this.getCountryId(this.selectedCustomerData.nationality),
      Iddoc: this.selectedCustomerData.customerDocumentDetailsVo[0]?.docType ?? null,
      docnum: this.selectedCustomerData.customerDocumentDetailsVo[0]?.docNumber ?? null,
      docissuing: this.getCountryId(this.selectedCustomerData.customerDocumentDetailsVo[0]?.docCountry ?? null),
      expdate: formatDate(this.selectedCustomerData.customerDocumentDetailsVo[0]?.expiryDate ?? null, "yyyy-MM-dd", "en-US"),
      mobnum: this.selectedCustomerData.phone,
      email: this.selectedCustomerData.email,
      country: this.getCountryId(this.selectedCustomerData.customerAddressVo.country),
      city: this.selectedCustomerData.customerAddressVo.city,
      street: this.selectedCustomerData.customerAddressVo.line1,
      zip: this.selectedCustomerData.customerAddressVo.postalCode,
      countryone: this.getCountryId(this.selectedCustomerData.taxCountry),
      taxnum: this.selectedCustomerData.taxIdentificationNo,
      citizenship: this.selectedCustomerData.multiCitizenship == null ? 'no' : 'yes',
      position: this.selectedCustomerData.designation,
      persons: this.selectedCustomerData.exposedPerson == null ? 'no' : 'yes',
      nominee: this.selectedCustomerData.benfActNominee == null ? 'no' : 'yes',
      trustee: this.selectedCustomerData.benfActTrustee == null ? 'no' : 'yes',
      usaPerson: this.selectedCustomerData.benfUSAPerson == null ? 'no' : 'yes',
      // add remaining formCOntrols here
    })

  }

  onUsaChange() {
    let val = this.busineesroles.controls.usaPerson.value;
    if (val === "yes") {
      this.isYesSelected = true;
    } else {
      this.isYesSelected = false;
    }
  }

  onDirectorUsaChange() {
    let val = this.busineesroles1.controls.usaPerson.value;
    if (val === "yes") {
      this.isDirecotUSASelected = true;
    } else {
      this.isDirecotUSASelected = false;
    }
  }

  onShareHolderUsaChange() {
    let val = this.busineesroles2.controls.usaPerson.value;
    if (val === "yes") {
      this.isShareHolderUSASelected = true;
    } else {
      this.isShareHolderUSASelected = false;
    }
  }

  onUboUsaChange() {
    let val = this.busineesroles4.controls.usaPerson.value;
    if (val === "yes") {
      this.isUboUSASelected = true;
    } else {
      this.isUboUSASelected = false;
    }
  }

  Previous() {
    this.router.navigate(['/businessDocuments']);
  }

  homePrevious() {
    // this.router.navigate(['/homeTab']);
  }

  addBusinessShare() {
    console.log(this.BusinessShare.controls)
    this.submittedShare = true;
    if (this.BusinessShare.invalid) {
      return;
    } else {
      console.log(this.BusinessShare);
      // Iterate through the inputArray and transform each item
      const shareDataItem: any = {
        businessId: this.businessID,
        firstName: this.BusinessShare.value.corporateFullName,
        nationality: this.BusinessShare.value.countryIncorporations,
        phone: this.BusinessShare.value.phoneNumbers,
        accountVo: {
          id: this.accountID
        },
        status: 0,
        dateOfBirth: this.BusinessShare.value.dateincorporation,
        multiCitizenship: 0,
        exposedPerson: 0,
        benfActNominee: 0,
        benfActTrustee: 0,
        benfUSAPerson: 0,
        isAuthorisedSignatory: 0,
        isDirector: 0,
        isShareHolder: 1,
        isUbo: 0,
        businessPartners: 0,
        type: "Business",
      };
      let body = { customer: shareDataItem };
      this.BusinessDetail.individualBusiness(body).subscribe(res => {
        if (res.code == "DAO_SUCCESS_00") { }
        console.log(res);
        this.ubotab.nativeElement.click();
      })
    }
  }

  addBusinessDirector() {
    console.log(this.BusinessDirector.controls)
    this.submittedbusiness = true;
    if (this.BusinessDirector.invalid) {
      return;
    } else {
      const shareDataItem: any = {
        businessId: this.businessID,
        firstName: this.BusinessDirector.value.corporateFullName,
        nationality: this.BusinessDirector.value.countryIncorporations,
        phone: this.BusinessDirector.value.phoneNumbers,
        accountVo: {
          id: this.accountID
        },
        status: 0,
        dateOfBirth: this.BusinessDirector.value.dateincorporation,
        multiCitizenship: 0,
        exposedPerson: 0,
        benfActNominee: 0,
        benfActTrustee: 0,
        benfUSAPerson: 0,
        isAuthorisedSignatory: 0,
        isDirector: 1,
        isShareHolder: 0,
        isUbo: 0,
        businessPartners: 0,
        type: "Business",
      };
      let body = { customer: shareDataItem };
      this.BusinessDetail.individualBusiness(body).subscribe(res => {
        if (res.code == "DAO_SUCCESS_00") { }
        console.log(res);
        this.contacttab.nativeElement.click();
      })
    }
  }

}

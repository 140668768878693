<div class="dyEAXN">
    <!-- RIGHT PANEL-->
    <div class="bCDrPV djdIYm">
        <div class="jYRwVK">
            <!-- FORM CONTENT-->
            <div class="hnZgAZ">
                <div class="husZCp">
                    <h1 data-component="heading"
                        class="styles__Root-sc-1myek4t-0 fdExrC Heading__Head-junqdu-1 lgVgrq">Selfie Photo Verification</h1>
                    <h2 data-component="subheading"
                        class="styles__Root-sc-1myek4t-0 cclCHJ Heading__SubHead-junqdu-2 goFGMk">Please be close to the camera to take a clear image for verification</h2>
                </div>
            </div>

            <form>
                <div class="row">
                    <webcam [height]="500" [width]="500" [trigger]="triggerObservable"
                        (imageCapture)="handleImage($event)" *ngIf="showWebcam"
                        [allowCameraSwitch]="allowCameraSwitch" [imageQuality]="1"
                        (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)">
                    </webcam>

                    <div class="parent" *ngIf="webcamImage">
                        <img [src]="webcamImage.imageAsDataUrl" />
                    </div>

                </div>

                <div class="eBKYjJ cgMkag">
                    <button data-component="primary-button" type="submit" role="button" tabindex="0"
                        class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 epjTBn"
                        (click)="triggerSnapshot();">{{snapshot_label}}</button>
                    &nbsp;
                    <button data-component="primary-button" type="submit" role="button" tabindex="0"
                        class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 epjTBn"
                        *ngIf="webcamImage" (click)="addSelfie()" >Upload</button>
                </div>

            </form>

        </div>
        <!--FORM CONTENT -->


    </div>

</div> <!-- RIGHT PANEL-->
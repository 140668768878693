import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundButtonComponent } from './radio-button/component/radio-button.component';
import { SidebarComponent } from './sidebar/component/sidebar.component';
import { LoginFooterComponent } from './login-footer/component/login-footer.component';
import { WalletCardComponent } from './wallet-card/component/wallet-card.component';
import { FormsModule } from '@angular/forms';
import { CardContainerComponent } from './card-container/component/card-container.component';
import { PopupComponent } from './popup/component/popup.component';
import { TabComponent } from './Tab/component/tab.component';
import { LoginTabsComponent } from './login-tabs/component/login-tabs.component';
import { DropdownWithIconComponent } from './dropdown-with-icon/component/dropdown-with-icon.component';
import { StepperComponent } from './stepper/stepper/stepper.component';
import { CardLargeComponent } from './card-large/card-large.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    RoundButtonComponent,
    TabComponent,
    SidebarComponent,
    LoginFooterComponent,
    WalletCardComponent,
    CardContainerComponent,
    PopupComponent,
    LoginTabsComponent,
    DropdownWithIconComponent,
    StepperComponent,
    CardLargeComponent
  ],

  exports: [
    RoundButtonComponent,
    TabComponent,
    SidebarComponent,
    LoginFooterComponent,
    WalletCardComponent,
    CardContainerComponent,
    PopupComponent,
    LoginTabsComponent,
    DropdownWithIconComponent,
    StepperComponent,
    CardLargeComponent,
  ]
})
export class UIModule { }


<div class="main-bg-login">
    <div class="col-lg-6">
      <sidebar></sidebar>
    </div>
    <div class="col-lg-6">
      <app-stepper></app-stepper>
    </div>
    <div>
      <router-outlet></router-outlet>
      <ngx-ui-loader></ngx-ui-loader>
    </div>
</div>

<login-footer></login-footer>


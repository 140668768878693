import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  registrationDate : any

  constructor() { }

  setData(data: any) {
    this.registrationDate = data
  }

  getData() {
    return this.registrationDate
  }

  // clearLocalStorage(event: String) {
  //   localStorage.clear();
  // }

}

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Data } from 'src/app/providers/data';
import { BusinessCustomer } from '../model/businessCustomer';
import { select, Store } from '@ngrx/store';
import { addPersonalDoc } from '../store/action/personalDocumentactions';
import { Business } from 'src/app/business-details/model/business';
import { Document } from 'src/app/business-details/model/document';
import { FormBuilder, Validators } from '@angular/forms';
import { PersonalDetailService } from '../service/personal-detail.service';
import { SharedService } from 'src/app/shared/shared.service';
import { first } from 'lodash';
declare var $: any;

class ImageSnippet {
  constructor(public src: string, public file: File, private sharedDatta: SharedService) { }
}

@Component({
  selector: 'personal-doctype',
  templateUrl: './personal-doctype.component.html',
  styleUrls: ['./personal-doctype.component.scss']
})


export class PersonalDocumentTypeComponent implements OnInit {
  [x: string]: any;
  public business: Business;
  selectedFile: ImageSnippet;
  public document1: string = null;
  public submitted = false;
  public customer: BusinessCustomer;
  public userDocument: any;
  countries: any;
  states: any;
  cities: any;
  informationForm: any;
  isYesSelected: boolean = false;
  transactionAll: any;
  sharedDattaone: any;
  employeenum: any;
  shareOfSourceCapital: any;
  monthlyTurnoverAll: any;

  constructor(private router: Router, private data: Data, private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private sharedDataone: SharedService,
    private personalDetailService: PersonalDetailService, private store: Store<Document>) {
    this.route.data.subscribe(response => {
      let res = response[0]?.countries;
      if (res?.code === 'DAO_SUCCESS_00') {
        this.countries = res.responseData.countries;

      }
      console.log(this.countries);

    });

    this.route.data.subscribe(response => {
      console.log(response);

      let res = response[0]?.transactionAll;
      if (res?.code === 'DAO_SUCCESS_00') {
        this.transactionAll = res.responseData.Data;
      }
      console.log("waleed", this.transactionAll);

    });

    this.route.data.subscribe(response => {
      console.log(response);

      let res = response[0]?.employeenum;
      console.log("res", res);

      if (res?.code === 'DAO_SUCCESS_00') {

        this.employeenum = res.responseData.Data;
      }
      console.log("raju", this.employeenum);

    });

    this.route.data.subscribe(response => {
      console.log(response);

      let res = response[0]?.businessActivityAll;
      console.log("res", res);

      if (res?.code === 'DAO_SUCCESS_00') {
        this.businessActivityAll = res.responseData.Data;
      }
      console.log("rajukz", this.businessActivityAll);

    });

    this.route.data.subscribe(response => {
      console.log(response);

      let res = response[0]?.shareOfSourceCapital;
      console.log("res", res);

      if (res?.code === 'DAO_SUCCESS_00') {
        this.shareOfSourceCapital = res.responseData.Data;
      }
      console.log("rajukhanzada", this.shareOfSourceCapital);

    });

    this.route.data.subscribe(response => {
      console.log(response);

      let res = response[0]?.monthlyTurnoverAll;
      console.log("res", res);

      if (res?.code === 'DAO_SUCCESS_00') {
        this.monthlyTurnoverAll = res.responseData.Data;
      }
      console.log("rajukhanzada", this.monthlyTurnoverAll);

    });



  }


  ngOnInit() {
    // this.customer=this.data.storage.businessInformation.customer[0];
    // this.business=this.data.storage.businessInformation; 
  }

  passportForm = this.formBuilder.group({
    tax: ['', Validators.required],
    employees: ['', Validators.required],
    industry: ['', Validators.required],
    tax1: ['', Validators.required],
    annual: ['', Validators.required],
    indicate: ['', Validators.required],
    source: ['', Validators.required],
    specify: ['', Validators.required],
    activity: ['', Validators.required],
    estimated: ['', Validators.required],
    monthly: ['', Validators.required],
    transactions: ['', Validators.required],
    regions: ['', Validators.required],
    passiveIncome: ['',],

    tradingName: ['no']
  });
  get f() { return this.passportForm.controls };


  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      // this.selectedFile = new ImageSnippet(event.target.result, file);
      this.sendImageApi(this.selectedFile.file);
    });
    reader.readAsDataURL(file);
  }

  // sendImageApi(file) {
  //   this.personalDetailService.sendPersonalDocument(file).subscribe((result) => {
  //     if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
  //       this.document1 = result.responseData.fileID;
  //       let arrayDoc = []
  //       let passport = new Document(null, "Document", "Passport", this.document1, 1);
  //       arrayDoc.push(passport);
  //       this.userDocument = arrayDoc;
  //       this.store.dispatch(addPersonalDoc(this.userDocument));
  //     }
  //     else {
  //       // this.isError = true;
  //     }
  //   }, error => { console.log(error) })
  // }

  addUserDocument() {
    console.log("click work");
    console.log(this.passportForm.controls);
    this.submitted = true;
    if (this.passportForm.invalid) {
      return;
    }
    this.sharedDataone.setData(this.passportForm.getRawValue());
    this.router.navigate(['/personalDocuments'])

    this.passportForm.get('country')?.valueChanges.subscribe(val => {
      this.fetchStates(val);
    });

  }


  fetchCities(stateId) {
    this.states.map((x: { id: any; cityVos: any; }) => {
      if (x.id == stateId) {
        this.cities = x.cityVos;
      }
    })
  }
  fetchStates(countryId) {
    this.countries.map(x => {
      if (x.id == countryId) {
        this.states = x.stateVos;
      }
    })
  }

  selectTradingName() {
    let val = this.passportForm.controls.tradingName.value;
    if (val === "yes") {
      this.isYesSelected = true;
    } else {
      this.isYesSelected = false;
    }
  }
  onPrevious() {
    this.router.navigate(['/personalAddress'])
  }

}
import {Action, createReducer, on} from '@ngrx/store';
import * as selfieDocumentactions from '../action/selfieDocumentactions';


export const selfieDocumentFeatureKey = 'selfieDocument';

export interface SelfieDocumentState {
  selfieDocument: {};
}

export const initialState: SelfieDocumentState = {
  selfieDocument: {}
};

export const selfieDocumentReducer = createReducer(
  initialState,
  on(selfieDocumentactions.addSelfieDoc,
    (state: SelfieDocumentState, {selfieDocument}) =>
      ({...state,
        selfieDocument,
      }))
);

export function SelfieDocumentReducer(state: SelfieDocumentState | undefined, action: Action): any {
  return selfieDocumentReducer(state, action);
}

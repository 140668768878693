import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { Businessreducer } from '../business-details/store/reducer/business.reducer';
// import { BusinessAddessReducer } from '../business-details/store/reducer/businessAddress.reducer';


export interface State {
}

export const reducers: ActionReducerMap<State> = {
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

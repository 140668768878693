<div>
  <ul class="tabs">
    <li class="tab">
      <a active href="#test1">
        <round-button image={{imageOne}} label={{labelOne}}></round-button>
      </a>
    </li>
    <li class="tab">
      <a href="#test2">
        <round-button image={{imageTwo}} label={{labelTwo}}></round-button>
      </a>
    </li>
  </ul>
</div>
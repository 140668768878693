<app-card-large>
  <div class="dyEAXN">
    <!-- RIGHT PANEL-->
    <div class="bCDrPV djdIYm">
      <div class="mjYRwVK">
        <div class="main-panel__content">
          <h1 class="main-heading nonreferral">
            Business Document Information
          </h1>
          <h2 class="main-sub-heading">
            Please upload all the required documents
          </h2>
        </div>

        <!-- <br> -->

        <form class="col s12" name="f" [formGroup]="accountItemsForm">
          <div formArrayName="accountVos">
            <div
              class="main-div"
              *ngFor="
                let detail of accountItemsForm.get('accountVos')?.controls;
                let i = index
              "
            >
              <ng-container [formGroupName]="i">
                <!-- {{detail.get('email').errors | json}} -->
                <div class="row">
                  <div class="input-field col s4">
                    <label
                      data-component="form__label"
                      class="form__label"
                      for="name"
                      >Country<span style="color: red">*</span></label
                    >
                    <div class="select-wrapper">
                      <select
                        id="country"
                        formControlName="country"
                        [ngClass]="{
                          invalid: submitted && detail.get('country').errors
                        }"
                        class="fts-select-small"
                      >
                        <option disabled selected>Select your country</option>
                        <option
                          class=""
                          *ngFor="let countrty of countries"
                          [value]="countrty.id"
                        >
                          {{ countrty.name }}s
                        </option>
                      </select>
                    </div>

                    <div
                      *ngIf="submitted && detail.get('country').errors"
                      class="text-danger"
                    >
                      Country is required
                    </div>
                  </div>
                  <div class="input-field col s4">
                    <label for="team_size" class="form__label"
                      >Identification number<span style="color: red"
                        >*</span
                      ></label
                    >
                    <input
                      id="name"
                      formControlName="identificationNo"
                      placeholder="XXXXXX"
                      [ngClass]="{
                        invalid:
                          submitted && detail.get('identificationNo').errors
                      }"
                      type="text"
                      class="fts-input-field input-width"
                    />
                    <div
                      *ngIf="submitted && detail.get('identificationNo').errors"
                      class="text-danger"
                    >
                      Identification number is required
                    </div>
                  </div>
                </div>

                <div class="input-field col-1">
                  <a
                    (click)="removeItemDetail(i)"
                    class="mb-6 btn-floating waves-effect waves-light red accent-2"
                  >
                    <img src="../../../assets/x-circle.svg" alt="" />
                    <!-- <i class="material-icons">clear</i> -->
                  </a>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="row" style="margin-right: 10px">
            <div class="col m10 s10">
              <label class="form__label"
                >Add identification number<a
                  (click)="addItemDetail()"
                  style="right: 550px"
                  class="mb-6 btn-floating waves-effect waves-light right red accent-2"
                >
                  <img src="../../../assets/plus-circle.svg" alt="" />
                  <!-- <i class="material-icons">add</i> -->
                </a></label
              >
            </div>
          </div>
        </form>

        <form name="m" [formGroup]="documentsForm">
          <div class="row-one" style="margin-top: 10px">
            <div class="input-field col s6" style="margin-top: 5px">
              <label for="team_size" class="form__label"
                >What is your connection lithuanaia<span style="color: red"
                  >*</span
                ></label
              >
              <select
                name="team_size"
                id="team_size"
                formControlName="lithuanaia"
                class="fts-select"
              >
                <option value disabled selected>
                  What is your business lithuanaia
                </option>
                <option
                  *ngFor="let industry of industries"
                  [value]="industry.id"
                >
                  {{ industry.name }}
                </option>
              </select>
            </div>
            <div *ngIf="submitted2 && m.lithuanaia.errors" class="text-danger">
              <div *ngIf="m.lithuanaia.errors.required">
                lithuanaia is required
              </div>
              <div *ngIf="m.lithuanaia.errors.minlength">
                Required atleast 6 characters
              </div>
            </div>
          </div>

          <div class="row-one" style="margin-top: 10px">
            <div class="input-field col s6" style="margin-top: 5px">
              <label for="team_size" class="form__label"
                >Purpose of the account opening with payswix
                <span style="color: red">*</span></label
              >
              <div class="form-floating">
                <textarea
                  class="fts-input-field register-w"
                  placeholder="Leave a comment here"
                  formControlName="Purpose"
                  id="floatingTextarea"
                ></textarea>
              </div>
            </div>
            <div *ngIf="submitted2 && m.Purpose.errors" class="text-danger">
              <div *ngIf="m.Purpose.errors.required">Purpose is required</div>
              <div *ngIf="m.Purpose.errors.minlength">
                Required atleast 6 characters
              </div>
            </div>
          </div>

          <div
            class="d-flex"
            style="margin-top: 10px; justify-content: space-between"
          >
            <span for="team_size" class="form__label">
              Does the company holds account(s) in others bank(s) Or payments
              service providers<span style="color: red">*</span>
            </span>
            <div class="radio-btn">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault9"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  YES
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault8"
                  checked
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  NO
                </label>
              </div>
            </div>
          </div>
        </form>

        <!-- <form name="f" [formGroup]="documentsForm">
          <div class="form__group">
            <div class="form__group">
              <label
                data-component="form-input-email-label"
                for="email"
                class="form__label"
                >Proof of Business Registration</label
              >
              <input
                type="file"
                placeholder=""
                #imageInput
                (change)="processFile(imageInput)"
                formControlName="businessRegistrationDocument"
                [ngClass]="{
                  invalid: submitted && f.businessRegistrationDocument.errors
                }"
                class="form__input validate-empty"
                value="businessRegistrationDocument"
              />
              <div
                *ngIf="submitted && f.businessRegistrationDocument.errors"
                class="text-danger"
              >
                <div *ngIf="f.businessRegistrationDocument.errors.required">
                  Proof of Business Registration is required
                </div>
              </div>
              <div class="p-text">
                Note: Only JPEGs, PNGs or PDFs with a file size of max. 2MB are
                allowed
              </div>
            </div>
          </div>
          <div data-component="form-input-name" class="form__group">
            <div class="form__group">
              <label
                data-component="form-input-name-label"
                for="name"
                class="form__label"
                >Proof of National Tax Number</label
              >
              <input
                #imageInputs
                type="file"
                (change)="processFile(imageInputs)"
                formControlName="ntnDocument"
                value="ntnDocument"
                [ngClass]="{ invalid: submitted && f.ntnDocument.errors }"
                class="form__input validate-empty"
              />
              <div
                *ngIf="submitted && f.ntnDocument.errors"
                class="text-danger"
              >
                <div *ngIf="f.ntnDocument.errors.required">
                  Proof of National Tax Number is required
                </div>
              </div>
              <div class="p-text">
                Note: Only JPEGs, PNGs or PDFs with a file size of max. 2MB are
                allowed
              </div>
            </div>
          </div>
          <div class="row">
            <div *ngIf="bDocument1" class="input-field col s4">
              <label for="bDocument1" class="active">Document 1</label>
              <img
                id="myimage"
                src="{{ imageUrl }}file/view/{{ bDocument1 }}"
              />
              
            </div>
            <div *ngIf="bDocument2" class="input-field col s4">
              <label for="bDocument2" class="active">Document 2</label>
              <img
                id="myimage"
                src="{{ imageUrl }}file/view/{{ bDocument2 }}"
              />
              
            </div>
          </div>

          <div class="primary-back-continue">
            <button
              data-component="primary-button"
              type="submit"
              role="button"
              tabindex="0"
              (click)="onPrevious()"
              class="fts-button-tertiary"
            >
              Previous
            </button>

            <button
              data-component="primary-button"
              type="submit"
              role="button"
              tabindex="0"
              class="fts-button-primary"
              (click)="addBusinessDocument(mymodal2)"
            >
              Save
            </button>
          </div>
        </form> -->
      </div>
      <!--FORM CONTENT -->
    </div>
    <div class="primary-back-continue">
      <button
        data-component="primary-button"
        type="submit"
        role="button"
        tabindex="0"
        (click)="onPrevious()"
        class="fts-button-tertiary"
      >
        Previous
      </button>

      <button
        data-component="primary-button"
        type="submit"
        role="button"
        tabindex="0"
        class="fts-button-primary"
        (click)="addBusinessDocument()"
      >
        Save
      </button>
    </div>
  </div>
  <!-- RIGHT PANEL-->

  <ng-template #mymodal2 let-modal>
    <div class="bgBody">
      <img
        class="image"
        src="http://54.216.113.184:8080/dao_business.png"
        alt="success"
        style="width: 100px; height: 100px"
      />
      <p>Thanks for providing business details!</p>
      <p>Let's get your personal information.</p>
      <div class="centered">
        <button
          value="success"
          class="button button1"
          (click)="modal.close('Save click')"
        >
          Continue
        </button>
      </div>
    </div>
  </ng-template>
</app-card-large>

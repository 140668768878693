import { Component, Input } from '@angular/core';

@Component({
  selector: 'round-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.css']
})

export class RoundButtonComponent {

  @Input() public image:string;
  @Input() public label:string;

  constructor() {
    this.image = '';
    this.label = '';
  }
}

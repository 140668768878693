import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import {select, Store} from '@ngrx/store';
import { Router } from '@angular/router';
import { Business } from 'src/app/business-details/model/business';
import {BusinessCustomer} from '../../personal-details/model/businessCustomer';
import { AuthenticationService } from '../service/authentication.service';
import { Account } from 'src/app/account-details/model/account';
import { Address } from 'src/app/business-details/model/address';
import {addSelfieDoc} from '../store/action/selfieDocumentactions';
import { selectPersonalDocument } from '../../personal-details/store/selector/personalDocument.selectors';
import {selectPersonal} from '../../personal-details/store/selector/personal.selectors';
import { selectSelfieDocument } from '../../bio-verification/store/selector/selfieDocument.selectors';
// import {selectBusinessDocument} from '../../business-details/store/selector/businessDocument.selectors';
import { PersonalDetailService } from 'src/app/personal-details/service/personal-detail.service';
import { Document } from 'src/app/business-details/model/document';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {selectBusiness} from '../../business-details/store/selector/business.selectors';
import {selectCustomers} from '../../account-details/store/selector/customer.selectors';
import { arraysAreNotAllowedMsg } from '@ngrx/store/src/models';




declare var $: any;

@Component({
  selector: 'bio-verification',
  templateUrl: './bio-verification.component.html',
  styleUrls: ['./bio-verification.component.scss']
})


export class BioVerificationComponent implements OnInit {
    public showWebcam = true;
    public isSuccess: boolean = false;
    public isError: boolean=false;
    public allowCameraSwitch = true;
    public closeResult: string;
    public multipleWebcamsAvailable = false;
    public finalCustomer:BusinessCustomer;
    public previousBusinessResponse:any;
    public errors: WebcamInitError[] = [];
    public selfieDocument: Document[] = [];
    public finalDocument: Document[] = [];
    public webcamImage: any = null;
    private trigger: Subject<void> = new Subject<void>();
    // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
    private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
    public deviceId: string;
    public snapshot_label: string = "Capture";
    public business : Business;
    public prevCustomer:Account;
    public selfieFile:File;
    public document: string=null;
    public prevBusiness:Business;
    public prevBusinessAddr:Address;
    public prevBusinessDoc:any;

    public prevPersonal:BusinessCustomer;
    public prevPersonalAddr:Address;
    public prevPersonalDoc :any[];
    public selfiePersonalDoc:any;

    constructor(private router: Router,private store: Store<any>,private modalService: NgbModal,
      private personalDetailService:PersonalDetailService,private ngxService: NgxUiLoaderService,
      private signUpService:AuthenticationService){

        this.store.pipe(select(selectCustomers)).subscribe((result:any) => {
          this.prevCustomer=result;
         })
        this.store.pipe(select(selectPersonal)).subscribe((result:any) => {
          this.prevPersonal=result;
         });
         this.store.pipe(select(selectBusiness)).subscribe((result) => {
          this.previousBusinessResponse=result;
        })
        this.store.pipe(select(selectPersonalDocument)).subscribe((result:any) => {
          this.prevPersonalDoc=JSON.parse(JSON.stringify(result));
        })

        this.store.pipe(select(selectSelfieDocument)).subscribe((result:any) => {
          this.selfiePersonalDoc=result;
        })

        

        // let custModel = new BusinessCustomer(null, accountModel,"operator",this.prevPersonal.firstName, 
        // this.prevPersonal.lastName,this.prevPersonal.gender,this.prevPersonal.nationality, 
        // this.prevPersonal.dateOfBirth, 0,customerAddressModel,this.prevPersonalDoc);

        // let finalBusiness= new Business(null,this.prevBusiness.fullName,this.prevBusiness.registrationNo,this.prevBusiness.website,
        // this.prevBusiness.status,bussinessAddressModel,this.prevBusiness.registrationType,this.prevBusiness.industry,this.prevBusinessDoc,custArray)
          
        //   this.business=finalBusiness;
      }
  ngOnInit() {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }


  addSelfie(){
    this.sendImageApi(this.selfieFile);

    // this.router.navigate(['/passbaseVerification']);
  }

  sendImageApi(file){
    this.personalDetailService.sendPersonalDocument(file, "Selfie", "Selfie").subscribe((result) => {
      if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
        this.document = result.responseData.Date.document;
        let selfieImage = new Document(null, "Document","Selfie", this.document, 1);
        this.store.dispatch(addSelfieDoc(selfieImage));

        this.prevPersonalDoc.push(selfieImage);

 let accountModel=new Account(this.prevCustomer.id,this.prevCustomer.fullName,this.prevCustomer.mobileNo,
          this.prevCustomer.email,this.prevCustomer.type,this.prevCustomer.status,null); 
          console.log(accountModel);

 let custModel = new BusinessCustomer(this.prevPersonal.id,this.previousBusinessResponse.id, accountModel,"operator",this.prevPersonal.firstName, 
        this.prevPersonal.lastName,this.prevPersonal.gender,this.prevPersonal.nationality, 
        this.prevPersonal.dateOfBirth, 0,null,this.prevPersonalDoc);
  this.finalCustomer=custModel;
  this.uploadPersonalDocument();
      }
      else {
        // this.isError = true;
      }
    }, error => { console.log(error) })
  }

  insertObject(arr, obj) {
     let index = arr.length;
     arr.splice(index, 0, obj);
     console.log(arr);
 }

  public handleImage(webcamImage: WebcamImage): void {
    // console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.showWebcam = false;
    this.snapshot_label = "Reset";
    let dataurl=this.webcamImage._imageAsDataUrl;
    this.selfieFile = this.dataURLtoFile(dataurl,'customer_Selfie.txt');
   
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public triggerSnapshot(): void {
    if(!this.showWebcam) {
      this.showWebcam = true;
      this.webcamImage = null;
      this.snapshot_label = "Capture"
    }
    else {
      this.trigger.next();
    }
  }

  uploadPersonalDocument(){
    this.ngxService.start();
    this.personalDetailService.customerUploadDocument(this.finalCustomer).subscribe((result) => {
      if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
        // this.store.dispatch(addPerson(result.responseData.customer));
        this.isSuccess = true;
        this.ngxService.stop();
        this.router.navigate(['/createAccount']);
      // this.openmodal(modal);
      }
      else {
        this.ngxService.stop();
        this.isError = true;
      }
    }, error => { console.log(error) });
  }

  openmodal(content){
    this.modalService.open(content,   {size: 'lg', windowClass: 'modal-xl'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.router.navigate(['/bioVerification']);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

   getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

   dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

}
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-large',
  templateUrl: './card-large.component.html',
  styleUrls: ['./card-large.component.scss']
})
export class CardLargeComponent implements OnInit {
  @Input()
  public heading: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}

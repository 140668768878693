<div class="card-content">
  
    <div class="container">
      <h5 class="wellet-heading">
        {{heading}}
      </h5>
      <div class="card">
        <div class="card-content ">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { addPersonalDoc } from '../store/action/personalDocumentactions';
import { selectPersonalDocument } from '../store/selector/personalDocument.selectors'
import { selectBusiness } from '../../../app/business-details/store/selector/business.selectors'
import { selectPersonal } from '../../personal-details/store/selector/personal.selectors';
import { selectPersonalAddress } from '../../personal-details/store/selector/personalAddress.selectors';
import { selectCustomers } from '../../account-details/store/selector/customer.selectors';
import { BusinessCustomer } from '../model/businessCustomer';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { addPerson } from '../store/action/personal.actions';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Business } from 'src/app/business-details/model/business';
import { FormBuilder, Validators } from '@angular/forms';
import { Account } from 'src/app/account-details/model/account';
import { Document } from 'src/app/business-details/model/document';
import { PersonalDetailService } from '../service/personal-detail.service';
import { environment } from 'src/environments/environment';
import { Address } from 'src/app/business-details/model/address';
import { SharedService } from 'src/app/shared/shared.service';
import { log } from 'console';
import { formatDate } from '@angular/common';
import { BusinessDetailService } from 'src/app/business-details/service/business-detail.service';

declare var $: any;

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'personal-documents',
  templateUrl: './personal-documents.component.html',
  styleUrls: ['./personal-documents.component.scss']
})


export class PersonalDocumentsComponent implements OnInit {
  public business: Business;
  public isSuccess: boolean = false;
  public isError: boolean = false;
  public customer: BusinessCustomer;
  public document: string = null;
  public prevPersonal: BusinessCustomer;
  public prevPersonalAddr: Address;
  public prevPersonalDoc: any;
  public submitted = false;
  public previousResponse: any;
  selectedFile: ImageSnippet;
  public userDocument: Document[] = [];
  public previousBusinessResponse: any;
  public bDocument1: any;
  public bDocument2: any;
  public imageUrl: string;
  public closeResult: string;
  public prevCustomer: Account;
  businessDataArray: any[] = [];
  public editingIndex: any;
  public previousDatta: any;
  public businessID: any;
  public rolesPayload: any;
  public accountID: any;
  public customerID: any;
  public partnerTabCustomer: any;
  public documentsType: any;
  public customers: any;


  personalDocumentsForm = this.formBuilder.group({
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    // code      : ['', Validators.required],
    birth: ['', Validators.required],
    place: ['', Validators.required],
    nationality: ['', Validators.required],
    doctype: ['', Validators.required],
    docnum: ['', Validators.required],
    docissuing: ['', Validators.required],
    expdate: ['', Validators.required],
    mob: ['', Validators.required],
    country: ['', Validators.required],
    street: ['', Validators.required],
    zip: ['', Validators.required],
    city: ['', Validators.required],
    city1: ['', Validators.required],
    tax1: ['', Validators.required],
    email: ['', Validators.required],
    citizenship: ["",],
    position: ["", Validators.required],
    persons: ["",],
    nominee: ["",],
    trustee: ["",],
    usaPerson: ["",],
    customer: ["",]
  });
  states: any;
  cities: any;
  countries: any;
  personalDetailForm: any;
  selectedCustomerData: any;
  businessCustomer: any
  get f() { return this.personalDocumentsForm.controls };


  constructor(private router: Router, private store: Store<Document>, private modalService: NgbModal,
    private ngxLoader: NgxUiLoaderService, private businessService: BusinessDetailService,
    private formBuilder: FormBuilder, private personalDetailService: PersonalDetailService, private route: ActivatedRoute, private sharedDataone: SharedService,) {

    this.businessService.fetchCountries().subscribe(response => {
      if (response.code == 'DAO_SUCCESS_00') {
        this.countries = response.responseData.countries;
        console.log(this.countries);
      }
    });

    this.businessService.getBusinesType().subscribe(response => {
      if (response.code == 'DAO_SUCCESS_00') {
        this.documentsType = response.responseData.Data;
        console.log(this.documentsType);
      }
    });

    this.imageUrl = environment.api_base_url;

    this.store.pipe(select(selectBusiness)).subscribe((result) => {
      this.previousBusinessResponse = result;
    })

    this.store.pipe(select(selectCustomers)).subscribe((result: any) => {
      this.prevCustomer = result;
    });

    this.store.pipe(select(selectPersonal)).subscribe((result: any) => {
      this.prevPersonal = result;
    });

    this.store.pipe(select(selectPersonalDocument)).subscribe((result: any) => {
      this.prevPersonalDoc = result;
    })

    this.store.pipe(select(selectPersonalAddress)).subscribe((result: any) => {
      this.prevPersonalAddr = result;
    });

    this.personalDocumentsForm.get('country').valueChanges.subscribe(val => {
      this.fetchStates(val);
      this.withoutStatefetchCities(val);
    });

    // this.store.pipe(select(selectPersonalDocument)).subscribe((result:any) => {
    //   this.previousResponse=result;
    //  })
  }

  ngOnInit() {

    setTimeout(() => {
      this.getCustumorData()
    }, 200);

    this.accountID = localStorage.getItem('accountID')
    this.businessID = localStorage.getItem('businessID');
    this.previousDatta = this.sharedDataone.getData()
    console.log(this.previousDatta);

    if (this.previousResponse) {
      this.bDocument1 = this.previousResponse[0].document;
      this.bDocument2 = this.previousResponse[1].document;
    }
  }

  fetchCities(stateId) {
    this.states.map((x: { id: any; cityVos: any; }) => {
      if (x.id == stateId) {
        this.cities = x.cityVos;
      }
    })
  }
  fetchStates(countryId) {
    this.countries.map(x => {
      if (x.id == countryId) {
        this.states = x.stateVos;
      }
    })
  }

  withoutStatefetchCities(countryId) {
    this.countries.map(x => {
      if (x.id == countryId) {
        x.stateVos.map(statess => {
          if (statess.country == countryId) {
            this.cities = statess.cityVos;
          } else {
            this.cities = [];
          }
        });
      }
    })
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.sendImageApi(this.selectedFile.file);
    });
    reader.readAsDataURL(file);
  }

  sendImageApi(file) {
    this.ngxLoader.start();
    this.personalDetailService.sendPersonalDocument(file, "ID", "Nic").subscribe((result) => {
      if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
        this.document = result.responseData.Date.document;
        let nic = new Document(null, "Document", "Nic", this.document, 1);
        this.userDocument.push(nic);
        this.ngxLoader.stop();
      }
      else {
        this.isError = true;
        this.ngxLoader.stop();
      } selectPersonalDocument
    }, error => { console.log(error) })
  }

  // sendImageApi(file){
  //   this.ngxService.start();
  //   this.personalDetailService.sendPersonalDocument(file).subscribe((result) => {
  //     if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
  //       this.document=result.responseData.fileID;
  //       let nic = new Document(null, "Document","Nic", this.document, 1);
  //        this.userDocument.push(nic);
  //        this.ngxService.stop();
  //     }
  //     else {
  //       this.isError = true;
  //       this.ngxService.stop();
  //     }selectPersonalDocument
  //   }, error => { console.log(error) })
  // }

  // addUserDocument(){
  //   this.submitted = true;
  //     if (this.personalDocumentsForm.invalid){
  //       return;
  //     }
  //   else{

  //       this.store.dispatch(addPersonalDoc(this.userDocument));
  //       this.router.navigate(['/bioVerification']);
  //   }
  // }

  addUserDocument() {
    console.log('Personal Data Previos', this.previousDatta);

    let body = {
      id: null,
      description: this.previousDatta?.tax,
      noOfEmployee: this.previousDatta?.employees,
      industryCode: this.previousDatta?.industry,
      codeType: null,
      additionalDescription: this.previousDatta?.tax1,
      license: this.previousDatta?.tradingName == 'no' ? 0 : 1,
      licenseInput: this.previousDatta?.tradingName,
      annualTurnover: this.previousDatta?.annual,
      shareCapital: this.previousDatta?.indicate,
      sourceShareCapital: this.previousDatta?.source,
      specificSource: this.previousDatta?.specify,
      passiveIncome: this.previousDatta?.passiveIncome == 'no' ? 0 : this.previousDatta?.passiveIncome,
      region: this.previousDatta?.regions,
      incomeMonthlyTurnover: this.previousDatta?.activity,
      incomeMonthlyTransaction: this.previousDatta?.estimated,
      outcomeMonthlyTurnover: this.previousDatta?.monthly,
      outcomeMonthlyTransaction: this.previousDatta?.transactions,
      business: {
        id: this.businessID,
        account: {
          id: this.accountID
        }
      },
      businessPartners: [
        this.customerID
      ]
    }


    console.log('THis is Body', body);
    // this.route.data.subscribe()
    this.ngxLoader.start();
    this.personalDetailService.addBusinessActivity(body).subscribe(resp => {
      console.log(resp);
      if (resp.code === 'DAO_SUCCESS_00') {
        this.ngxLoader.stop();
        this.router.navigate(['/createAccount']);
      } else {
        this.ngxLoader.stop();
      }
    });
    // this.store.dispatch(addPersonalDoc(this.userDocument));
    // this.router.navigate(['/personalDocuments']);
    // }

  }

  saveBusinessRoles() {
    if (this.businessDataArray.length === 0) {
      console.log(this.personalDocumentsForm.controls)
      this.submitted = true;
      if (this.personalDocumentsForm.invalid) {
        return;
      }
    }

    if (this.businessDataArray.length != 0) {
      const transformedArray: any[] = [];

      // Iterate through the inputArray and transform each item
      this.businessDataArray.forEach(item => {
        const transformedItem: any = {
          businessId: this.businessID,
          firstName: item.firstname,
          lastName: item.lastname,
          nationality: item.nationality,
          email: item.email,
          phone: item.mobnum,
          placeofBirth: item.place,
          accountVo: {
            id: this.accountID
          },
          status: 0,
          dateOfBirth: item.birth,
          customerAddressVo: {
            line1: item.street,
            line2: "",
            city: item.city,
            postalCode: item.zip,
            state: null,
            country: item.country,
          },
          customerDocumentDetailsVo: [{
            docType: item.Iddoc,
            docNumber: item.docnum,
            docCountry: item.docissuing,
            expiryDate: item.expdate
          }],
          taxIdentificationNo: item.taxnum,
          multiCitizenship: item.citizenship == 'no' ? 0 : 1,
          exposedPerson: item.persons == 'no' ? 0 : 1,
          designation: item.position,
          benfActNominee: item.nominee == 'no' ? 0 : 1,
          benfActTrustee: item.trustee == 'no' ? 0 : 1,
          benfUSAPerson: item.usaPerson == 'no' ? 0 : 1,
          isAuthorisedSignatory: 0,
          isDirector: 0,
          isShareHolder: 0,
          isUbo: 0,
          businessPartners: 1,
          taxCountry: item.countryone,
          type: "individual",
        };
        // Push the transformed item to the result array
        transformedArray.push(transformedItem);
      });

      this.rolesPayload = transformedArray;
      console.log(this.rolesPayload);
    }
    this.ngxLoader.start();
    this.personalDetailService.businessRoles(this.rolesPayload).subscribe(response => {
      console.log("save roles api res", response);
      if (response.code === "DAO_SUCCESS_00") {
        this.ngxLoader.stop();
        this.customerID = response.responseData.Data.customer.id;
        console.log('Customer ID => ', this.customerID);

        this.addUserDocument()
      } else {
        this.ngxLoader.stop();
      }
    })
  }

  onPrevious() {
    this.router.navigate(['/personalDoctype']);
  }

  getCustumorData() {
    this.personalDetailService.getCustumer(this.businessID).subscribe(res => {
      // this.customers = res.
      console.log(res);
      if (res.code === "DAO_SUCCESS_00") {
        this.customers = res.responseData.Data;
      }
    })
  }

  addDocData() {
    console.log(this.personalDocumentsForm.controls);



    if (this.personalDocumentsForm.valid) {
      if (typeof this.editingIndex !== 'undefined') {
        // Update existing data at the editingIndex
        this.businessDataArray[this.editingIndex] = this.personalDocumentsForm.value;
        // Reset the form and clear the editingIndex
        this.editingIndex = undefined;
      } else {
        // Add new data to the array
        this.businessDataArray.push(this.personalDocumentsForm.value);
      }

      console.log("this.form data", this.businessDataArray);
      this.personalDocumentsForm.reset();
    } else {
      // Handle invalid form data
    }
  }

  partnersForm() {
    this.personalDocumentsForm.reset(); // Form ko reset karein
  }

  setFormValues(item: any, index: any) {
    console.log("index", index);

    console.log("personalDocumentsForm")
    this.personalDocumentsForm.setValue({
      lastname: item.lastname,
      firstname: item.firstname,
      // code      : item.code,
      birth: item.birth,
      place: item.place,
      nationality: item.nationality,
      doctype: item.doctype,
      docnum: item.docnum,
      docissuing: item.docissuing,
      expdate: item.expdate,
      mob: item.mob,
      country: item.country,
      street: item.street,
      zip: item.zip,
      city: item.city,
      city1: item.city1,
      // tax1       : item.tax1,
      email: item.email,
      citizenship: item.citizenship,
      position: item.position,
      persons: item.persons,
      nominee: item.nominee,
      trustee: item.trustee,
      usaPerson: item.usaPerson,
      customer: item.customer
      // Set other form control values as needed
    });
    this.editingIndex = index;
  }

  deleteRecord(idToDelete: number): void {
    let record = this.businessDataArray
    if (idToDelete >= 0 && idToDelete < record.length) {
      record.splice(idToDelete, 1);
    }
  }
  getCountryId(value: String) {
    this.countries.map((i, v) => {
      if (i.name == value) {
        value = i.id;
      }

    })
    return value;
  }

  getdocumentsType(value: String) {
    this.documentsType.map((i, v) => {
      if (i.name == value) {
        value = i.id;
      }

    })
    return value;
  }



  fetchCustomerData(value: string) {
    this.businessCustomer = value;
    this.selectedCustomerData = this.getSelectedCustomerByFilter(this.businessCustomer)[0]
    console.log(this.selectedCustomerData);
    console.log('Date Convert ', formatDate(this.selectedCustomerData.dateOfBirth, "yyyy-MM-dd", "en-US"));

    this.personalDocumentsForm.patchValue({
      firstname: this.selectedCustomerData.firstName,
      lastname: this.selectedCustomerData.lastName,
      birth: formatDate(this.selectedCustomerData.dateOfBirth, "yyyy-MM-dd", "en-US"),
      // personalcode: this.selectedCustomerData.personalcode,
      place: this.getCountryId(this.selectedCustomerData.placeofBirth),
      nationality: this.getCountryId(this.selectedCustomerData.nationality),
      Iddoc: this.getdocumentsType(this.selectedCustomerData.docType ?? null),
      docnum: this.selectedCustomerData.customerDocumentDetails[0].docNumber ?? null,
      docissuing: this.getCountryId(this.selectedCustomerData.customerDocumentDetails[0].docCountry ?? null),
      expdate: formatDate(this.selectedCustomerData.customerDocumentDetails[0].expiryDate ?? null, "yyyy-MM-dd", "en-US"),
      mobnum: this.selectedCustomerData.phone,
      email: this.selectedCustomerData.email,
      country: this.getCountryId(this.selectedCustomerData.customerAddresses[0].country),
      city: this.selectedCustomerData.customerAddresses[0].city,
      street: this.selectedCustomerData.customerAddresses[0].line1,
      zip: this.selectedCustomerData.customerAddresses[0].postalCode,
      countryone: this.getCountryId(this.selectedCustomerData.taxCountry),
      taxnum: this.selectedCustomerData.taxIdentificationNo,
      citizenship: this.selectedCustomerData.multiCitizenship == null ? 'no' : 'yes',
      position: this.selectedCustomerData.designation,
      persons: this.selectedCustomerData.exposedPerson == null ? 'no' : 'yes',
      nominee: this.selectedCustomerData.benfActNominee == null ? 'no' : 'yes',
      trustee: this.selectedCustomerData.benfActTrustee == null ? 'no' : 'yes',
      usaPerson: this.selectedCustomerData.benfUSAPerson == null ? 'no' : 'yes',


      // add remaining formCOntrols here
    })

  }


  getSelectedCustomerByFilter(id: any) {
    console.log('customer ID', id);
    return this.customers.filter((item) => item.id == id);
  }



}

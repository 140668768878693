import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import {VerifyOtpService} from '../service/verify-otp.service';
import {addBusiness} from '../../business-details/store/action/business.actions';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {addCustomer} from '../../account-details/store/action/customer.actions';
import {Store} from '@ngrx/store';
import {addPerson} from '../../personal-details/store/action/personal.actions';
import { Otp } from '../model/otp';
import { Data } from 'src/app/providers/data';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { filter } from 'rxjs/operators';
import { Account } from 'src/app/account-details/model/account';
import { BusinessDetailService } from 'src/app/business-details/service/business-detail.service';
import { Business } from 'src/app/business-details/model/business';
import { PersonalDetailService } from 'src/app/personal-details/service/personal-detail.service';
import { Observable, Subscription, timer } from 'rxjs';


declare var $: any;

@Component({
  selector: 'verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})


export class VerifyOTPComponent implements OnInit{

  otpTimer$: Observable<number> | any;
  timerSubscription: Subscription | any;
  timeLeft: number = 60; // Initial time in seconds
  timerRunning: boolean = false;

  public isSuccess: boolean = false;
  public isError: boolean=false;
  public submitted = false;
  public first:string;
  public second:string;
  public third:string;
  public fourth:string;
  public navUrl:string;
  public fifth:string;
  public modalText:string;
  public accountType:string;
  public sixsth:string;
  public accountModel:Account;
  public userId:number;
  public currentEmail:string;
  public title = 'appBootstrap';
  public closeResult: string;

  @ViewChild("first") firstElement: ElementRef;
  @ViewChild("second") secondElement: ElementRef;
  @ViewChild("third") thirdElement: ElementRef;
  @ViewChild("fourth") fourthElement: ElementRef;
  @ViewChild("fifth") fifthElement: ElementRef;
  @ViewChild("sixsth") sixsthElement: ElementRef;

  constructor(private verifyOtpService: VerifyOtpService,private data: Data,private store: Store<Account>,private personalDetailService:PersonalDetailService,
    private modalService: NgbModal,private ngxService: NgxUiLoaderService,private businessDetailService:BusinessDetailService,
    private router: Router, private formBuilder : FormBuilder) {
      this.currentEmail=localStorage.getItem('userEmail');
    }

    ngOnInit() {
      this.startTimer();
      this.userId=Number(localStorage.getItem('userId'));

      this.optForm.get("first").valueChanges
      .pipe(filter((value: string) => value.length === 1))
      .subscribe(() => this.secondElement.nativeElement.focus());

      this.optForm.get("second").valueChanges
      .pipe(filter((value: string) => value.length === 1))
      .subscribe(() => this.thirdElement.nativeElement.focus());

      this.optForm.get("third").valueChanges
      .pipe(filter((value: string) => value.length === 1))
      .subscribe(() => this.fourthElement.nativeElement.focus());

      this.optForm.get("fourth").valueChanges
      .pipe(filter((value: string) => value.length === 1))
      .subscribe(() => this.fifthElement.nativeElement.focus());

      this.optForm.get("fifth").valueChanges
      .pipe(filter((value: string) => value.length === 1))
      .subscribe(() => this.sixsthElement.nativeElement.focus());

      

    }

    optForm = this.formBuilder.group({
      first :  ['', Validators.required],
      second : ['', Validators.required],
      third :  ['', Validators.required],
      fourth : ['', Validators.required],
      fifth :  ['', Validators.required],
      sixsth : ['', Validators.required],

    })

    get f(){return this.optForm.controls;}



  verifyOtp(modal){
    this.startTimer();
    this.submitted = true;
    if (this.optForm.invalid){
      return;
    }
    else{
      let finalOtp = `${this.optForm.value.first}${this.optForm.value.second}${this.optForm.value.third}${this.optForm.value.fourth}${this.optForm.value.fifth}${this.optForm.value.sixsth}`;
      let accountVo = {
        id: this.userId
      }
      const otpModel = new Otp(this.userId, accountVo, finalOtp);
      this.ngxService.start();
      this.verifyOtpService.sendOtp(otpModel).subscribe((result) => {
        if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
         var accountRes=result.responseData.user.accountVo;
          
         let accountModel = new Account(accountRes.id,accountRes.fullName,
          accountRes.mobileNo, accountRes.email,accountRes.type,accountRes.status,null);

          this.accountType=accountRes.type;
          this.accountModel=accountModel;
          this.store.dispatch(addCustomer(accountModel));



          if(accountRes.status===2){
            if(this.accountType==="operator"){
              this.fetchBusiness(accountRes.id);
            }
            else{
              this.fetchCustomer(accountRes.id);
            }
            this.modalText="Let's get your personal information.";
            this.navUrl="/personalDetails";
          }
          else if(accountRes.status===1){
            this.modalText="Let's get your merchant information.";
            this.navUrl="/businessDetails";
          }
          else if(accountRes.status===0){
            this.modalText="Let's get your merchant information.";
            this.navUrl="/businessDetails";
          }

          else if(accountRes.status===3){
            this.modalText="Let's get your Identification Documents for Verification";
            this.fetchCustomer(accountRes.id);
            this.navUrl="/personalDocuments";
          }

          this.isSuccess = true;
          this.isError = false;
          this.ngxService.stop();
          // this.openmodal(modal);
          this.router.navigate(['/businessDetails']);
        }
        else {
          this.ngxService.stop();
          this.isError = true;
        }
      }, error => { console.log(error) })
    }

  }

fetchBusiness(id){
  this.businessDetailService.getSelectedBusiness(id).subscribe((result)=> {
    if(result != null && result.responseData != null) {
    let businessResp=result.responseData.Business;

    const merchantModel = new Business(businessResp.id,businessResp.fullName,businessResp.registrationNo,
      businessResp.website,businessResp.status,businessResp.address,businessResp.registrationType,businessResp.industry,
      businessResp.documents,this.accountModel,null, null)

    this.store.dispatch(addBusiness(merchantModel));
    }
    else{
   
    }

  }, error => {console.log(error);})
}

fetchCustomer(id){
this.personalDetailService.getSelectedCustomer(id).subscribe((result)=> {
  if(result != null && result.responseData != null) {
    this.store.dispatch(addPerson(result.responseData.Customer));
  }

}, error => {console.log(error);})
}

  openmodal(content){
    this.modalService.open(content,   {size: 'lg', windowClass: 'modal-xl'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.router.navigate([this.navUrl]);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  

  onPrevious(){
    this.data.storage = {
      check: 1
    };
    this.router.navigate(['/accountDetails']);
  }
  
  startTimer() {
    this.otpTimer$ = timer(0, 1000); // Emits a value every 1 second
    this.timerSubscription = this.otpTimer$.subscribe(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.stopTimer();
      }
    });
    this.timerRunning = true;
  }

  stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timerRunning = false;
  }


}

import { Address } from "./address";
import { Document } from "./document";
import { Account } from "./account";

export class BusinessCustomer { 
  constructor(  
    public id: number,
    public account:Account, 
    public type: string,
    public firstName: string,
    public lastName: string,
    public gender:string,
    public nationality: string,
    public dateOfBirth: string,
    public status: number,
    public address: Address,
    public documents: Document[],
    ){}  }
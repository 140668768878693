import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpService } from '../../http.service';
import { map, catchError } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { SystemConstants } from '../../sign-up/system/system.constants'; 
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  public isError: boolean;
  public isSuccess: boolean = false;

  private baseUrl = environment.api_base_url;
  private headers = SystemConstants.header;

  constructor(private httpService:HttpService,
    private ngxLoader: NgxUiLoaderService) { }


  createBusiness(object: any): Observable<any> {
    let headers = new Headers();
    headers.append("Content-Type","application/json");
    let request = JSON.stringify({ "business": object});    
    return this.httpService.post(this.baseUrl+"business/register",request,{headers})
    .pipe(map((res:any) => {
      this.ngxLoader.stop();   
      return JSON.parse(res._body);
    })).pipe(catchError((error:any) => {
      console.log(error);
      this.ngxLoader.stop();
      return error;
    })); 
  }


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthenticationService} from '../bio-verification/service/authentication.service' 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import {selfieDocumentFeatureKey,SelfieDocumentReducer} from './store/reducer/selfieDocument.reducer'
import { BioVerificationComponent } from './component/bio-verification.component';
import { WebcamModule } from 'ngx-webcam';

@NgModule({
  declarations: [
    BioVerificationComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(selfieDocumentFeatureKey,SelfieDocumentReducer),
    ReactiveFormsModule,
    WebcamModule,
    FormsModule,
    RouterModule
  ],
  providers:[AuthenticationService],
  exports: [
    BioVerificationComponent
  ]
})
export class BioVerificationModule {}

<app-card-large>
  <div class="dyEAXN">
    <div class="bCDrPV djdIYm">
      <div class="mjYRwVK">
        <div class="main-panel__content">
          <div class="main-panel__content">
            <h1 class="main-heading nonreferral">Business Activity</h1>
            <h2 class="main-sub-heading">
              Please provide your business activity
            </h2>
          </div>
        </div>

        <form name="f" [formGroup]="passportForm">
          <div class="row">
            <div class="input-field col s6">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="email"
                >State your main business activity and provide a detailed
                description of it <span style="color: red">*</span></label
              >
              <div class="form-floating">
                <textarea
                  class="fts-input-field register-w"
                  placeholder="Leave a comment here"
                  formControlName="tax"
                  id="floatingTextarea"
                ></textarea>
                <div *ngIf="submitted && f.tax.errors" class="text-danger">
                  <div *ngIf="f.tax.errors.required">
                    business activity tax is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s1">
              <strong
                data-component="form-input-name-label"
                class="form__label"
                for="email"
                >How many employees work in your company
                <span style="color: red">*</span></strong
              >
            </div>
            <br />
            <div class="input-field col s4">
              <label for="team_size" class="form__label">
                <span style="color: red"></span
              ></label>
              <select
                name="team_size"
                formControlName="employees"
                id="team_size"
                formControlName="employees"
                class="fts-select-small"
              >
                <option disabled selected>Employees</option>
                <option
                  *ngFor="let employeenum of employeenum"
                  [value]="employeenum.id"
                >
                  {{ employeenum.label }}
                </option>
              </select>
              <div *ngIf="submitted && f.employees.errors" class="text-danger">
                <div *ngIf="f.employees.errors.required">
                  Employees is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="email"
                >Nace industry code for main business activity<span
                  style="color: red"
                  >*</span
                ></label
              >
              <select
                name="team_size"
                id="team_size"
                formControlName="industry"
                class="fts-select"
              >
                <option
                  *ngFor="let businessActivityAll of businessActivityAll"
                  [value]="businessActivityAll.id"
                >
                  {{ businessActivityAll.label }}
                </option>
              </select>
              <div *ngIf="submitted && f.industry.errors" class="text-danger">
                <div *ngIf="f.industry.errors.required">
                  Industry Code is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="email"
                >State your additional business activity and provide a detailed
                description of it <span style="color: red">*</span></label
              >
              <div class="form-floating">
                <textarea
                  class="fts-input-field register-w"
                  placeholder="Leave a comment here"
                  formControlName="tax1"
                  id="floatingTextarea"
                ></textarea>
              </div>
              <div *ngIf="submitted && f.tax1.errors" class="text-danger">
                <div *ngIf="f.tax1.errors.required">
                  Main business activity is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4"></div>
            <br />
            <div
              class="d-flex"
              style="justify-content: space-between; gap: 5px"
            >
              <h2
                for="team_size"
                class="form__label"
              >
                Does the business activity require a license, permission, Or
                special registration<span style="color: red">*</span>
              </h2>
              <label>
                <input
                  type="radio"
                  (change)="selectTradingName()"
                  formControlName="tradingName"
                  name="tradingName"
                  value="yes"
                  style="margin-left: 5px"
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  (change)="selectTradingName()"
                  formControlName="tradingName"
                  name="tradingName"
                  value="no"
                />
                No
              </label>
            </div>
            <div *ngIf="isYesSelected">
              <input
                class="fts-input-field register-w"
                formControlName="tradingNames"
                type="text"
                placeholder="trading name"
              />
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Please state the company annual turnover (in preceding fiscal
                year),in Eur;<span style="color: red">*</span></label
              >
              <input
                id="name"
                type="text"
                name="name"
                placeholder="10000"
                autocomplete="name"
                formControlName="annual"
                class="fts-input-field register-w"
                value
              />
              <div *ngIf="submitted && f.annual.errors" class="text-danger">
                <div *ngIf="f.annual.errors.required">
                  preceding fiscal year is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Please indicate the amount of share capital.in EUR<span
                  style="color: red"
                  >*</span
                ></label
              >
              <input
                id="name"
                type="text"
                name="name"
                placeholder="10000"
                autocomplete="name"
                formControlName="indicate"
                class="fts-input-field register-w"
                value
              />
              <div *ngIf="submitted && f.indicate.errors" class="text-danger">
                <div *ngIf="f.indicate.errors.required">
                  Please indicate the amount of share capital is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="email"
                >Please specify source of share capital
                <span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                formControlName="source"
                class="fts-select"
              >
                <option
                  *ngFor="let shareOfSourceCapital of shareOfSourceCapital"
                  [value]="shareOfSourceCapital.id"
                >
                  {{ shareOfSourceCapital.label }}
                </option>
              </select>

              <div *ngIf="submitted && f.source.errors" class="text-danger">
                <div *ngIf="f.source.errors.required">
                  Please specify source is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Please specify<span style="color: red">*</span></label
              >
              <input
                id="name"
                type="text"
                formControlName="specify"
                name="name"
                placeholder="10000"
                autocomplete="name"
                class="fts-input-field register-w"
                value
              />
              <div *ngIf="submitted && f.specify.errors" class="text-danger">
                <div *ngIf="f.specify.errors.required">
                  Please specify is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label for="team_size" class="form__label">
                Select main business regions
                <span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                formControlName="regions"
                class="fts-select"
              >
                <option disabled selected>Regions</option>
                <option>Regions</option>
                <option>Regions1</option>
                <!-- <option *ngFor="let countrty of countries"[value]="countrty.id">{{countrty.name}} -->
              </select>
              <div *ngIf="submitted && f.employees.errors" class="text-danger">
                <div *ngIf="f.employees.errors.required">
                  Regions is required
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex"
            style="justify-content: space-between; margin-top: 5px"
          >
            <h2
              for="team_size"
              class="form__label"
            >
              Please specify whether passive income(rent,dividens,etc.)is more
              than 50% of the company annual revenue
              <span style="color: red">*</span>
            </h2>
            <div style="display: flex; gap: 10px">
              <input
                type="radio"
                id="html"
                formControlName="passiveIncome"
                name="passiveIncome"
                value="yes"
              />
              <label for="html" style="margin-top: 10px">Yes</label><br />
              <input
                type="radio"
                id="css"
                formControlName="passiveIncome"
                name="passiveIncome"
                value="no"
              />
              <label for="css" style="margin-top: 10px">No</label><br />
            </div>
          </div>
          <div>
            <h5 data-component="heading" style="margin-top: 25px" class="small-heading">
              Incoming activity<span style="color: red">*</span>
            </h5>
          </div>
          <div class="row">
            <div class="input-field col s4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="email"
                >Estimated incoming monthly turnover
                <span style="color: red">*</span></label
              >
              <select
                name="team_size"
                style="margin-top: 8px"
                id="team_size"
                formControlName="activity"
                class="fts-select-small"
              >
                <option
                  *ngFor="let monthlyTurnoverAll of monthlyTurnoverAll"
                  [value]="monthlyTurnoverAll.id"
                >
                  {{ monthlyTurnoverAll.label }}
                </option>
              </select>
              <div *ngIf="submitted && f.activity.errors" class="text-danger">
                <div *ngIf="f.activity.errors.required">
                  Incoming activity is required
                </div>
              </div>
              <p class="text-muted">
                Note: please provide equivalent value in eur
              </p>
            </div>
            <div class="input-field col s4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="email"
                >Estimated number of incoming transactions per month
                <span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                formControlName="estimated"
                class="fts-select-small"
              >
                <option
                  *ngFor="let transactionAll of transactionAll"
                  [value]="transactionAll.id"
                >
                  {{ transactionAll.label }}
                </option>
              </select>
              <div *ngIf="submitted && f.estimated.errors" class="text-danger">
                <div *ngIf="f.estimated.errors.required">
                  Estimated number is required
                </div>
              </div>
            </div>
          </div>
          <h5 data-component="heading" class="small-heading">
            Outgoing activity<span style="color: red">*</span>
          </h5>
          <div class="row">
            <div class="input-field col s4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="email"
                >Estimated incoming monthly turnover
                <span style="color: red">*</span></label
              >
              <select
                name="team_size"
                style="margin-top: 8px"
                id="team_size"
                formControlName="monthly"
                class="fts-select-small"
              >
                <option
                  *ngFor="let monthlyTurnoverAll of monthlyTurnoverAll"
                  [value]="monthlyTurnoverAll.id"
                >
                  {{ monthlyTurnoverAll.label }}
                </option>
              </select>
              <div *ngIf="submitted && f.monthly.errors" class="text-danger">
                <div *ngIf="f.monthly.errors.required">
                  Estimated incoming is required
                </div>
              </div>
              <p class="text-muted">
                Note: please provide equivalent value in eur
              </p>
            </div>
            <div class="input-field col s4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="email"
                >Estimated number Of incoming transactions per month
                <span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                formControlName="transactions"
                class="fts-select-small"
              >
                <option
                  *ngFor="let transactionAll of transactionAll"
                  [value]="transactionAll.id"
                >
                  {{ transactionAll.label }}
                </option>
              </select>
              <div
                *ngIf="submitted && f.transactions.errors"
                class="text-danger"
              >
                <div *ngIf="f.transactions.errors.required">
                  incoming transactions per month is required
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="eBKYjJ cgMkag">
            <div class="row">
              <div class="input-field col s4">
                <button
                  data-component="primary-button"
                  type="submit"
                  role="button"
                  tabindex="0"
                  (click)="onPrevious()"
                  class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 buttons-Add"
                >
                  Previous
                </button>
              </div>
              <div class="input-field col s4" style="margin-bottom: 30px">
                <button
                  data-component="primary-button"
                  type="submit"
                  role="button"
                  tabindex="0"
                  class="epjTBn"
                  (click)="addUserDocument()"
                >
                  Continue
                </button>
              </div>
            </div>
          </div> -->
        </form>

        <div class="primary-back-continue">
          <div class="">
            <button
              data-component="primary-button"
              type="submit"
              role="button"
              tabindex="0"
              (click)="onPrevious()"
              class="fts-button-tertiary"
            >
              Previous
            </button>
          </div>
          <div class="">
            <button
              data-component="primary-button"
              type="submit"
              role="button"
              tabindex="0"
              (click)="addUserDocument()"
              class="fts-button-primary"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-card-large>

<app-card-large>
  <div class="dyEAXN">
    <div class="bCDrPV djdIYm">
      <div class="mjYRwVK">
        <div class="main-panel__content">
          <div class="main-panel__content">
            <h1 class="main-heading nonreferral">Personal Information</h1>
            <h2 class="main-sub-heading">
              Please provide your personal details
            </h2>
          </div>
        </div>
        <!-- <form name="form" [formGroup]="personalDetailForm">
          <div class="row">
            <div class="col-sm-11">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="email"
                >First Name <span style="color: red">*</span></label
              >
              <input
                id="email"
                name="email"
                type="text"
                placeholder=""
                formControlName="firstName"
                [ngClass]="{ invalid: submitted && f.firstName.errors }"
                class="fts-input-field name_w validate-empty"
                value=""
              />
              <div *ngIf="submitted && f.firstName.errors" class="text-danger">
                <div *ngIf="f.firstName.errors.required">
                  First Name is required
                </div>
                <div *ngIf="f.firstName.errors.minlength">
                  Required atleast 3 Characters
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Last Name <span style="color: red">*</span></label
              >
              <input
                id="name"
                formControlName="lastName"
                [ngClass]="{ invalid: submitted && f.lastName.errors }"
                name="name"
                type="text"
                autocomplete="name"
                class="fts-input-field input-width validate-empty"
                value=""
              />
              <div *ngIf="submitted && f.lastName.errors" class="text-danger">
                <div *ngIf="f.lastName.errors.required">
                  Last Name is required
                </div>
                <div *ngIf="f.lastName.errors.minlength">
                  Required atleast 3 Characters
                </div>
              </div>
            </div>
            <div class="col-sm-5">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Date Of Birth <span style="color: red">*</span></label
              >
              <input
                id="name"
                name="name"
                type="date"
                autocomplete="name"
                formControlName="dateOfBirth"
                [ngClass]="{ invalid: submitted && f.dateOfBirth.errors }"
                class="fts-input-field input-width"
                value=""
              />
              <div
                *ngIf="submitted && f.dateOfBirth.errors"
                class="text-danger"
              >
                <div *ngIf="f.dateOfBirth.errors.required">DOB is required</div>
                <div *ngIf="f.dateOfBirth.errors.minAge">
                  Age limit is 18 years old
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-5">
              <label for="team_size" class="form__label"
                >Gender <span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                formControlName="gender"
                [ngClass]="{ invalid: submitted && f.gender.errors }"
                class="fts-select selector_width"
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <div *ngIf="submitted && f.gender.errors" class="text-danger">
                <div *ngIf="f.gender.errors.required">Gender is required</div>
              </div>
            </div>
            <div class="col-sm-5">
              <label for="team_size" class="form__label"
                >Nationality <span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                formControlName="nationality"
                [ngClass]="{ invalid: submitted && f.nationality.errors }"
                class="fts-select selector_width"
              >

                <option value="Hong Kong">Hong Kong</option>
                <option value="Singapore">Singapore</option>
                <option value="India">India</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Nepal">Nepal</option>
              </select>

              <div
                *ngIf="submitted && f.nationality.errors"
                class="text-danger"
              >
                <div *ngIf="f.nationality.errors.required">
                  Country is required
                </div>
              </div>
            </div>
          </div>
        </form> -->

        <form name="form" [formGroup]="personalDetailForm">
          <div class="row">
            <div class="input-field col s4">
              <label data-component="form-input-name-label" class="form__label"
                >First name <span style="color: red">*</span></label
              >
              <input
                id
                name="firstName"
                type="text"
                placeholder="first name"
                formControlName="firstName"
                class="fts-input-field input-width"
                value
              />
              <div *ngIf="submitted && f.firstName.errors" class="text-danger">
                <div *ngIf="f.firstName.errors.required">
                  First Name is required
                </div>
                <div *ngIf="f.firstName.errors.minlength">
                  Required atleast 3 characters
                </div>
              </div>
            </div>
            <div class="input-field col s4" style="margin-right: 70px;">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Last name <span style="color: red">*</span></label
              >
              <input
                id="name"
                formControlName="lastName"
                name="name"
                type="text"
                placeholder="last name"
                autocomplete="name"
                class="fts-input-field input-width"
                value
              />
              <div *ngIf="submitted && f.lastName.errors" class="text-danger">
                <div *ngIf="f.lastName.errors.required">
                  Last Name is required
                </div>
                <div *ngIf="f.lastName.errors.minlength">
                  Required atleast 3 characters
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Date of birth <span style="color: red">*</span></label
              >
              <input
                id="name"
                name="name"
                type="date"
                formControlName="dateOfBirth"
                class="fts-input-field input-width"
                value
              />
              <div
                *ngIf="submitted && f.dateOfBirth.errors"
                class="text-danger"
              >
                <div *ngIf="f.dateOfBirth.errors.required">
                  Date is required
                </div>
                <div *ngIf="f.dateOfBirth.errors.minAge">
                  Age limit is 18 years old
                </div>
              </div>
            </div>
            <div class="input-field col s6" style="margin-right: 70px;">
              <label for="team_size" class="form__label"
                >Gender<span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                formControlName="gender"
                class="fts-select-small"
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <div *ngIf="submitted && f.gender.errors" class="text-danger">
                <div *ngIf="f.gender.errors.required">Gender is required</div>
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label for="team_size" class="form__label"
                >Nationality<span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                formControlName="nationality"
                class="fts-select"
              >
                <option disabled selected>Nationality</option>
                <option
                  *ngFor="let countrty of countries"
                  [value]="countrty.id"
                >
                  {{ countrty.name }}
                </option>
              </select>

              <div
                *ngIf="submitted && f.nationality.errors"
                class="text-danger"
              >
                <div *ngIf="f.nationality.errors.required">
                  Nationality is required
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4" style="margin-top: 5px">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Id document type<span style="color: red">*</span></label
              >
              <select
                name="team_size"
                id="team_size"
                class="fts-select-small"
                formControlName="doctype"
                placeholder="XXXXXXXXXXXXXXX"
              >
                <option disabled selected>document type</option>

                <option *ngFor="let type of documentsType" [value]="type.id">
                  {{ type.label }}
                </option>
              </select>
              <div *ngIf="submitted && f.doctype.errors" class="text-danger">
                <div *ngIf="f.doctype.errors.required">
                  Id document type is required
                </div>
              </div>
            </div>
            <div class="input-field col s4" style="margin-top: 5px;margin-right: 70px;">
              <label for="team_size" class="form__label"
                >Id document number<span style="color: red">*</span></label
              >
              <input
                id="name"
                placeholder="document number"
                formControlName="docnum"
                type="text"
                class="fts-input-field input-width"
              />
              <div *ngIf="submitted && f.docnum.errors" class="text-danger">
                <div *ngIf="f.docnum.errors.required">
                  Id document number is required
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4" style="margin-top: 5px">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Id document issuing country<span style="color: red"
                  >*</span
                ></label
              >
              <select
                name="team_size"
                id="team_size"
                class="fts-select-small"
                formControlName="issucountry"
                placeholder="XXXXXXXXXXXXXXX"
              >
                <option disabled selected>Country</option>
                <option
                  *ngFor="let countrty of countries"
                  [value]="countrty.id"
                >
                  {{ countrty.name }}
                </option>
              </select>

              <div
                *ngIf="submitted && f.issucountry.errors"
                class="text-danger"
              >
                <div *ngIf="f.issucountry.errors.required">
                  Id document issuing country is required
                </div>
              </div>
            </div>
            <div class="input-field col s4" style="margin-top: 5px;margin-right: 70px;">
              <label for="team_size" class="form__label"
                >Id document expiry date<span style="color: red">*</span></label
              >
              <input
                id="name"
                placeholder="document expiry"
                type="date"
                formControlName="docexp"
                class="fts-input-field input-width"
              />
              <div *ngIf="submitted && f.docexp.errors" class="text-danger">
                <div *ngIf="f.docexp.errors.required">
                  document expiry date is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4" style="margin-top: 5px">
              <label for="team_size" class="form__label">
                Mobile number<span style="color: red">*</span></label
              >
              <input
                id="name"
                placeholder="XXXXXXXXXXXXXXX"
                type="text"
                formControlName="mobnumber"
                onkeypress="return /^[0-9+]/.test(event.key)"
                class="fts-input-field register-w"
              />
              <div *ngIf="submitted && f.mobnumber.errors" class="text-danger">
                <div *ngIf="f.mobnumber.errors.required">
                  mobile number is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s4" style="margin-top: 10px">
              <label
                data-component="form-input-email-label"
                for="email"
                class="form__label"
              >
                Email <span style="color: red">*</span></label
              >
              <input
                id="userEmail"
                formControlName="email"
                name="email"
                type="text"
                class="fts-input-field register-w"
              />
            </div>
          </div>

          <h6 class="main-sub-heading" style="margin-top: 10px">
            Representative role<span style="color: red">*</span>
          </h6>
          <input
            type="radio"
            id="html"
            name="role"
            value="CEO"
            formControlName="role"
          />
          <label for="html" style="margin-left: 10px; font-family: Montserrat;">CEO</label><br />
          <input
            type="radio"
            id="css"
            name="role"
            value="MOB"
            formControlName="role"
          />
          <label for="css" style="margin-left: 10px; font-family: Montserrat;"
            >Member of the board/board of directors</label
          ><br />
          <input
            type="radio"
            id="javascript"
            name="role"
            value="Acts on the basis of authorisation"
            formControlName="role"
          />
          <label for="javascript" style="margin-left: 10px; font-family: Montserrat;">
            Acts on the basis of authorisation</label
          >

          <h6 class="main-sub-heading" style="margin-top: 10px">
            Are you authorized under power of attorney<span style="color: red"
              >*</span
            >
          </h6>

          <div style="display: flex; gap: 10px">
            <input
              type="radio"
              (change)="onAttorneyChange()"
              formControlName="attorney"
              id="html"
              name="attorney"
              value="yes"
            />
            <label for="html" style="margin-top: 10px">Yes</label><br />
            <input
              type="radio"
              (change)="onAttorneyChange()"
              formControlName="attorney"
              id="css"
              name="attorney"
              value="no"
            />
            <label for="css" style="margin-top: 10px">No</label><br />
          </div>

          <div *ngIf="isYesSelected">
            <input
              id="name"
              placeholder="document expiry"
              type="date"
              class="fts-input-field"
            />
          </div>

          <h6 class="main-sub-heading" style="margin-top: 10px">
            Is the person,his/hers immediate family members or close associates
            politically exposed person (Or have been so during the past 12
            months)<span style="color: red">*</span>
          </h6>
          <div style="display: flex; gap: 10px">
            <input
              type="radio"
              id="html"
              name="associatePolitically"
              value="yes"
              formControlName="associatePolitically"
            />
            <label for="html" style="margin-top: 10px">Yes</label><br />
            <input
              type="radio"
              id="css"
              name="associatePolitically"
              value="no"
              formControlName="associatePolitically"
            />
            <label for="css" style="margin-top: 10px">No</label><br />
          </div>
          <h6 class="main-sub-heading" style="margin-top: 10px">
            Does the representative act as a nominee<span style="color: red"
              >?</span
            >
          </h6>
          <div style="display: flex; gap: 10px">
            <input
              type="radio"
              id="html"
              name="representativeNiminee"
              value="yes"
              formControlName="representativeNiminee"
            />
            <label for="html" style="margin-top: 10px">Yes</label><br />
            <input
              type="radio"
              id="css"
              name="representativeNiminee"
              value="no"
              formControlName="representativeNiminee"
            />
            <label for="css" style="margin-top: 10px">No</label><br />
          </div>
          <h6 class="main-sub-heading" style="margin-top: 10px">
            Does the representative act as trustee<span style="color: red"
              >?</span
            >
          </h6>
          <div style="display: flex; gap: 10px">
            <input
              type="radio"
              id="html"
              name="benfActTrustee"
              value="yes"
              formControlName="benfActTrustee"
            />
            <label for="html" style="margin-top: 10px">Yes</label><br />
            <input
              type="radio"
              id="css"
              name="benfActTrustee"
              value="no"
              formControlName="benfActTrustee"
            />
            <label for="css" style="margin-top: 10px">No</label><br />
          </div>
          <h6 class="main-sub-heading" style="margin-top: 10px">
            Is the representative a USA person<span style="color: red">?</span>
          </h6>
          <div style="display: flex; gap: 10px">
            <input
              type="radio"
              (change)="onUsaChange()"
              formControlName="isUsaPerson"
              id="html"
              name="isUsaPerson"
              value="yes"
            />
            <label for="html" style="margin-top: 10px">Yes</label><br />
            <input
              type="radio"
              (change)="onUsaChange()"
              formControlName="isUsaPerson"
              id="css"
              name="isUsaPerson"
              value="no"
            />
            <label for="css" style="margin-top: 10px">No</label><br />
          </div>
          <div *ngIf="isUsaYesSelected">
            <input
              id="name"
              placeholder="Enter USA Tin Number"
              type="text"
              class="fts-input-field"
              formControlName="taxIdentificationNo"
            />
          </div>
          <!-- <div class="eBKYjJ cgMkag">
            <div class="row" style="margin-bottom: 30px">
              <div class="input-field col s4">
                <button
                  data-component="primary-button"
                  type="submit"
                  role="button"
                  tabindex="0"
                  (click)="onPrevious()"
                  class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 buttons-Add"
                >
                  Previous
                </button>
              </div>
              <div class="input-field col s4">
                <button
                  data-component="primary-button"
                  type="submit"
                  role="button"
                  tabindex="0"
                  class="epjTBn"
                  (click)="addPersonalDetail()"
                >
                  Continue
                </button>
              </div>
            </div>
          </div> -->
        </form>

        <div class="primary-back-continue">
          <div class="">
            <button
              data-component="primary-button"
              type="submit"
              role="button"
              tabindex="0"
              (click)="onPrevious()"
              class="fts-button-tertiary"
            >
              Previous
            </button>
          </div>
          <div class="">
            <button
              data-component="primary-button"
              type="submit"
              role="button"
              tabindex="0"
              (click)="addPersonalDetail()"
              class="fts-button-primary"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-card-large>

<!-- RIGHT PANEL-->

import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromCustomer from '../reducer/business.reducer';

export const selectBusinessState = createFeatureSelector<fromCustomer.BusinessState>(
  fromCustomer.businessFeatureKey,
);


export const selectBusiness = createSelector(
  selectBusinessState,
  (state: fromCustomer.BusinessState) => state.business
);

<div class="bs-stepper">
  <div class="bs-stepper-header" role="tablist">

    <!-- Step 1 -->
    <div class="step" data-target="#logins-part" [ngClass]="{ 'current': currentStep === 1 }">
      <button type="button" class="step-trigger" role="tab" aria-controls="logins-part" id="logins-part-trigger" [ngClass]="{ 'active': currentStep === 1 }">
        <span class="bs-stepper-circle">1</span>
        <span class="bs-stepper-label">Business Registration</span>
      </button>
    </div>

    <div class="line"></div>

    <!-- Step 2 -->
    <div class="step" data-target="#information-part" [ngClass]="{ 'current': currentStep === 2 }">
      <button type="button" class="step-trigger" role="tab" aria-controls="information-part" id="information-part-trigger" [ngClass]="{ 'active': currentStep === 2 }">
        <span class="bs-stepper-circle">2</span>
        <span class="bs-stepper-label">Business Information</span>
      </button>
    </div>

    <div class="line"></div>

    <!-- Step 3 -->
    <div class="step" data-target="#information-part1" [ngClass]="{ 'current': currentStep === 3 }">
      <button type="button" class="step-trigger" role="tab" aria-controls="information-part1" id="information-part-trigger" [ngClass]="{ 'active': currentStep === 3 }">
        <span class="bs-stepper-circle">3</span>
        <span class="bs-stepper-label">Personal Details</span>
      </button>
    </div>
     <!-- Step 4 -->
    <div class="line"></div>
    <div class="step" data-target="#information-part1" [ngClass]="{ 'current': currentStep === 4 }">
      <button type="button" class="step-trigger" role="tab" aria-controls="information-part1" id="information-part-trigger" [ngClass]="{ 'active': currentStep === 4 }">
        <span class="bs-stepper-circle">4</span>
        <span class="bs-stepper-label">Expected Account Activity</span>
      </button>
    </div>
  </div>

  <div class="bs-stepper-content">

    <div id="logins-part" class="content" role="tabpanel" aria-labelledby="logins-part-trigger"></div>
    <div id="information-part" class="content" role="tabpanel" aria-labelledby="information-part-trigger" *ngIf="currentStep >= 2"></div>

  </div>
</div>

  <!-- <div class="container">
    <h1>bs-stepper</h1>
    <div class="row">
      <div class="col-md-12 mt-5">
        <h2>Linear stepper</h2>
        <div id="stepper1" class="bs-stepper">
          <div class="bs-stepper-header">
            <div class="step" data-target="#test-l-1">
              <button type="button" class="btn step-trigger">
                <span class="bs-stepper-circle">1</span>
                <span class="bs-stepper-label">First step</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-2">
              <button type="button" class="btn step-trigger">
                <span class="bs-stepper-circle">2</span>
                <span class="bs-stepper-label">Second step</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-3">
              <button type="button" class="btn step-trigger">
                <span class="bs-stepper-circle">3</span>
                <span class="bs-stepper-label">Third step</span>
              </button>
            </div>
          </div>
          <div class="bs-stepper-content">
            <div id="test-l-1" class="content">
              <p class="text-center">test 1</p>
              <button class="btn btn-primary" onclick="stepper1.next()">Next</button>
            </div>
            <div id="test-l-2" class="content">
              <p class="text-center">test 2</p>
              <button class="btn btn-primary" onclick="stepper1.next()">Next</button>
            </div>
            <div id="test-l-3" class="content">
              <p class="text-center">test 3</p>
              <button class="btn btn-primary" onclick="stepper1.next()">Next</button>
              <button class="btn btn-primary" onclick="stepper1.previous()">Previous</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-5">
        <h2>Non linear stepper</h2>
        <div id="stepper2" class="bs-stepper">
          <div class="bs-stepper-header">
            <div class="step" data-target="#test-nl-1">
              <button type="button" class="btn step-trigger">
                <span class="bs-stepper-circle">1</span>
                <span class="bs-stepper-label">First step</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-nl-2">
              <div class="btn step-trigger">
                <span class="bs-stepper-circle">2</span>
                <span class="bs-stepper-label">Second step</span>
              </div>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-nl-3">
              <button type="button" class="btn step-trigger">
                <span class="bs-stepper-circle">3</span>
                <span class="bs-stepper-label">Third step</span>
              </button>
            </div>
          </div>
          <div class="bs-stepper-content">
            <div id="test-nl-1" class="content">
              <p class="text-center">test 3</p>
              <button class="btn btn-primary" onclick="stepper2.next()">Next</button>
            </div>
            <div id="test-nl-2" class="content">
              <p class="text-center">test 4</p>
              <button class="btn btn-primary" onclick="stepper2.next()">Next</button>
            </div>
            <div id="test-nl-3" class="content">
              <p class="text-center">test 5</p>
              <button class="btn btn-primary" onclick="stepper2.next()">Next</button>
              <button class="btn btn-primary" onclick="stepper2.previous()">Previous</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-5">
        <h2>Vertical stepper</h2>
        <div id="stepper3" class="bs-stepper vertical">
          <div class="bs-stepper-header">
            <div class="step" data-target="#test-lv-1">
              <button type="button" class="btn step-trigger">
                <span class="bs-stepper-circle">1</span>
                <span class="bs-stepper-label">First step</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-lv-2">
              <button type="button" class="btn step-trigger">
                <span class="bs-stepper-circle">2</span>
                <span class="bs-stepper-label">Second step</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-lv-3">
              <button type="button" class="btn step-trigger">
                <span class="bs-stepper-circle">3</span>
                <span class="bs-stepper-label">Third step</span>
              </button>
            </div>
          </div>
          <div class="bs-stepper-content">
            <div id="test-lv-1" class="content">
              <p class="text-center">test 3</p>
              <button class="btn btn-primary" onclick="stepper3.next()">Next</button>
              <button class="btn btn-primary" onclick="stepper3.previous()">Previous</button>
            </div>
            <div id="test-lv-2" class="content">
              <p class="text-center">test 4</p>
              <button class="btn btn-primary" onclick="stepper3.next()">Next</button>
              <button class="btn btn-primary" onclick="stepper3.previous()">Previous</button>
            </div>
            <div id="test-lv-3" class="content">
              <p class="text-center">test 5</p>
              <button class="btn btn-primary" onclick="stepper3.next()">Next</button>
              <button class="btn btn-primary" onclick="stepper3.previous()">Previous</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-5">
        <h2>Linear vertical stepper without fade</h2>
        <div id="stepper4" class="bs-stepper vertical">
          <div class="bs-stepper-header">
            <div class="step" data-target="#test-vlnf-1">
              <button type="button" class="btn step-trigger">
                <span class="bs-stepper-circle">1</span>
                <span class="bs-stepper-label">First step</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-vlnf-2">
              <button type="button" class="btn step-trigger">
                <span class="bs-stepper-circle">2</span>
                <span class="bs-stepper-label">Second step</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-vlnf-3">
              <button type="button" class="btn step-trigger">
                <span class="bs-stepper-circle">3</span>
                <span class="bs-stepper-label">Third step</span>
              </button>
            </div>
          </div>
          <div class="bs-stepper-content">
            <div id="test-vlnf-1" class="content">
              <p class="text-center">test 1</p>
              <button class="btn btn-primary" onclick="stepper4.next()">Next</button>
            </div>
            <div id="test-vlnf-2" class="content">
              <p class="text-center">test 2</p>
              <button class="btn btn-primary" onclick="stepper4.next()">Next</button>
              <button class="btn btn-primary" onclick="stepper4.previous()">Previous</button>
            </div>
            <div id="test-vlnf-3" class="content">
              <p class="text-center">test 3</p>
              <button class="btn btn-primary" onclick="stepper4.next()">Next</button>
              <button class="btn btn-primary" onclick="stepper4.previous()">Previous</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
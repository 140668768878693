import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'wallet-card',
    templateUrl: './wallet-card.component.html',
    styleUrls: ['./wallet-card.component.css']
})

export class WalletCardComponent implements AfterViewInit {

    @Output() walletChange: EventEmitter<any> = new EventEmitter<any>();

    public selectedWalletId: string | null = null;

    @Input() public wallet: any;

    public val = "XXX";

    constructor() {
        this.wallet = null;
        console.log("Wallet " + this.wallet);
    }

    ngAfterViewInit() {
        console.log("Wallet " + this.wallet);
    }

    onWalletChange(event: any, id: any) {
        this.walletChange.emit(event);
    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignUpService } from '../sign-up/service/sign-up.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { PersonalDetailsComponent } from './components/personal-details.component';
import {PersonalAddressComponent} from './components/personal-address.component';
import {PersonalDocumentsComponent} from './components/personal-documents.component';
import {PersonalDocumentTypeComponent} from './components/personal-doctype.component';
import {PersonalPassportComponent} from './components/personal-passport.component';
import {personalFeatureKey,personalReducer } from '../personal-details/store/reducer/personal.reducer'
import {personalAddressFeatureKey,personalAddressReducer} from '../personal-details/store/reducer/personalAddress.reducer'
import {personalDocumentFeatureKey,PersonalDocumentReducer} from '../personal-details/store/reducer/personalDocument.reducer'
import {PersonalAddressResolver} from '../personal-details/resolver/personal-address.resolver'
import { UIModule } from '../util/ui/ui-module';
@NgModule({
  declarations: [
    PersonalDetailsComponent,
    PersonalAddressComponent,
    PersonalDocumentsComponent,
    PersonalDocumentTypeComponent,
    PersonalPassportComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(personalFeatureKey,personalReducer),
    StoreModule.forFeature(personalAddressFeatureKey,personalAddressReducer),
    StoreModule.forFeature(personalDocumentFeatureKey,PersonalDocumentReducer),
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    UIModule,
  ],
  providers:[SignUpService,PersonalAddressResolver],
  exports: [
    PersonalDetailsComponent,
    PersonalAddressComponent,
    PersonalDocumentsComponent,
    PersonalDocumentTypeComponent,
    PersonalPassportComponent
  ]
})
export class PersonalDetailsModule {}

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Data } from 'src/app/providers/data';
import {BusinessCustomer} from '../model/businessCustomer';
import {select, Store} from '@ngrx/store';
import {addPersonalDoc} from '../store/action/personalDocumentactions';
import { Business } from 'src/app/business-details/model/business';
import { Document } from 'src/app/business-details/model/document';
import { FormBuilder, Validators } from '@angular/forms';
import { PersonalDetailService } from '../service/personal-detail.service';

declare var $: any;

class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'personal-passport',
  templateUrl: './personal-passport.component.html',
  styleUrls: ['./personal-passport.component.scss']
})

export class PersonalPassportComponent implements OnInit{
  public business : Business;
  selectedFile: ImageSnippet;
  public document1: string=null;
  public submitted = false;
  public customer: BusinessCustomer;
  public userDocument: any;
  constructor(private router: Router,private data: Data,private formBuilder : FormBuilder,
    private personalDetailService:PersonalDetailService,private store: Store<Document>
    ){}

  ngOnInit() {
    // this.customer=this.data.storage.businessInformation.customer[0];
    // this.business=this.data.storage.businessInformation;
  }

  passportForm = this.formBuilder.group({
    front : ['', Validators.required],
  });
  get f(){return this.passportForm.controls};


  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.sendImageApi(this.selectedFile.file);
    });
    reader.readAsDataURL(file);
  }

  sendImageApi(file){
    this.personalDetailService.sendPersonalDocument(file, "Passport", "Passport").subscribe((result) => {
      if (result != null && result.code === 'DAO_SUCCESS_00' && result.responseData != null) {
        this.document1 = result.responseData.Data.document;
        let arrayDoc= []
        let passport = new Document(null, "Document","Passport", this.document1, 1);
        arrayDoc.push(passport);
         this.userDocument=arrayDoc;   
         this.store.dispatch(addPersonalDoc(this.userDocument));
      }
      else {
        // this.isError = true;
      }
    }, error => { console.log(error) })
  }

 addUserDocument(){
  this.submitted = true;
  if (this.passportForm.invalid){
    return;
  }
else{

  this.router.navigate(['/bioVerification']);
}
  }

}
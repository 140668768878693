import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountDetailsComponent } from './account-details/component/account-details.component';
import { BioVerificationComponent } from './bio-verification/component/bio-verification.component';
import { BusinessAddressComponent } from './business-details/components/business-address.component';
import { BusinessDetailsComponent } from './business-details/components/business-details.component';
import { BusinessDocumentsComponent } from './business-details/components/business-documents.component';
import { BusinessTypeComponent } from './business-type/component/business-type.component';
import { EmailCheckComponent } from './email-check/component/email-check.component';
import { PersonalAddressComponent } from './personal-details/components/personal-address.component';
import { PersonalDetailsComponent } from './personal-details/components/personal-details.component';
import { PersonalDocumentTypeComponent } from './personal-details/components/personal-doctype.component';
import { PersonalDocumentsComponent } from './personal-details/components/personal-documents.component';
import { PersonalPassportComponent } from './personal-details/components/personal-passport.component';
import { VerifyOTPComponent } from './verify-otp/component/verify-otp.component';
import { BusinessAddressResolver } from '../../src/app/business-details/resolver/business-address.resolver'
import { CreateAccountComponent } from './create-account/component/create-account.component';
import { PassBaseVerificationComponent } from '../app/pass-base-verification/component/pass-base-verification.component';
import { KycTypeComponent } from './kyc-type/kyc-type.component';
import { PersonalAddressResolver } from './personal-details/resolver/personal-address.resolver';
import { RewiewDocumentsComponent } from './rewiew-documents/rewiew-documents.component';
import { BusinessrolesComponent } from './business-details/components/businessroles/businessroles.component';

const routes: Routes = [
  { path: '', component: BusinessTypeComponent },
  { path: 'emailCheck', component: EmailCheckComponent },
  { path: 'accountDetails', component: AccountDetailsComponent },
  { path: 'verifyOTP', component: VerifyOTPComponent },
  { path: 'businessDetails', component: BusinessDetailsComponent },
  {
    path: 'businessAddress',
    component: BusinessAddressComponent,
    resolve: [BusinessAddressResolver],
  },
  { path: 'businessDocuments', component: BusinessDocumentsComponent },
  { path: 'personalDetails', component: PersonalDetailsComponent },
  {
    path: 'personalAddress',
    component: PersonalAddressComponent,
    resolve: [PersonalAddressResolver],
  },
  { path: 'personalDocuments', component: PersonalDocumentsComponent },
  { path: 'personalDoctype', component: PersonalDocumentTypeComponent,
  resolve: [PersonalAddressResolver], },
  { path: 'bioVerification', component: BioVerificationComponent },
  { path: 'passportDocuments', component: PersonalPassportComponent },
  { path: 'createAccount', component: CreateAccountComponent },
  { path: 'passbaseVerification', component: PassBaseVerificationComponent },
  { path: 'document-details', component: RewiewDocumentsComponent },
  { path: 'kycType', component: KycTypeComponent },
  { path: 'businessRoles', component: BusinessrolesComponent,},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

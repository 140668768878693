<app-card-large>
  <div class="dyEAXN">
    <!-- RIGHT PANEL-->
    <div class="bCDrPV djdIYm">
      <div class="mjYRwVK">
        <!-- FORM CONTENT-->
        <!-- <div class="hnZgAZ"> -->
        <!-- <div class="husZCp"> -->

        <div class="main-panel__content">
          <div class="main-panel__content">
            <h1 class="main-heading nonreferral">Business Address Details</h1>
            <h2 class="main-sub-heading">
              Please provide your business address details
            </h2>
          </div>
        </div>
        <!-- </div> -->
        <!-- </div> -->

        <!-- <br> -->

        <!-- <form name="addressForm" [formGroup]="addressForm">
          <div class="row">
            <div class="col-sm-5">
              <label
                data-component="form-input-email-label"
                for="email"
                class="form__label"
                >Street Name <span style="color: red">*</span></label
              >
              <input
                id="line1"
                type="text"
                placeholder=""
                formControlName="line1"
                [ngClass]="{ invalid: submitted && f.line1.errors }"
                autocomplete="username"
                class="fts-input-field input-width validate-empty"
                value=""
              />
              <div *ngIf="submitted && f.line1.errors" class="text-danger">
                <div *ngIf="f.line1.errors.required">
                  Street Name is required
                </div>
                <div *ngIf="f.line1.errors.minlength">
                  Required atleast 6 Characters
                </div>
              </div>
            </div>
            <div class="col-sm-5">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Address Line</label
              >
              <input
                id="line2"
                formControlName="line2"
                type="text"
                class="fts-input-field input-width validate-empty"
                value=""
              />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-5">
              <label for="team_size" class="form__label"
                >Country <span style="color: red">*</span></label
              >
              <select
                id="country"
                formControlName="country"
                [ngClass]="{ invalid: submitted && f.country.errors }"
                class="fts-select selector_width"
              >
                <option
                  *ngFor="let countrty of countries"
                  [value]="countrty.id"
                >
                  {{ countrty.name }}
                </option>
              </select>
              <div *ngIf="submitted && f.country.errors" class="text-danger">
                <div *ngIf="f.country.errors.required">Country is required</div>
              </div>
            </div>

            <div class="col-sm-5">
              <label for="team_size" class="form__label"
                >State <span style="color: red">*</span></label
              >
              <select
                id="state"
                formControlName="state"
                [ngClass]="{ invalid: submitted && f.state.errors }"
                class="fts-select selector_width"
              >
                <option *ngFor="let state of states" [value]="state.id">
                  {{ state.name }}
                </option>
              </select>
              <div *ngIf="submitted && f.state.errors" class="text-danger">
                <div *ngIf="f.state.errors.required">State is required</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-5">
              <label for="team_size" class="form__label"
                >City <span style="color: red">*</span></label
              >
              <select
                id="city"
                formControlName="city"
                [ngClass]="{ invalid: submitted && f.city.errors }"
                class="fts-select selector_width"
              >
                <option *ngFor="let city of cities" [value]="city.id">
                  {{ city.name }}
                </option>
              </select>
              <div *ngIf="submitted && f.city.errors" class="text-danger">
                <div *ngIf="f.city.errors.required">City is required</div>
              </div>
            </div>
            <div class="col-sm-5">
              <label
                data-component="form-input-name-label"
                for="name"
                class="form__label"
                >Postcode <span style="color: red">*</span></label
              >
              <input
                id="postalCode"
                type="text"
                formControlName="postalCode"
                [ngClass]="{ invalid: submitted && f.postalCode.errors }"
                class="fts-input-field input-width validate-empty"
                value=""
              />
              <div *ngIf="submitted && f.postalCode.errors" class="text-danger">
                <div *ngIf="f.postalCode.errors.required">
                  Postal Code is required
                </div>
              </div>
            </div>
          </div>
        </form> -->

        <form name="addressForm" [formGroup]="addressForm">
          <div class="row">
            <div class="input-field col s6">
              <label
                data-component="form-input-email-label"
                for="email"
                class="form__label"
                >Street name <span style="color: red">*</span></label
              >
              <input
                id="line1"
                type="text"
                placeholder="Street name"
                formControlName="line1"
                class="fts-input-field register-w"
              />
              <div *ngIf="submitted && f.line1.errors" class="text-danger">
                <div *ngIf="f.line1.errors.required">
                  Street name is required
                </div>
                <div *ngIf="f.line1.errors.minlength">
                  Required atleast 6 characters
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label
                data-component="form-input-name-label"
                class="form__label"
                for="name"
                >Address line 2 (optional)</label
              >
              <input
                id="line2"
                formControlName="line2"
                type="text"
                placeholder="Address"
                class="fts-input-field register-w"
              />
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label for="team_size" class="form__label"
                >Country <span style="color: red">*</span></label
              >
              <select id="country" formControlName="country" class="fts-select">
                <option disabled selected>Select your country</option>
                <option
                  *ngFor="let countrty of countries"
                  [value]="countrty.id"
                >
                  {{ countrty.name }}
                </option>
              </select>
              <div *ngIf="submitted && f.country.errors" class="text-danger">
                <div *ngIf="f.country.errors.required">Country is required</div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label for="team_size" class="form__label"
                >County<span style="color: red">*</span></label
              >
              <select id="state" formControlName="state" class="fts-select">
                <option value disabled selected>county</option>
                <option *ngFor="let state of states" [value]="state.id">
                  {{ state.name }}
                </option>
              </select>
              <div *ngIf="submitted && f.state.errors" class="text-danger">
                <div *ngIf="f.state.errors.required">county is required</div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label for="team_size" class="form__label"
                >City<span style="color: red">*</span></label
              >
              <select id="city" formControlName="city" class="fts-select">
                <option value="" disabled selected>city</option>
                <option *ngFor="let city of cities" [value]="city.name">
                  {{ city.name }}
                </option>
              </select>
              <div *ngIf="submitted && f.city.errors" class="text-danger">
                <div *ngIf="f.city.errors.required">city is required</div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label
                data-component="form-input-name-label"
                for="name"
                class="form__label"
                >Postal code <span style="color: red">*</span></label
              >
              <input
                id="postalCode"
                type="text"
                formControlName="postalCode"
                class="fts-input-field register-w"
                value
              />
              <div *ngIf="submitted && f.postalCode.errors" class="text-danger">
                <div *ngIf="f.postalCode.errors.required">
                  Postal code is required
                </div>
              </div>
            </div>
          </div>

          <div style="margin-top: 20px">
            <h1 data-component="heading" class="main-heading nonreferral">
              Business Trading Address
            </h1>
            <!-- <h2 data-component="subheading"
                     class="styles__Root-sc-1myek4t-0 cclCHJ Heading__SubHead-junqdu-2 goFGMk">Please
                     provide your busienss address details</h2> -->
          </div>
          <div class="d-flex" style="margin-top: 5px">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value
                (change)="showAddress()"
                id="flexCheckDefault"
                formControlName="addressCheckbox"
              />
              <label class="form__label" for="flexCheckDefault">
                Same as registerd address
              </label>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label
                data-component="form__label"
                for="email"
                class="form__label"
                >Street name<span style="color: red">*</span></label
              >
              <input
                id="line1"
                type="text"
                placeholder="Street name"
                formControlName="tradingLine1"
                class="fts-input-field register-w"
              />
              <div *ngIf="submitted && f.line1.errors" class="text-danger">
                <div *ngIf="f.tradingLine1.errors.required">
                  Street name is required
                </div>
                <div *ngIf="f.tradingLine1.errors.minlength">
                  Required atleast 6 characters
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label data-component="form__label" class="form__label" for="name"
                >Address line 2 (optional)</label
              >
              <input
                id="line2"
                formControlName="tradingLine2"
                type="text"
                class="fts-input-field register-w"
                placeholder="Address"
              />
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label for="team_size" class="form__label"
                >Country <span style="color: red">*</span></label
              >
              <select
                id="country"
                formControlName="tradingcountry"
                class="fts-select"
              >
                <option disabled selected>Select your country</option>
                <option
                  *ngFor="let countrty of countries"
                  [value]="countrty.id"
                >
                  {{ countrty.name }}
                </option>
                <!-- <option *ngFor="let countrty of countries" [value]="countrty.id">{{countrty.name}}
                           </option> -->
              </select>
              <div
                *ngIf="submitted && f.tradingcountry.errors"
                class="text-danger"
              >
                <div *ngIf="f.tradingcountry.errors.required">
                  Country is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label for="team_size" class="form__label"
                >County <span style="color: red">*</span></label
              >
              <select
                id="state"
                formControlName="tradingstate"
                class="fts-select"
              >
                <option value disabled selected>county</option>
                <option *ngFor="let state of states" [value]="state.id">
                  {{ state.name }}
                </option>
              </select>
              <div
                *ngIf="submitted && f.tradingstate.errors"
                class="text-danger"
              >
                <div *ngIf="f.tradingstate.errors.required">
                  county is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label for="team_size" class="form__label"
                >City <span style="color: red">*</span></label
              >
              <select
                id="city"
                formControlName="tradingcity"
                [ngClass]="{ invalid: submitted && f.city.errors }"
                class="fts-select"
              >
                <option value="" disabled selected>city</option>
                <option *ngFor="let city of cities" [value]="city.name">
                  {{ city.name }}
                </option>
              </select>
              <div
                *ngIf="submitted && f.tradingcity.errors"
                class="text-danger"
              >
                <div *ngIf="f.tradingcity.errors.required">
                  City is required
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="input-field col s6">
              <label
                data-component="form-input-name-label"
                for="name"
                class="form__label"
                >Post code<span style="color: red">*</span></label
              >
              <input
                id="postalCode"
                type="text"
                formControlName="tradingpostalCode"
                [ngClass]="{ invalid: submitted && f.postalCode.errors }"
                class="fts-input-field register-w"
                value
              />
              <div *ngIf="submitted && f.postalCode.errors" class="text-danger">
                <div *ngIf="f.tradingpostalCode.errors.required">
                  Postal code is required
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="eBKYjJ cgMkag">
            <div class="row button-row">
              <div class="input-field col s4">
                <button
                  data-component="primary-button"
                  type="submit"
                  role="button"
                  tabindex="0"
                  (click)="onPrevious()"
                  class="styles__Root-sc-1myek4t-0 bXyBff styles__Root-iaddyf-0 buttons-Add"
                >
                  Previous
                </button>
              </div>
              <div class="input-field col s4">
                <button
                  data-component="primary-button"
                  type="submit"
                  role="button"
                  tabindex="0"
                  class="epjTBn"
                  (click)="addBusinessAddress()"
                >
                  Continue
                </button>
              </div>
            </div>
          </div> -->
        </form>

        <div class="row mb-6">
          <div class="primary-back-continue">
            <button
              data-component="primary-button"
              type="submit"
              role="button"
              tabindex="0"
              (click)="onPrevious()"
              class="fts-button-tertiary"
            >
              Previous
            </button>

            <button
              data-component="primary-button"
              type="submit"
              role="button"
              tabindex="0"
              class="fts-button-primary"
              (click)="addBusinessAddress()"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- RIGHT PANEL-->
</app-card-large>

import {Action, createReducer, on} from '@ngrx/store';
import * as BusinessAddressAction from '../action/businessAddress.actions';


export const businessAddressFeatureKey = 'businessAddress';

export interface BusinessAddressState {
  businessAddress: {};
}

export const initialState: BusinessAddressState = {
  businessAddress: {}
};

export const businessAddressReducer = createReducer(
  initialState,
  on(BusinessAddressAction.addBusiAddr,
    (state: BusinessAddressState, {businessAddress}) =>
      ({...state,
        businessAddress,
      }))
);

export function BusinessAddessReducer(state: BusinessAddressState | undefined, action: Action): any {
  return businessAddressReducer(state, action);
}
